.login-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--dark);
  overflow: hidden;

  &__icons-wrapper {
    position: absolute;
    width: 90%;
    display: flex;
    bottom: 275px;
    align-items: center;
    height: 1px;
    justify-content: space-evenly;
  }

  &__icon {
    width: 30px;
    height: 30px;
    svg {
      width: 100%;
      fill: #322b41;
    }

    &.icon-1 {
      transform: rotate(-30deg);
      width: 35px;
      position: relative;
      top: -30px;
      opacity: 0.5;
    }

    &.icon-2 {
      transform: rotate(18deg);
      width: 30px;
      position: relative;
      top: 5px;
      opacity: 0.4;
    }

    &.icon-3 {
      transform: rotate(20deg);
      width: 25px;
      position: relative;
      top: -15px;
      opacity: 0.5;
    }

    &.icon-4{
      transform: rotate(-20deg);
      width: 40px;
      position: relative;
      top: 20px;
      opacity: 0.4;
    }

    &.icon-5 {
      transform: rotate(5deg);
      width: 25px;
      position: relative;
      top: 5px;
      opacity: 0.3;
    }

    &.icon-6 {
      transform: rotate(25deg);
      width: 35px;
      position: relative;
      top: -18px;
      opacity: 0.3;
    }
  }

  &__wave {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(80px);

    &--animate {
      animation: waveMove 5s infinite alternate;
    }
  }

  &__logo {
    text-align: center;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    font-weight: 700;
  }

  &__wrapper {
    min-width: 360px;
    padding-bottom: 80px;
    position: relative;
    z-index: 6;
  }

  &__title {
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 20px;
  }

  &__subtitle {
    text-align: center;
    color: #fff;
    font-size: 18px;
    opacity: 0.5;
    margin-bottom: 40px;
    max-width: 500px;
  }

  &__form {
    width: 100%;
    max-width: 360px;
    padding: 20px;
    margin: 0 auto;

    &__input-wrapper {
      width: 100%;
      margin-bottom: 20px;
      position: relative;

      small {
        font-size: 10px;
        display: block;
        margin-bottom: 5px;
        text-align: center;
        color: var(--primary);
      }

      & > .fa, & > fa-icon {
        position: absolute;
        display: block;
        right: -30px;
        top: 12px;
        font-size: 20px;
        color: #eaeaf1;
        opacity: 0;
        cursor: pointer;
        transform: translateY(6px) scale(0);
        transition: all 0.4s;

        &:hover {
          & ~ .clue-info {
            opacity: 1;
          }
        }

        &.clue {
          display: block;
          opacity: 0.6;
          transform: translateY(0);
        }
      }

      &.error {
        & > .fa, & > fa-icon {
          color: var(--danger);
          opacity: 1;
          transform: translateY(0) scale(1);

          &.clue {
            display: none;
          }
        }
        .clue-info {
          color: var(--danger);
        }
      }

      .clue-info {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -240px;
        width: 200px;
        background-color: #eaeaf1;
        padding: 15px;
        border-radius: 10px;
        color: #73768c;
        font-size: 10px;
        font-weight: 600;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.4s;
      }

      password-visibility-switch {
        background-color: rgba(37, 40, 58, 0.6);
        border-radius: 8px;
        display: block;
        padding: 0 16px;
        border: 2px solid transparent;
        transition: all 0.4s;

        &:focus-within {
          border-color: var(--primary);
        }

      }

      input {
        height: 50px;
        width: 100%;
        font-weight: 700;
        color: #7c7f94;
        background-color: transparent;
        outline: none;
        border: none;

        &:-webkit-autofill {
          transition: background-color 5000s;
          -webkit-text-fill-color: #7c7f94;
          caret-color: #7c7f94;
        }

        &::placeholder {
          color: #73768c;
          opacity: 0.8;
          font-weight: 600;
        }

        &:not(.unstyled) {
          padding: 0 16px;
          border-radius: 8px;
          border: 2px solid transparent;
          background-color: rgba(37, 40, 58, 0.6);
          transition: all 0.4s;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px #2b2e41 inset !important;
            -webkit-text-fill-color: #7c7f94 !important;
            border-color: var(--primary);
          }


          &:focus {
            border-color: var(--primary);
          }

          &:hover, &:focus, &:active {
            outline: none;
          }
        }
      }
    }

    &__forgot-password {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;

      a {
        color: #2ff9d1;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          color: lighten(#2ff9d1, 15%);
        }
      }
    }

    &__button {
      width: 100%;
      height: 50px;
      background-color: var(--primary);
      border: none;
      border-radius: 8px;
      color: #fff;
      font-weight: 700;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        transform: translateY(-3px);
        background-color: #30d4b3;
        box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
      }
    }
  }
}

@keyframes waveMove {
  0% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(95px);
  }
}

@media screen and (max-width: 767px) {
  .login-view{
    &__title{
      font-size: 24px;
    }
    &__wrapper {
      min-width: unset;
      padding-bottom: 50px;
    }
    &__form__input-wrapper .clue-info{
      right: 0;
    }
    &__footer{
      color: var(--text);
    }
    &__wave{
      display: none;
    }
  }
}
