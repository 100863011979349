agenda-detail {
  flex: 1;
  position: relative;
}

.agenda-detail {
  width: 100%;
  height: 100%;
  padding: 10px 20px 15px;
  position: relative;
  display: flex;
  flex-direction: column;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    height: 100%;
    width: 1px;
    background-image: linear-gradient(to bottom, white 2%, #d9dade, white 98%);
  }

  &__header {
    display: flex;
    align-items: center;

    h3 {
      display: flex;
      align-items: center;
      min-height: 38px;
      margin: 0 0 10px 0;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 8px;
      font-weight: 700;
      color: var(--text);
      border-bottom: 2px dashed #eaeaf1;

      span a {
        color: var(--primary);
        margin-right: 8px;
      }

      .todo-card__summary {
        margin-top: -6px;
        font-size: 16px;
      }

    }

    &__type {
      border-radius: 6px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background: linear-gradient(#fff, #e4e4e9) no-repeat;
      box-shadow: rgba(37, 44, 97, 0.15) 0px 5px 15px 0px, rgba(93, 100, 148, 0.2) 0px 2px 4px 0px;

      &__medical,
      &__plaintiff {
        svg {
          position: relative;
          top: 2px;
        }
      }

      i.fa-medkit,
      &__medical {
        color: var(--medical);
        font-size: 16px;
      }

      i.fa-money,
      &__plaintiff {
        color: var(--primary);
        font-size: 16px;
      }

      svg-icon, svg {
        width: 24px;
        height: 24px;
        fill: var(--primary);
      }
    }

    &__categories {
      display: flex;
    }
  }

  &__content {
    display: flex;
    align-items: stretch;
    height: calc(100% - 140px);

    &__main {
      width: 70%;
      padding: 20px 0 5px;
      display: flex;
      flex-direction: column;

      .section__title {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 13px;
        color: var(--text);
      }

      .section {
        color: var(--text);

        &--space {
          margin: 0 0 20px;
        }

        &__content {
          padding: 5px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          transition: 0.3s all;

          h4 {
            padding: 0;
          }

          &.clean {
            padding: 0;
            border-radius: 0;
          }

          button, button:active, button:hover, button:focus {
            outline: none;
            box-shadow: none;
            font-size: 12px;
            font-weight: 600;
          }

          .btn--save {
            color: white;
            background-color: var(--primary);
            width: 68px;
            border-radius: 5px;
            transition: all 0.4s;

            &:hover {
              background-color: var(--primary-hover);
            }
          }

          .btn--cancel {
            color: var(--primary);
            width: 68px;
            border: none;
            background-color: transparent;


            &:active {
              box-shadow: none;
            }
          }

          .btn--attachment {
            margin-left: auto;
            width: 21px;
            height: 21px;
            border: none;
            border-radius: 5px;
            background-color: var(--primary);
            color: #fff;
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s;

            span {
              transition: all 0.4s;
            }

            &.active {
              span {
                transform: rotate(225deg);
              }
            }
          }

          .todo-card__associations .quick-list {
            right: -14px;
          }
        }

        &.notes {
          flex: 1 1 1px;

          &.border-radius__overflow-fix {
            margin-left: 8px;

            .section__title, .notes__form {
              margin-left: -8px;
            }


          }

          funding-notes-section {
            display: flex;
            flex-direction: column;
            height: 100%;
          }

          .notes__list {
            flex: 1 1 1px;
            overflow: scroll;
            padding-right: 5px;

            &__note__message {
              word-break: normal;
              overflow-wrap: anywhere;
            }
          }
        }
      }
    }

    &__sidebar {
      width: 30%;
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      overflow-y: auto;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0px;
      left: 0;
      background-image: linear-gradient(to right, white 2%, #e5e8f3, white 98%);
    }

    .agenda-btn-done {
      margin-left: auto;
      margin-top: 15px;
      height: 54px;
      width: 250px;
      border: none;
      border-radius: 5px;
      background-color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--primary-hover);
      }

      svg-icon {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }

      svg {
        fill: #fff;
      }
    }

    &__done-actions {
      position: absolute;
      bottom: calc(100% - 4px);
      right: 24px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);

      &__title {
        padding: 8px;
        font-weight: 600;
        font-size: 10px;
        text-align: center;
        color: var(--primary);
      }

      &__select {
        display: flex;
        padding: 8px;
        color: var(--text);
        font-weight: 700;
        font-size: 11px;
        justify-content: center;
        cursor: pointer;
        position: relative;
        transition: all 0.4s;

        &:hover {
          color: var(--primary);
        }

        .select-list {
          width: 100%;
        }
      }

      &__divider {
        padding: 4px 8px;
        font-weight: 600;
        font-size: 10px;
        color: var(--text);
        position: relative;
        display: flex;
        justify-content: center;

        span {
          margin: 0 5px;
        }

        &:after {
          right: 0;
          top: 11px;
          position: absolute;
          content: '';
          display: inline-block;
          width: calc(50% - 10px);
          height: 1px;
          background-color: #eee;
        }

        &:before {
          left: 0;
          top: 11px;
          position: absolute;
          content: '';
          display: inline-block;
          width: calc(50% - 10px);
          height: 1px;
          background-color: #eee;
        }
      }

      .bs-datepicker.custom-datepicker-theme:not(.without-arrow):after {
        display: none;
      }

      .custom-datepicker-theme {
        box-shadow: none;
        margin-top: 0;

        .bs-datepicker-head {
          background-color: var(--primary);
        }
      }
    }
  }

  // notes
  .notes {
    &__form {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      align-items: stretch;
      gap: 8px;

      .user__avatar {
        align-self: center;
      }

      input:not([type=checkbox]).base-input.notes__form__input {
        background-color: #f4f5f7;
        border: 1px solid #dfe1e6;
        display: flex;
        padding: 0 10px;
        height: 40px;
        border-radius: 5px;
        font-size: 12px;
        outline: none;
        transition: all 0.4s;
      }

      &__actions {
        flex: 1 1 1px;
        display: flex;
        align-items: stretch;
        gap: 8px;
        height: 100%;

        button {
          height: 40px;
          width: 55px;
          background-color: var(--primary);
          border: none;
          color: white;
          font-weight: 700;
          font-size: 11px;
          border-radius: 5px;
        }

        .link {
          height: 40px;
          width: 55px;
          color: var(--text);
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          cursor: pointer;

          &__secondary {
            width: 35px;
            height: 35px;
            border-radius: 5px;

            svg-icon {
              width: 30px;
              height: 30px;
            }
          }

          &__bg-secondary {
            background-color: var(--grey);

            svg-icon {
              fill: #73768c;
            }
          }

          &__active {
            color: white;
            background-color: var(--primary);

            svg-icon {
              fill: white;
            }
          }
        }
      }
    }

    &__list {
      padding: 10px 0 10px 10px;
      // height: 555px;
      margin-left: -9px;
      overflow-y: auto;

      &--medical {
        // height: 444px;
      }

      &__note {
        display: flex;
        padding: 8px 0 8px 8px;
        width: calc(100% + 8px);
        left: -8px;
        border-radius: 5px;
        position: relative;
        transition: all 0.4s;

        &:hover {
          background-color: #f1f1f6;

          .notes__list__note__actions {
            opacity: 1;
            transform: translateY(0);
            right: 12px;
          }

        }

        &__actions {
          position: absolute;
          top: 8px;
          right: 8px;
          opacity: 0;
          transform: translateY(5px);
          transition: all 0.3s;

          i, fa-icon {
            cursor: pointer;
            color: var(--text);
          }

          &__edit {
            top: 1px;
            right: 20px;
          }

          &__delete {
            top: 0;
            right: 3px;
          }
        }

        &__name {
          font-weight: 700;
          margin-bottom: 4px;
          font-size: 11px;
        }

        &__edited {
          font-weight: 600;
          font-size: 10px;
          margin-left: 2px;
          display: inline-block;
          opacity: 0.5;
        }

        &__remove-dialog {
          position: absolute;
          top: -8px;
          left: -8px;
          background-color: rgba(255, 255, 255, 0.9);
          display: flex;
          flex-direction: column;
          width: calc(100% + 8px);
          height: calc(100% + 8px);
          border-radius: 5px;
          justify-content: center;
          align-items: center;

          &__title {
            margin-bottom: 8px;
            color: var(--text);
            font-weight: 700;
          }

          .link {
            display: inline-block;
            font-weight: 600;
            margin: 0 8px;
            cursor: pointer;
          }
        }

        &__info {
          font-size: 10px;
          margin-bottom: 4px;
          font-style: italic;
          color: #8894c3;

          span {
            font-weight: 600;
            color: var(--primary);
          }
        }

        .notes-form {
          display: flex;
          margin-top: 3px;
          width: 100%;
        }

        .notes__form__input {
          width: calc(100% - 150px);
          margin: 0 10px;
        }
      }
    }
  }

  // deal-info
  .deal-info {
    display: flex;
    align-items: center;
    padding: 0 10px;
    position: relative;

    &__widget {
      display: flex;
      align-items: center;
      padding: 0 35px 0 0;
      position: relative;
      color: var(--text);
      font-weight: 700;
      line-height: 16px;

      .icon {
        width: 20px;
        height: 20px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &.attorney {
        a {
          color: var(--primary);
        }

        svg {
          fill: var(--primary);
        }
      }

      &.requestedFunding {
        i, fa-icon {
          font-size: 20px;
          color: var(--primary);
        }
      }

      &.medical {
        a {
          color: var(--medical);
        }

        svg {
          fill: var(--medical);
        }
      }

      .edit-icon {
        width: 1rem;
        height: 1rem;
        align-self: flex-start;
        margin-left: 6px;

        svg {
          fill: var(--text-light);
        }
      }

      .info {
        margin-left: 10px;
        padding-left: 10px;
        font-size: 11px;
        font-weight: 400;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, white 2%, #d9dade, white 98%);
        }

        &__value {
          color: var(--primary)
        }
      }
    }
  }

  // deal contact
  .deal-contacts {
    &__wrapper {
      display: grid;
      grid-column-gap: 4px;
      grid-row-gap: 8px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto auto;
      grid-template-areas:
			  "a a b b c c"
			  "d d d e e e";

      a {
        color: white;
        text-decoration: underline;
      }
    }

    &__card {
      border-radius: 5px;
      background-color: var(--primary);
      padding: 15px;
      overflow: hidden;
      position: relative;
      color: white;
      margin: 0 3px;

      &--client {
        grid-area: a;
      }

      &--law-firm {
        grid-area: b;
      }

      &--case-manager {
        grid-area: c;
      }

      &--medical-facility {
        grid-area: d;
        background-color: var(--medical);;
      }

      &--doctor {
        grid-area: e;
        background-color: var(--medical);;
      }

      &__title {
        font-weight: 700;
        margin-bottom: 15px;
      }

      &__info {
        font-size: 11px;
      }

      svg-icon {
        &.clients-icon {
          width: 110px;
          height: 110px;
          bottom: -22px;
          right: -2px;
          position: absolute;
          opacity: 0.1;

          svg {
            fill: #fff;
            width: 110px;
            height: 110px;
          }
        }

        &.law-icon {
          width: 80px;
          height: 80px;
          bottom: 0;
          right: 0;
          position: absolute;
          opacity: 0.1;

          svg {
            fill: #fff;
            width: 80px;
            height: 80px;
          }
        }

        &.manager-icon {
          width: 100px;
          height: 100px;
          bottom: -12px;
          right: -13px;
          position: absolute;
          opacity: 0.1;

          svg {
            fill: #fff;
            width: 100px;
            height: 100px;
          }
        }

        &.medical-icon {
          width: 100px;
          height: 100px;
          bottom: -17px;
          right: 0px;
          position: absolute;
          opacity: 0.1;

          svg {
            fill: #fff;
            width: 100px;
            height: 100px;
          }
        }

        &.doctor-icon {
          width: 90px;
          height: 90px;
          bottom: -6px;
          right: -6px;
          position: absolute;
          opacity: 0.1;

          svg {
            fill: #fff;
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }
}

.no-item-selected {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--text);
  opacity: 0.3;
}

.task-completed {
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);

  .icon {
    fill: var(--primary);
    width: 300px;
    height: 150px;
    display: block;
  }

  &__message {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 15px;
  }
}

.warningSpanTooltip .tooltip-inner {
  background-color: var(--warning);
  font-weight: bold;
}

.warningSpanTooltip .tooltip-arrow:before, .warningSpanTooltip.tooltip.top .tooltip-arrow {
  border-top-color: var(--warning);
  font-weight: bold;
}
