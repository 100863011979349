table, table.table-sm {
  width: 100%;

  > thead > tr > th {
    text-align: left;
  }

  tbody {
    tr {
      &:first-child {
        border-top: none;
      }

      &:last-child {
				border-bottom: none;
			}
		}
	}
	tr {
		td, th {
			padding: 4px;
			position: relative;
		}
	}
	.number {
		text-align: right;
	}
	&.table-striped {
		> tbody > tr:nth-of-type(odd) {
			background: #f3f3f4;
		}
		> tbody > tr:nth-of-type(even) {
			background: white;
		}
	}
	h3 {
		margin: 0;
	}
}

.table-hover tbody tr:hover {
	background: #f3f3f4;
}

.table .table {
	background: none;
}

.table-scroll {
	max-width: 100%;
	overflow-y: auto;
}

.datagrid-wrapper {
	height: auto !important;
	overflow: scroll;
	flex: 1;
  @extend .flex-fix;
	.table-responsive {
		overflow-x: visible;
	}
}

.table-header {
	margin-bottom: 0;
	th {
		border-top: 3px solid var(--primary) !important;
		border-collapse: separate !important;
		span {
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
		}
		.btn-group i {
			border: none;
			border-radius: 0 !important;
			padding: 2px;
			&:hover {
				background: rgba(255, 255, 255, 0.25);
			}
		}
	}
}

// ag-grid style
.ag-theme-balham .ag-header-cell.ag-editable-cell .ag-header-cell-text, .ag-theme-balham .ag-header-group-cell.ag-editable-cell .ag-header-cell-text {
	&:before {
		content: "\f040";
		display: inline-block;
		margin-right: 0.5rem;
		font-family: "FontAwesome";
	}
}
