salesboard-detail {
  width: 67%;
  height: 100%;
  padding: 10px 20px 20px;
  position: relative;
  display: flex;
  flex-direction: column;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    height: 100%;
    width: 1px;
    background-image: linear-gradient(to bottom, white 2%, #d9dade, white 98%);
  }
}

.salesboard-detail {
  &__header {
    width: 100%;
    height: 164px;

    &__categories {
      margin-top: 8px;
    }

    h3 {
      display: flex;
      align-items: center;
      height: 40px;
      margin: 0;
      font-size: 16px;
      padding-bottom: 8px;
      font-weight: 700;
      color: var(--text);
      border-bottom: 2px dashed #eaeaf1;

      a {
        color: var(--primary);
      }

      a.medical {
        color: var(--medical);
      }
    }

    &__info {
      display: flex;
      padding: 0 10px;
    }

    &__type {
      border-radius: 6px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background: linear-gradient(#fff, #e4e4e9) no-repeat;
      box-shadow: rgba(37, 44, 97, 0.15) 0px 5px 15px 0px, rgba(93, 100, 148, 0.2) 0px 2px 4px 0px;

      .doctor__icon, .medical-facility__icon {
        fill: var(--medical);
      }

      .law-firm__icon {
        fill: var(--primary);
      }

      svg-icon, svg {
        width: 20px;
        height: 20px;
      }
    }

    &__tier {
      position: absolute;
      right: 0;
      top: 3px;
    }
  }

  &__content {
    display: flex;
    flex: 1 1 1px;
  }

  &__notes, &__todos {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .add-activity-btn {
      fa-icon {
        margin-right: 5px;
      }
    }

    .notes__add-btn, .todos__add-btn {
      position: absolute;
      bottom: 10px;
      right: 12px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--warning);
      cursor: pointer;
      box-shadow: 0 1.5rem 3.5rem rgba(50, 50, 93, .2), 0 0.5rem 1.5rem rgba(0, 0, 0, .15);
      transition: all 0.3s;

      i, fa-icon {
        color: #fff;
      }

      &:hover {
        transform: translateY(-5px);
        background-color: var(--warning-hover);
      }
    }

    &__header {
      h3 {
        display: flex;
        align-items: center;
        height: 40px;
        margin: 0;
        font-size: 16px;
        padding-bottom: 8px;
        margin-bottom: 10px;
        font-weight: 700;
        color: var(--text);
        border-bottom: 2px dashed #eaeaf1;
      }
    }

    &__daterange {
      flex: 1;
      margin-right: 16px;
      margin-bottom: 10px;
      position: relative;

      .fa {
        position: absolute;
        top: 11px;
        right: 11px;
        color: var(--text);
        font-size: 16px;
        cursor: pointer;
      }

      .icon {
        position: absolute;
        top: 7px;
        right: 6px;
        fill: var(--primary);

        svg {
          width: 26px;
          height: 26px;
        }
      }
    }

    &__pool {
      border-radius: 10px;
      background-color: #f1f1f6;
      display: grid;
      grid-gap: 8px;
      grid-auto-rows: max-content;
      padding: 15px;
      width: 100%;
      overflow-y: scroll;
      flex: 1 1 1px;
      position: relative;

      &__shadow-top {
        position: absolute;
        z-index: 2;
        width: calc(100% - 18px);
        height: 1px;
        top: 101px;
        left: 9px;
        box-shadow: 0 -1px 3px 0 rgba(100, 100, 100, 0.3);
      }

      &__shadow-bottom {
        position: absolute;
        z-index: 2;
        width: calc(100% - 18px);
        height: 1px;
        bottom: -2px;
        left: 9px;
        box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__todos {
    &__filter {
      height: 40px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      &__info {
        display: flex;
        align-items: center;
        font-weight: 600;
        width: 100%;
        color: var(--text);
        position: relative;

        .fa, fa-icon {
          position: absolute;
          right: 5px;
          color: var(--text);
          cursor: pointer;
        }
      }

      &__title {
        margin-right: 10px;
        font-weight: 400;
        color: #9294ba;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }

  &__sidebar {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    &__header {
      h3 {
        display: flex;
        align-items: center;
        height: 40px;
        margin: 0 0 10px 0;
        font-size: 16px;
        padding-bottom: 8px;
        font-weight: 700;
        color: var(--text);
        border-bottom: 2px dashed #eaeaf1;
      }
    }
  }
}

salesboard-detail-notes, salesboard-detail-todos, salesboard-detail-sidebar {
  flex: 1;
}

salesboard-detail-notes, salesboard-detail-todos {
  margin-right: 20px;
}


.info-widget {
  display: flex;
  align-items: center;
  padding: 0 25px 0 0;
  position: relative;
  color: var(--text);
  font-weight: 700;
  line-height: 16px;

	&__wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}

  svg {
    width: 20px;
    height: 20px;
    fill: var(--primary);
  }

  .info {
    margin-left: 10px;
    padding-left: 10px;
    font-size: 12px;
    font-weight: 400;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      width: 1px;
      background: linear-gradient(to bottom, transparent 2%, #d9dade, transparent 98%);
    }

    .info-label {
      font-weight: 400;
    }
  }

  &.editable {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: var(--soft-grey);
    }
  }

  &__editor {
    width: 100%;
    padding: 15px;
    border: 1px solid var(--soft-grey);
    border-radius: 5px;
  }
}


.activity-detail-sidebar {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  .salesboard-detail-notes {
    margin: 0;
  }

  .salesboard-activity-type-filter {
    position: relative;
    min-height: 40px;
    margin-bottom: 10px;

    .select-item__content {
      display: flex;
      flex-wrap: wrap;
    }

    .activity-detail-label {
      display: flex;
      align-items: center;
    }
  }

  .salesboard-detail__notes__pool__shadow-top {
    top: 54px;
  }

  .loading-panel {
    top: 46px !important;
    height: calc(100% - 36px) !important;
  }

  .placeholder {
    font-style: italic;
    color: #bcbed0;
    display: flex;
    align-items: center;
    height: 3rem;
  }
}

.medical-icon > svg {
  fill: var(--medical);
}
