$btn-color: var(--primary);
$btn-hover-color: var(--primary-hover);

$background-grey: #f4f5f7;
$background-grey-hover: #f1f1f6;
$border-grey: #dfe1e6;

button {
  &:hover, &:focus, &:active {
    outline: none;
  }
}

.todo-widget {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  z-index: 1052;
  transform: translateX(400px);
  transition: all 0.3s ease-out;

  &.expand {
    transform: translateX(0);
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .todo-widget__expand-btn {
      position: absolute;
      padding-left: 1px;
      top: 59px;
      left: -50px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      display: flex;
      border-left: 1px solid var(--grey);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.4s;

      &.expand {
        border-radius: 5px 0 0 5px;
        box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        right: -10px;
        height: 50px;
        width: 10px;
        background-color: #fff;
      }

      .svg-icon, svg {
        width: 30px;
        height: 30px;
        fill: var(--primary);
      }

      &__notification-dot {
        position: absolute;
        bottom: 3px;
        left: 1px;
        width: 12px;
        height: 12px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &__wrapper {
          position: relative;
          width: 100%;
          height: 12px;
          z-index: 0;
        }

        div {
          width: 18px;
          height: 18px;
          border-radius: 0 7px 0 0;
          background-color: var(--primary);
          position: relative;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: white;
          }

          .bg-color-red {
            background-color: #fb2b2b;
          }
        }
      }

      &__notification-dot.mention {
        bottom: 35px;

        div {
          border-radius: 0 0 7px 0;
          background-color: var(--funded);
        }
      }

    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;

    &__btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &__controls {
      display: flex;
      height: 40px;
      width: 100%;
    }

    &__summary {
      background-color: $background-grey;
      border: 1px solid $border-grey;
      color: var(--text);
      display: flex;
      width: 100%;
      padding: 0 10px;
      height: 40px;
      border-radius: 5px 5px 0 0;
      font-size: 14px;
      outline: none;
      transition: all 0.4s;

      &__max-length {
        color: var(--text);
        font-size: 9px;
        position: absolute;
        top: 25px;
        right: 9px;
        font-style: italic;
      }

      &:active, &:focus {
        background-color: #fff;
      }

      &:hover, &:focus, &:active {
        outline: none;
      }

      &::placeholder {
        font-style: italic;
      }
    }

    &__description {
      background-color: $background-grey;
      border: 1px solid $border-grey;
      color: var(--text);
      display: flex;
      border-top: none;
      width: 100%;
      height: 77px;
      resize: none;
      padding: 10px;
      margin-bottom: 0;

      &:active, &:focus {
        background-color: #fff;
      }

      &:hover, &:focus, &:active {
        outline: none;
      }

      &::placeholder {
        font-style: italic;
      }
    }

    &__category {
      display: flex;
      height: 40px;
      width: 25%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $background-grey;
      border-bottom: 1px solid $border-grey;
      position: relative;

      &__item {
        padding: 4px 6px;
        border-radius: 5px;
        background-color: #fff;
        background-image: linear-gradient(180deg, #fff, #f5f5fa);
        box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 1px 3px 0 rgba(93, 100, 148, 0.2);
        position: relative;
        font-weight: 700;
        font-size: 10px;
      }

      .quick-list {
        right: unset;
      }

      .svg-icon {
        width: 20px;
        height: 20px;
        fill: #73768c;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &:hover {
        background-color: $background-grey-hover;
      }
    }

    &__assignee {
      height: 40px;
      width: 25%;
      border-left: 1px solid $border-grey;
      border-bottom: 1px solid $border-grey;
      border-right: 1px solid $border-grey;
      border-radius: 0 0 0 5px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $background-grey;
      fill: #73768c;
      transition: all 0.4s;

      .quick-list {
        right: unset;
        min-height: 135px;
      }

      .svg-icon {
        width: 40px;
        height: 40px;
        fill: #dfdfe0;

        svg {
          width: 40px;
          height: 40px;
        }
      }

      &:hover {
        background-color: $background-grey-hover;
      }
    }

    &__datepicker {
      height: 40px;
      width: 25%;
      border-bottom: 1px solid $border-grey;
      border-right: 1px solid $border-grey;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $background-grey;
      transition: all 0.4s;

      &__wrapper {
        position: absolute;
        top: 44px;
        margin: 0 auto;
        width: 215px;
        height: 385px;
        background-color: #fff;
        z-index: 10;
        border-radius: 5px;
        box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);

        .custom-datepicker-theme {
          .bs-datepicker-head {
            min-width: 215px;
          }
        }

        &__timepicker {
          position: relative;
          padding: 1px 10px;

          .clear {
            position: absolute;
            bottom: 0;
            right: 20px;
            font-size: 11px;
            color: var(--primary);
            cursor: pointer;
          }
        }

        &__timezone {
          padding: 8px 6px;
        }
      }

      .svg-icon {
        width: 30px;
        height: 30px;
        fill: #73768c;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      &:hover {
        background-color: $background-grey-hover;
      }

      &__date {
        background-color: #fff;
        border-radius: 5px;
        width: 35px;
        height: 40px;
        box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 1px 3px 0 rgba(93, 100, 148, 0.2);
        position: relative;

        &__day {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $btn-color;
          font-size: 13px;
          font-weight: 700;
          height: 25px;
        }

        &__month {
          display: flex;
          background-color: $btn-color;
          justify-content: center;
          color: #fff;
          font-size: 10px;
          font-weight: 600;
          height: 15px;
          border-radius: 0 0 5px 5px;
        }

        &__clear {
          position: absolute;
          top: -5px;
          right: 0;

          i, fa-icon {
            font-size: 10px;
            color: #ED5565;
          }
        }
      }
    }

    &__btn {
      height: 40px;
      min-width: 25%;
      border-radius: 0 0 5px 0;
      border: 0px solid transparent;
      background-color: $btn-color;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      transition: background-color 0.4s;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }

      &:hover {
        background-color: $btn-hover-color;
      }
    }
  }

  .user-filter {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 10px 15px;
    align-items: center;
  }

  &__filter {
    display: flex;
    position: relative;
    padding: 0 15px;

    &__item {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      color: #868686;
      font-weight: 600;
      font-size: 11px;
      cursor: pointer;

      &.active {
        color: var(--primary);
      }
    }

    &__line {
      position: absolute;
      height: 3px;
      border-radius: 5px;
      background-color: var(--primary);
      bottom: 0;
      left: 15px;
      width: calc((100% - 30px) / 4);
      transition: all 0.2s;
    }

    &__line.mentions-tab-visible {
      width: calc((100% - 30px) / 5);
    }
  }

  &__content {
    padding: 0 15px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 1px;

    &__shadow-top {
      position: absolute;
      z-index: 2;
      width: calc(100% - 40px);
      height: 1px;
      top: 0;
      left: 20px;
      box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    &__shadow-bottom {
      position: absolute;
      z-index: 2;
      width: calc(100% - 40px);
      height: 1px;
      bottom: 14px;
      left: 20px;
      box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    &__pool {
      padding: 15px;
      border-radius: 5px;
      background-color: #f1f1f6;
      display: grid;
      grid-gap: 15px;
      flex: 1 1 1px;
      grid-auto-rows: max-content;
      overflow-y: scroll;
      position: relative;
    }

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.badge-more {
  position: absolute;
  top: -3px;
  right: -4px;
  width: 14px;
  height: 14px;
  background-color: var(--primary);
  border-radius: 50%;
  font-size: 8px;
  font-weight: 700;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-filter {
  position: relative;

  &__box {
    display: inline-flex;
    border-radius: 5px;
    background-color: transparent;
    color: #686a7e;
    min-width: 140px;
    font-weight: 600;
    font-size: 12px;
    height: 40px;
    padding: 0 14px;
    cursor: pointer;
    align-items: center;
    transition: all 0.4s;

    &__wrapper {
      display: flex;
    }

    &__label {
      padding: 4px 6px;
      margin-right: 5px;
      border-radius: 5px;
      background-color: #fff;
      background-image: linear-gradient(180deg, #fff, #f5f5fa);
      box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 1px 3px 0 rgba(93, 100, 148, 0.2);
      position: relative;
      font-weight: 700;
      font-size: 10px;

      &:last-child {
        margin-right: 15px;
      }
    }

    i {
      margin-left: auto;
    }

    &:hover, &.active {
      background-color: #f1f1f6;
    }
  }
}

.todos-list-widget {
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
  border-radius: 5px;
  padding-top: 15px;
}
