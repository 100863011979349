.base-icon {
  width: 20px;
  height: 20px;

  svg {
	width: inherit;
	height: inherit;
	fill: #fff;
  }

  &.medical {
	svg {
	  fill: var(--medical);
	}
  }

  &--small {
	width: 14px;
	height: 14px;
  }
}

svg-icon {
	&.base-icon--nav {
	  svg {
		fill: var(--text);
	  }
	}
}

.active {
  svg-icon {
	&.base-icon--nav {
	  svg {
		fill: var(--primary);
	  }
	}
  }
}

svg-icon {
  &.white {
	svg {
	  fill: #fff;
	}
  }

  &.danger-color {
	svg {
	  fill: var(--danger);
	}
  }

  &.grey-color {
	svg {
	  fill: var(--grey);
	}
  }

  &.primary-color {
	svg {
	  fill: var(--primary);
	}
  }

  &.text-color {
	svg {
	  fill: var(--text);
	}
  }

	&.info-color {
		svg {
			fill: #5b5e77;
		}
	}

  &.board-filter__active {
    svg {
      fill: #b00c28;
    }
  }

  svg {
	width: inherit;
	height: inherit;
  }
}
