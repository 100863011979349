.row-form {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  &--info {
    padding-bottom: 17px;
    position: relative;

    .info {
      position: absolute;
      right: 6px;
      color: var(--primary);
      font-size: 11px;
    }
  }

  &--block {
    display: block;
    margin-bottom: 10px;
    padding-bottom: 0;

    .info {
      bottom: -14px;
    }
  }

  &--inline {
    justify-content: space-between;

    label {
      width: 45%;
    }

    input, select, ng-select {
      width: 50%;
      margin-bottom: 5px;
    }

    &.error {
      .error-msg {
        opacity: 1;
        color: var(--danger);
        font-weight: 700;
      }
    }
  }

  &__group {
    flex-grow: 1;
    margin-right: 5px;

    input, select, ng-select {
      margin: 0;
    }

    &:last-child {
      margin-right: 0px;
    }

    &.error {
      .error-msg {
        opacity: 1;
        color: var(--danger);
        font-weight: 700;
      }
    }
  }

  &__add-new {
    position: absolute;
    bottom: -15px;
    right: 47px;
    margin-top: 5px;
    color: var(--primary);
    font-size: 12px;
    cursor: pointer;
  }

  label {
    display: flex;
    font-weight: 600;
    font-size: 12px;
    color: #686a7e;

    .required {
      margin-left: auto;
      font-size: 12px;
      opacity: 0.8;
      font-weight: 500;
      color: var(--grey-dark);
      margin-right: 5px;

      &.left {
        margin-left: 0px;
      }
    }

    .funded-amount {
      font-size: 12px;
      font-weight: 600;
      color: #686a7e;
      opacity: 1;
    }
  }

  input, select, ng-select {
    flex-grow: 1;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #dfe1e6;
    border-radius: 5px;
    color: #73768c;
    background-color: #f4f5f7;
    margin-right: 5px;
    width: 100%;

    &:hover, &:active, &:focus {
      outline: none;
    }

    &:disabled {
      color: #dfe1e6;

      +label {
        color: #dfe1e6;
      }
    }
  }

  input[type="radio"] {
    width: 10px;
    height: 10px;
    display: inline-block;
  }

  .radio label {
    display: inline-block;
  }

  textarea {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #dfe1e6;
    border-radius: 5px;
    color: #73768c;
    background-color: #f4f5f7;
    width: 100%;
    min-height: 100px;
  }

  &__actions {
    display: flex;
    align-items: center;

    button {
      display: inline-flex;
      height: 40px;
      width: 45px;
      background-color: var(--primary);
      border-radius: 5px;
      color: #fff;
      align-items: center;
      justify-content: center;
      border: none;
      font-size: 17px;
      margin-right: 5px;
    }

    svg-icon, svg {
      width: 20px;
      height: 20px;
      fill: #e85353;
    }
  }

  &--reverse {
    flex-direction: row-reverse;
  }

	.disabled {
		pointer-events: none;
		opacity: 0.5;
		transition: all 1s;
	}
}
