lien-letters {
	width: 100%;
	.panel {
	  padding: 0 20px 20px 20px;
		.onoffswitch-label {
			margin-bottom: 0px;
		}
	}

  	items-selector {
	  .btn-warning {
		background-color: #f0ad4e;
		border-color: #eea236;
	  }
	}
  	#bottom-bar {
	  padding: 15px 0 0 0;
	}
	table tr td:first-child {
		padding-left: 20px;
	}
	.lien-letter-heading {
		padding: 10px;
	}
}
