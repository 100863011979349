medical-detail-content {
  appointment-card {
    margin-left: 5px;
  }
}

.appointment {
  width: calc(100% + 5px);
  display: flex;
  align-items: stretch;
  font-size: 10px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
  line-height: 16px;
  position: relative;
  transform: translateX(-5px);
  transition: all 0.4s;

  .p-card-content {
    padding: 0;
  }

  &.editMode .p-card-body {
    padding-top: 1.25rem;
  }

  .p-card-body {
    padding-top: 0;
  }

  &__right {
    display: flex;
    flex: none;
  }

  &__procedure {
    color: var(--text);
    padding-right: 10px;
  }

  &__doctor {
    color: var(--primary);
    font-weight: 700;

    a, a:hover, a:focus, a:active {
      color: var(--primary);
      padding-right: 5px;
      cursor: pointer;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    color: var(--text);
    font-weight: 400;
    opacity: 0.8;

		.telemed {
			color: var(--primary);
			font-size: 12px;
			font-weight: 600;
		}
  }

  &__form {
    position: relative;

    .loading-panel__message__text {
      font-weight: 400;
    }

		.disabled {
			cursor: not-allowed;
			pointer-events: none;
			opacity: 0.5;
		}
  }

  &__confirmation-dialog {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--text);
  }

  &__datepicker-wrapper {
    position: absolute;
    bottom: 40px;
    margin: 0 auto;
    width: 200px;
    height: 308px;
    background-color: #fff;
    z-index: 10;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  }

  &__doctor__address {
    color: #b6b9d3;
  }

  &__doctor__phone {
    font-style: italic;
    font-weight: 400;
  }
}


.whiteboard-appointments {
  padding: 0 5px;
  margin: 10px 0;

  &__wrapper {
    display: flex;
    align-items: center;

    i {
      margin-left: auto;
      cursor: pointer;
      color: var(--primary);
    }
  }

  .appointment {
    width: calc(100% + 10px);
  }

  .appointment__doctor__address {
    color: rgba(0, 0, 0, 0.75);
  }

  .appointment__procedure {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
  }
}
