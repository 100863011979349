.base-info-box {
  width: 100%;
  padding: 20px 20px 20px 60px;
  display: flex;
  align-items: center;
  background-color: var(--soft-grey);
  color: var(--text);
  border-radius: 10px;
  font-size: 12px;
  position: relative;

  span {
	color: var(--primary);
	font-weight: 600;
  }

  p {
	margin-bottom: 5px;
  }

  &__text {
	position: relative;

	&::before {
	  content: '';
	  display: block;
	  width: 1px;
	  height: 100%;
	  background: linear-gradient(to bottom, var(--soft-grey) 2%, var(--grey), var(--soft-grey) 98%);
	  position: absolute;
	  top: 0;
	  left: -13px;
	}
  }

  .base-icon {
	position: absolute;
	left: 15px;
	svg {
	  fill: var(--primary);
	}
  }
}

@media only screen and (max-width: 1700px) {
	.base-info-box {
	  padding: 10px;

	  .base-icon {
		display: none;
	  }
	}
}
