//flex grid

.flex {
  display: flex !important;
  flex-grow: 1;
  flex-basis: 0;
  &-center {
    display: flex ;
    align-items: center;
  }
  &-base {
    display: flex;
    align-items: baseline;
  }

  &.edit {
    margin-left: auto;
  }
}

.flex-item, .f-child-wide {
  flex: 1;
  @extend .flex-fix;
}

.f-child-auto {
  flex: 0;
  @extend .flex-fix;
}


@supports (-moz-appearance:none) {
  .flex-fix {
    flex-basis: 0;
  }
}

.flex-col {
  width: 100%;
  display: flex !important;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.gap-1 {
  row-gap: 0.25rem;
  column-gap: 0.25rem;
}

.gap-2 {
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.gap-4 {
  row-gap: 1rem;
  column-gap: 1rem;
}
