:root {
  --font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

html {
  font-size: 12px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h1 {
  font-size: 30px;
  margin: 12px 0;
}

h2 {
  font-size: 24px;
  margin: 8px 0;
}

h3 {
  font-size: 20px;
  margin: 6px 0;
}

h4 {
  font-size: 16px;
  margin: 5px 0;
}

h5 {
  font-size: 14px;
  margin: 4px 0;
}

h6 {
  font-size: 12px;
  margin: 3px 0;
}

a {
  color: var(--primary);
  cursor: pointer;

  &:hover {
    color: unset;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--primary-hover);
    }
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

