button.base-button, a.base-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 120px;
  border-radius: 5px;
  border: 0 solid transparent;
  background-color: var(--primary);
  color: #fff;
  font-weight: 700;
  font-size: 11px;
  transition: background-color 0.4s;

  &:disabled, &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover, &.active {
    background-color: var(--primary-hover);
  }

	&--large {
		min-width: 180px;
		height: 54px;
		font-size: 12px;
	}

  &--small {
    min-width: 80px;
    height: 34px;
    font-size: 11px;
  }

  &--wider {
    min-width: 160px;
  }

  &--cancel {
    background-color: transparent !important;
    color: var(--text);
    transition: all 0.4s;

    @media (hover: hover) {
      &:hover {
        background-color: transparent !important;
        color: var(--primary);
      }
    }
  }

  &--round {
    width: 40px;
    height: 40px;
    min-width: unset;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    cursor: pointer;
    box-shadow: 0 1.5rem 3.5rem rgba(50, 50, 93, 0.2), 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
  }

  &--square {
    width: 32px;
    height: 32px;
    min-width: unset;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary);
    cursor: pointer;
    transition: all 0.3s;
  }

  &--grid {
    line-height: initial;
  }

  &--danger {
    background-color: var(--danger);

    &:hover {
      background-color: var(--danger-hover);
    }
  }

  &--warning {
    background-color: var(--warning);

    &:hover {
      background-color: var(--warning-hover);
    }
  }

  &--info {
    background-color: var(--info);

    &:hover {
      background-color: var(--info-hover);
    }
  }
}

.base-button-group {
  border-radius: 5px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;

  &.small {
    height: 30px;
  }

  &.full-width {
    display: flex;

    button.base-button {
      flex: 1;
      padding: 0 5px;
    }
  }

  button.base-button {
    padding: 0 15px;
    min-width: unset;
    border-radius: 0;
    border-right: 1px solid var(--primary-hover);

    &:last-child {
      border-right: none;
    }

    &.active {
      box-shadow: 0 0 5px 0 rgba(100, 100, 100,0.2) inset;
      background-color: var(--primary-hover);
    }
  }
}


// file button
.file-button {
	cursor: pointer;
	position: absolute;
	top: 3px;
	right: 3px;
	width: 32px;
	height: 32px;
	border-radius: 5px;
	background-color: var(--primary);
	display: flex;
	align-items: center;
	justify-content: center;

	.base-icon {
		width: 16px;
		height: 16px;
	}
}
