ag-grid-angular {
  &.grid-list {
    flex: 1;
    overflow: hidden;
    height: 100vh;
  }

  .no-text-wrap .ag-header-cell-text {
    white-space: normal;
  }
}

.ag-chart-tooltip-title {
  background-color: var(--primary);
}
