.modal-backdrop {
  z-index: 1054;
}

.modal {
  z-index: 1060;
}

.new-modal {
  background-color: #fff;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    //border-bottom: 1px solid #eee;
    height: 80px;

    h3 {
      margin: 0;
      color: var(--primary);
      font-weight: 600;
    }

    &__subtitle {
      color: #c3c3da;
      margin-top: 3px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__content {

    &__form-group {
      padding: 20px;

      &--colorize {
        background-color: #f3f3f4;
      }

      &--bordered {
        border-bottom: 1px solid #eee;
      }

      &__label {
        display: block;
        color: var(--text);
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 12px;
      }
    }

    &__left, &__right {
      width: 49%;
    }

    &__steps {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: relative;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90px;
        position: relative;
        top: -19px;
        transition: all 0.3s;

        &__label {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #fff;
          border: 3px solid #c3c3da;
          color: #f3f3f4;
          position: relative;
          cursor: pointer;
          transition: all 0.2s;

          svg-icon {
            position: absolute;
            top: 7px;
            left: 9px;
            width: 18px;
            height: 18px;
            fill: #c3c3da;
            transition: all 0.2s;
          }
        }

        &.active {
          .new-modal__content__steps__item__label {
            border-color: var(--primary);
          }

          svg-icon {
            fill: var(--primary);
          }

          .new-modal__content__steps__item__title {
            color: var(--primary);
          }
        }

        &__title {
          font-size: 12px;
          font-weight: 600;
          color: #c3c3da;
          transition: all 0.3s;
        }
      }

      &__line {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #c3c3da;
        transition: all 0.3s;
      }

      &__line-step {
        position: absolute;
        top: -1px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--primary);
        transition: all 0.3s;

        &.step1 {
          width: 30%;
        }

        &.step2 {
          width: 70%;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid #eee;

    &__steps-nav {
      &__next, &__previous {
        cursor: pointer;
        height: 44px;
        display: flex;
        align-items: center;
        color: var(--soft-text);
        font-weight: 600;

        i {
          color: var(--primary);
          margin: 0 5px;
          font-size: 20px;
        }
      }
    }

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal-tabs {
  .tab-content, .tab-pane {
    overflow: initial !important;
  }

  // INFO Used for re-ordering tabs in add note section
  .order1 {
    order: 1
  }

  .order2 {
    order: 2
  }

  .order3 {
    order: 3
  }

  .order4 {
    order: 4
  }
}

.modal--datepicker {
  width: 240px;
  min-width: unset;

  .modal-content {
    border: none;
    background-color: transparent;
    border-radius: 15px;
  }

  .modal-body {
    width: 240px;
    min-width: unset;
    padding: 0;
    border-radius: 15px;
  }

  .custom-datepicker-theme .bs-datepicker-head {
    min-width: 240px;
  }
}

.board-modal {
  width: 80% !important;
  max-width: 1800px;
  height: calc(100vh - 15%);

  .modal-content {
    height: 100%;
    padding: 16px;
    border-radius: 10px;
  }
}

.warning-confirmation-modal {
  .modal-content {
    border-radius: 10px;
    width: 30vw;
    padding: 20px;

    &__left {
      margin-right: 20px;
      display: flex;
      align-items: flex-start;

      &__icon {
        font-size: 100px;
        color: var(--warning);
      }
    }

    &__right {
      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;

        h3 {
          color: var(--warning);
        }
      }

      &__content {
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;
        border-top: 1px solid #eee;
      }
    }
  }
}

.medical-markers-map > .modal-content {
  width: 79vw;
}

.wide-modal > .modal-content {
  width: 79vw;
}

.small-modal > .modal-content {
  width: 30vw;
}

.centered-modal-xl {
  display: inline-table;
}
