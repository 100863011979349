div.overlay-panel-overlay {
  top: 0;
  z-index: 101;
  background-color: white;
  position: absolute;
  overflow: hidden;
  right: 0;
  height: 100%;
  width: 30%;
  padding: 30px 20px;
  button[title="Close"] {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .form-group {
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
  }
}

.overlay-panel-dim {
  top: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
}
