.base-table {
  color: var(--text);
  margin-bottom: 40px;
  font-size: 12px;

	&--striped > tbody > tr:nth-of-type(even) {
		background-color: #f8f8ff;
	}

  th, td {
		margin-bottom: 1px;
  }

	&--big-rows {
		tr td, tr th {
			padding: 10px;
		}
	}

  &__header {
		font-weight: 700;
		font-size: 14px;
		color: var(--primary);
		margin-bottom: 5px;
  }

  &__underline {
		border-bottom: 1px solid var(--primary);
  }

  &__fill {
		background-color: #f1f1f6;
  }

	&--strong {
		font-weight: 700;
	}

	th {
	   &.colorize {
		 color: var(--primary);
	   }
	}
}
