.user-filter {
  display: flex;
  height: 100%;

  .user__avatar {
    margin: 0 2px;
    cursor: pointer;
    transition: all 0.4s;

    &.active {
      background-color: var(--primary);
      background-image: linear-gradient(180deg, var(--primary-hover), var(--primary));
      color: #fff;
    }

    &:hover {
      transform: translateY(-3px);
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 30px;
    font-size: 16px;
    color: #dedee4;
    opacity: 0;
    transform: scale(0);
    transition: all 0.4s;
    &.active {
      opacity: 1;
      transform: scale(1);
      cursor: pointer;
    }
    &:hover {
      color: var(--primary);
    }
  }
}
