.copy-tooltip {
  position: relative;
  display: inline-block;
  cursor: copy;
  transition: all 0.3s;

  &:hover {
    color: #ff6f00;
  }
}

.copy-tooltip:before {
  content: "Copied!";
  width: 90px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -45px;
  visibility: hidden;
  opacity: 0;
  font-size: 11px;
  font-weight: 400;
  transition: opacity 0.3s;
}

.copy-tooltip:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 5px;
  border-width: 5px;
  border-style: solid;
  visibility: hidden;
  opacity: 0;
}

.copy-tooltip.top:before {
  bottom: calc(100% + 10px);
}

.copy-tooltip.top:after {
  bottom: 100%;
  border-color: #555 transparent transparent transparent;
}

.copy-tooltip.bottom:before {
  top: calc(100% + 10px);
}

.copy-tooltip.bottom:after {
  top: 100%;
  border-color: transparent transparent #555 transparent;
}

.copy-tooltip.tooltip-visible:after, .copy-tooltip.tooltip-visible:before  {
  visibility: visible;
  opacity: 1;
}

.copy-button {
  color: var(--copy-button-color, var(--text));
  opacity: 0.6;
  font-size: 10px;
  font-weight: 700;
  cursor: copy;
  margin: 6px;
  transition: all 0.3s;

  &:hover {
    color: #ff6f00;
  }
}
