/*******************************
  NEW Applicant detail
 *******************************/
.applicant-detail {
  @media screen and (max-width: 1024px) {
    display: block;
  }

  //
  //timeline
  //
  .rp-sidebar {
    border-left: 1px solid #ccc;;
    p {
      word-break: break-all !important;
    }
  }

  .tab-pane {
    padding: 0;
  }

  .timeline-wrapper {
    margin-bottom: 0;
    .ibox-title {
      padding: 6px 6px 4px 10px;
      border-top: none;
      border-bottom: 2px solid var(--primary);
      min-height: 31px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-left: 2px;
      h3 {
        margin: 0;
        flex: 1;
        @extend .flex-fix;
      }
    }
    .input-group {
      margin: 0 10px 10px 10px;
    }
    .input-group-addon {
      border-radius: 4px;
    }
    .form-control:focus, .single-line:focus {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-color: #e5e6e7 !important;
      background-color: #fafafa;
    }
    .activity-stream {
      flex: 1;
      @extend .flex-fix;
      overflow-y: auto;
      padding-right: 10px;
      padding-top: 10px;
      border-top: 1px solid #ccc;;
    }
  }

 #applicant-detail-sms-btn {
		position: relative;
		.badge {
			position: absolute;
			bottom: -2px;
			left: -4px;
			background-color: #ED5565;
			color: #FFFFFF;
			z-index: 999;
			display: inline-block;
		}
	}

  .last-service-date {
    color: var(--primary);
    padding: 5px 10px;
    justify-content: space-between;
  }

  .service-note {
    background: var(--primary);
    padding: 10px 10px 1px 10px;
    color: white;
  }
}

.funding-funded {
  .funding-icon {
    background-color: var(--success);
    color: #fff;
  }
}

.funding-approved {
  .funding-icon {
    background-color: var(--info);
    color: #fff;
  }
}

.funding-external-portfolio {
  .funding-icon {
    background-color: var(--text);
    color: #fff;
  }
}

.funding-rejected {
  .funding-icon {
    background-color: var(--danger);
    color: #fff;
  }
}

.funding-paid {
  .funding-icon {
    background-color: var(--primary);
    color: #fff;
  }
}

.funding-pending {
  .funding-icon {
    background-color: var(--warning);
    color: #fff;
  }
}

.funding-consult {
  max-height: 43px;
  .funding-icon--svg {
    background-color: var(--warning);
    height: 43px;
    width: 40px;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      top: -8px;
      left: -10px;
      width: 60px;
    }
  }
}

.funding-funded, .funding-approved, .funding-rejected, .funding-paid, .funding-pending, .funding-consult {
  &.archived {
    .funding-icon, .funding-icon--svg {
      background-color: #D1DADE;
    }
  }
}

#fundings-accordion {
  & * {
    &:hover, &:focus, &:active {
      outline: none !important;
    }
  }

  .funding-control-header {
    display: flex;
    margin-bottom: 5px;
  }

  .panel-body {
    padding: 0;
    border-top: none;
  }
  .tab-pane .panel-body {
    padding: 0 10px 10px 10px;
    .row {
      margin: 0;
    }
  }
  .funding-icon {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 32px;
    padding: 0;
    text-align: center;
    color: #fff;
    //border-bottom-left-radius: 3px;
    //border-top-left-radius: 3px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      min-width: 21px;
    }
  }
  .funding-icon.fa-medkit {
    background: none;
    color: #676a6c;
    font-size: 20px;
    margin-left: 2px;
  }

  .funding-icon.fa-money {
    background: none;
    color: #676a6c;
    font-size: 20px;
    margin-left: 2px;
  }

  .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus {
    background-color: #1a7bb9;
  }
}

.folder-list {
  padding: 0;

  font-size: 12px;
  line-height: 21px;

  .label {
    display: block;
    margin-top: 8px;
  }
}

dd {
  margin-bottom: 15px;
}

.upcoming-task {
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 10px;
  border: 1px solid var(--info-bg);
  background-color: var(--info-bg);
  color: var(--info-text);

  .fa {
    float: left;
    margin-right: 15px;
    font-size: 18px;
    border: 1px solid var(--btn-primary-border);
    background-color: var(--btn-primary-border);
    color: #fff;
    border-radius: 50%;
    padding: 12px;
  }

  .date {
    margin-left: 57px;
    font-size: 11px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

#fundings-accordion .panel-heading {
  padding: 0;
  .funding-header {
    display: flex;
    align-items: stretch;
    border-radius: 0;
    > span {
      flex: 1;
      @extend .flex-fix;
      padding: 5px;
      &:hover {
        color: var(--primary);
      }
    }

    button {
      border-radius: 3px;
      font-size: 14px;

      margin: 5px 5px 5px 0;
    }
  }
  .accordion-toggle {
    button {
      width: 100%;
      //i:not(.fa-cog) {
      //
      //}
    }
    &:hover {
      color: initial;
    }
  }
  .actions {
    align-content: stretch;
    align-items: stretch;
  }
  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    .actions {
      margin-right: 5px;
      align-content: center;
      align-items: center;
      button {
        border-radius: 0;
        margin: 0;
      }
      button span {
        display: none;
      }
    }
  }
}

br.clear {
  display: block;
  clear: both;
}

.applicant-detail-header-fields {
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  last-change {
      display: none;
    }
}

.sms-header {
  background: var(--primary);
  color: white;
}

.file-panel {
  position: relative;
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 10px;

  .files-dropzone-wrapper {
    position: relative;
    background: rgb(73, 157, 134);
    top: 0;
    width: 100%;
    height: 100%;

    .files-dropzone-area {
      display: grid;
      grid-gap: 1px;
      grid-template-columns: repeat(3, 1fr);
      box-sizing: border-box;
      border: 2px solid rgb(73, 157, 134);

      .file-upload-area {
        display: grid;
        align-items: center;
        justify-items: center;
        background: rgb(255, 255, 255);
        height: 100px;

        &.nv-file-over {
          opacity: 0.8;
        }

        &:last-child {
          grid-column: span 3;
        }
      }
    }
  }
}

.underwriting-checklist {
  padding-top: 15px;
}

.underwriting-checklist-item {
  flex-wrap: wrap;
  > span {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }
  strong {
    flex: 1 1 auto;
    text-align: right;
    padding-left: 30px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner-loading {
  animation: spin 1s infinite linear;
}

.applicant-detail-damages-content {
  display: flex;
  flex-direction: column;
}

.medical-icon > svg {
  fill: var(--medical);
}
