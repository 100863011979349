.base-grid {
  display: grid;
  grid-gap: 20px;

  &--one-col {
    grid-template-columns: 1fr;
  }

  &--two-col {
    grid-template-columns: 1fr 1fr;
  }

  &--three-col {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1700px) { //resolution for 125% scale
  .base-grid {
    &--two-col-sm {
      grid-template-columns: 1fr 1fr;
    }
    &--one-col-sm {
      grid-template-columns: 1fr;
    }
  }
}
