.tooltip-reset-stage {
	width: 150px;

	&.top .tooltip-arrow:before,
	&.top .tooltip-arrow {
		border-top-color: var(--rejected);
	}

	.tooltip-inner {
		max-width: 150px;
		background-color: var(--rejected);
	}
}

.tooltip-full-width {
	.tooltip-inner {
		max-width: unset;
	}
}
