.base-form {
	&__view {
		width: 100%;
		max-width: 1100px;
		margin: 20px auto;
	}

	&__header {
		display: flex;
		align-items: center;
		padding: 0 15px;

    &-blur {
      backdrop-filter: blur(9px);
      background-color: rgb(255 255 255 / 0.85);
    }

		&__title {
			font-size: 24px;
			font-weight: 700;
			color: var(--primary);
		}

		&__actions {
			margin-left: auto;
		}
	}
}

.wizard-form {
	&__header {
		display: flex;
		align-items: center;
		position: relative;
		margin-bottom: 10px;

		&:before {
			z-index: 0;
			content: '';
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: 5px;
			background-color: var(--grey);
		}

		&__title {
			font-weight: 700;
			color: var(--primary);
			background-color: #fff;
			padding-right: 5px;
			position: relative;
			z-index: 1;
		}

		&__actions {
			margin-left: auto;
		}
	}
}
