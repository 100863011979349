.contact-card {
  --copy-button-color: white;
  border-radius: 5px;
  background-color: var(--primary);
  padding: 15px;
  position: relative;
  color: white;
  min-height: 80px;
  width: 100%;

  &.editable {
    cursor: pointer;
  }

  &--medical {
    background-color: var(--medical);
  }

  &__title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin-bottom: 15px;

    &__name {
      font-weight: 700;
      font-size: 14px;
    }

    &__edit-btn {
      justify-self: end;
      z-index: 2;
      opacity: 0;
    }
  }

  &__info {
    font-size: 12px;
  }

  &__info-edit {
    .base-label {
      color: #fff;
    }

    button.base-button--cancel, a.base-button--cancel {
      color: #fff;

      &:hover {
        color: var(--dark);
      }
    }
  }


  &__icon {
    width: 100px;
    height: 100px;
    opacity: 0.1;

    &__wrapper {
      width: 25%;
      height: inherit;
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      pointer-events: none;
    }
  }

  a {
    color: white;
  }

  &:hover {
    .contact-card__title__edit-btn {
      opacity: 1;
    }
  }
}
