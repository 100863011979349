/*******************************
  SMART Search
 *******************************/

smart-search {
  flex: 0 1 auto;
  margin-right: auto;
}

.smart-search {
  margin-right: 30px;
  position: relative;

  .input-section {

    display: flex;

    input {
      display: block;
      width: 100%;
      padding: 5px;

      &#top-search {
        max-width: 460px;
      }
    }

    .exact-match {
      display: flex;
      margin-left: 20px;
      align-items: center;

      span {
        font-weight: 600;
        padding-bottom: 5px;
      }

      rp-switch {
        margin-left: 6px;
      }
    }
  }

  .spinner {
    position: absolute;
    top: 7px;
    left: 405px;
  }

  .form-control {
    padding-left: 15px;
    border: none;
    font-size: 14px;
    background: #f3f3f4;
    border-radius: 8px;
  }
}

.search-suggestions {
  position: absolute;
  left: 0;
  background-color: white;
  z-index: 10;
  border: 1px solid #ccc;;
  //box-shadow: 3px 3px 10px 0 rgba(200, 200, 200, .8);
  min-width: 465px;
  overflow-y: auto;
  max-height: 750px;

  span, a {
    display: inline-block;
    color: #676a6c;

    &.suggestion {
      display: block;
      line-height: 1.7;
      padding: 4px 10px;

      > * {
        padding-right: 4px;
      }

      &:hover {
        cursor: pointer;
        background-color: #f3f3f4;
      }

      &.selected {
        background-color: #f3f3f4;
      }
    }

    &.funding {
      display: block;
      //padding-left: 15px;
      font-style: italic;
      font-size: 75%;
      margin-top: -3px;
    }

    .badge {
      color: white;
      margin: 1px 2px;
    }
  }

  span.highlightedText {
    color: #1a7bb9;
  }

  .suggestion-type, .announcement {
    display: block;
    text-align: right;
    padding-bottom: 5px;
    font-size: 75%;
    color: var(--primary);
    margin-bottom: -25px;
    padding-right: 5px;
    padding-top: 3px;
    z-index: 11000;
    position: relative;
    width: 100px;
    float: right;
  }

  .suggestion-type.law-firm {
    margin-bottom: -10px;
  }

  hr {
    margin: 7px 0;
  }

  .sections-suggestions i {
    color: var(--success);
    font-weight: bold;
  }

  .items-suggestions i {
    font-size: 85%;
  }

  .section.applicants, .section.attorneys, .section.law-firms, .section.doctors, .section.medical-facilities, .section.doctor-contacts {
    overflow: hidden;
  }

  .smart-button {
    background-color: white;
    z-index: 10000;
    position: relative;
  }
}
