board-card {
  .colored-badge, .colored-label {
    position: relative;
    overflow: hidden;

    .bg-badge {
      position: absolute;
      background-color: #AFAFAF;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }

    span {
      filter: brightness(.6);
    }
  }
}
