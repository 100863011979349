.select-list {
  position: absolute;
  width: 200px;
  height: auto;
  background-color: #fff;
  min-height: 135px;
  border-radius: 5px;
  top: calc(100% + 6px);
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  z-index: 5;

  &.medium-width {
	width: 300px;
  }

	&.without-triangle {
        .select-list__triangle {
            border: 0;
        }
    }

  &.top {
	top: unset;
	bottom: calc(100% + 6px);

	.select-list__triangle {
	  top: unset;
	  bottom: -9px;
	  border-bottom: unset;
	  border-top: 9px solid white;
	}
  }

  &.left {
	left: 0;
  }

  &.right {
	right: 0;
  }

  &.center {
	left: unset;
	right: unset;
	justify-content: center;
  }

  &__wrapper {
	width: 100%;
	height: 100%;
	max-height: 250px;
	overflow-y: auto;
  }

  &__triangle {
	top: -9px;
	right: 0;
	left: 0;
	margin: 0 auto;
	position: absolute;
	width: 0;
	height: 0;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-bottom: 9px solid white;
  }

  &__item {
	display: flex;
	height: 45px;
	position: relative;
	font-weight: 600;
	color: var(--text);
	font-size: 11px;
	overflow: hidden;
	align-items: center;
	cursor: pointer;
	padding: 0 10px;
	transition: background-color 0.4s;

	svg:not(.svg-inline--fa) {
	  width: 20px;
	  height: 20px;
	  margin-right: 6px;
	}

  &.selected {
    background-color: #f1f1f6;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #f1f1f6;
    }
  }
  }

  &__no-options {
	display: flex;
	height: 135px;
	align-items: center;
	justify-content: center;
	color: var(--text);
	font-size: 10px;
	font-weight: 600;
  }
}

// select-item
.select-item {
  position: relative;
  max-width: 300px;

  &__title {
	display: block;
	font-size: 11px;
	color: var(--text);
	font-weight: 700;
  }

  &__content {
	font-weight: 700;
	font-size: 11px;
	padding: 0 5px;
	min-height: 30px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	margin-left: -5px;
	color: var(--primary);
	transition: all 0.4s;

	svg {
	  width: 20px;
	  height: 20px;
	  margin-right: 6px;
	}

	&.placeholder {
	  color: #c1c4dc;
	  font-style: italic;
	}

	&:hover {
	  cursor: pointer;
	  background-color: #f1f1f6;
	}
  }
}
