.association-todo-list {
  height: 100%;

  .association-todo-list {
    height: 100%;
  }

  .todo-widget__content {
    height: 100%;
  }

  .todo-widget__content__pool {
    height: calc(100% - 135px);
  }

  .loading-panel__message {
    height: calc(100vh - 100px);
  }

  .todo-widget__header__summary {
      border-radius: 5px;
  }

  .user-filter {
    height: unset;
    padding: 0 15px 10px;
  }

}
