.sidebar-section {
  margin: 0 0 10px 20px;
  border-radius: 5px;
  position: relative;
  transition: all 0.4s;

  .sidebar-section__content {
    color: var(--primary);

    &.text {
      color: var(--text);
      font-size: 12px;
      font-weight: 400;
    }
  }

  &.editable {
    padding: 8px;

		&:hover {
			background-color: #f1f1f6;
			cursor: pointer;
		}
  }

  &__title {
		font-weight: 700;
		color: var(--text);
		margin-bottom: 5px;
  }

	&__datepicker-wrapper {
		position: absolute;
		top: 100%;
		left: 0;
		width: 202px;
		height: 234px;
		background-color: #fff;
  }

  &__content {
		font-weight: 700;
		font-size: 12px;
		color: #c1c4dc;

		&.assignees {
			display: flex;
			.user__avatar {
				margin: 0 2px;
				cursor: pointer;
			}
		}

		&.multiple {
			display: flex;
			flex-wrap: wrap;
		}

		&.priority {
			display: flex;
			align-items: center;
			font-weight: 700;
			color: var(--text);
			font-size: 10px;
			svg, svg-icon {
				margin-right: 5px;
				width: 16px;
				height: 16px;
			}
		}
  }

  .sidebar-input__value {
    font-weight: bold;
    font-size: 12px;
    color: var(--primary);
  }

  &__add-btn {
		margin-left: auto;
		width: 18px;
		height: 18px;
		padding-right: 5px;

		.fa, fa-icon {
			cursor: pointer;
			transition: all 0.4s;
			transform-origin: center;
		}

		&.expanded {
			.fa, fa-icon {
				transform: rotate(-135deg);
			}
		}

			&:hover {
			.fa, fa-icon {
				color: var(--primary);
			}
		}
  }
}

ga-note-field {
	.sidebar-section {
		margin: 0;
	}
}
