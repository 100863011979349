.law-firm {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  .law-firm-header {
    grid-column: span 2;
  }
  .law-firm-content {
    height: calc(100vh - 60px);
    overflow: auto
  }
  .contact-boxes {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    grid-gap: 10px;
    > div {
      display: flex;
    }
    .contact-box {
      flex: 1;
      @extend .flex-fix;
    }
  }
  .timeline-wrapper .activity-stream {
    flex: 1;
    @extend .flex-fix;
    overflow-y: auto;
    padding-right: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(110, 110, 110, 0.3);
  }
  .tab-pane {
    padding: 0 !important;
  }
  .tabHeader {
    text-align: center;
    border-top: none;
    background-color: #16a085;
    .nav-link.disabled {
      color: white;
    }
    .nav-link.disabled:hover {
      background-color: #16a085;
      border-top: none;
      color:white;
      cursor: unset;
    }
  }
  .nav-link .attorney-name {
    margin-left: 15px;
    font-size: 11px;
    display: inline-block;
  }
}


////// law firm old


law-firm-detail-page {
  display: flex;
}
.law-firm {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2px;
  flex: 1;
  @extend .flex-fix;
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    @extend .flex-fix;
  }
  .tabs {
    padding: 10px 0 0 0;
    flex: 1;
    display: flex;
    @extend .flex-fix;
  }
  .tab-container:not(.rp-sidebar) {
    display: grid;
    grid-template-columns: minmax(150px, 10%) 1fr;
    grid-gap: 2px;
    width: 100%;
    .nav-tabs {
      flex-direction: column;
      overflow: auto;
      a.nav-link {
        border-radius: 0;
        width: 100%;
        border-top-color: #ccc;;
        margin: 0;
      }
      a.active, a:hover {
        border-top-color: var(--primary);
      }
    }
  }
  .tab-content {
    overflow: auto !important;
  }
}

.law-firm-header, .attorney-header {
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
    flex: 1;
    font-weight: 500;
    @extend .flex-fix;
  }
}
.tab-content {
  background: none;
  background: white;
}

.tab-content tab.stats.active {
  display: grid !important;
  grid-gap: 10px !important;
  grid-template-columns: repeat( auto-fit, minmax(1fr, 33%) ) !important;
  align-content: start;
}

.tab-content tab.active.attorney-list .ibox,
.tab-content tab.active.contact-list .ibox {
  display: grid !important;
  grid-gap: 10px;
  padding: 0 10px;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
  align-content: start;
  .contact-box {
    margin-bottom: 0;
  }
}

.tab-content tab.active.attorney-detail {
  display: grid !important;
  grid-gap: 10px;
  grid-template-columns: 1fr 2fr;
  align-content: start;
}

.tab-content {
  img {
    max-width: 100%;
  }
}

/*
mobile view changes on law firm page commonly
used for doctor, staff
*/
.search-btn-mobile{
  display: flex;
}

.add-btn-right {
  position: absolute;
  right: 0;
}

.add-doctor-btn-right {
  position: absolute;
  right: 0;
}

law-firm-detail {
  max-width: 100%;
}

.disable-doctorname-mobile {
  pointer-events: none;
}

.margin-btm-20 {
  margin-bottom: 20px;
}

.ovrflw-doctor-card {
  overflow: initial !important;
}

.doctor-funding-count {
  margin-left: 10px;
  font-size: 12px;
}

.doctor-margin-top-5 {
  margin-top: 5px;
}

.doctor-locn-note-flex {
  flex: 1;
}

