.confirmation-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;

  &__header {
    width: 100%;
    padding: 20px 10px;
    text-align: center;
    border-bottom: 1px solid #eee;
    h1 {
      font-size: 22px;
      color: var(--primary);
      font-weight: 600;
    }
  }

  &__content {
    padding: 20px 10px;
    font-size: 16px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin: 12px 0;
      span {
        font-weight: 600;
        color: var(--primary);
      }
      &:before {
        content: '\f111';
        font-family: 'FontAwesome';
        color: var(--primary);
        margin-right: 10px;
        font-size: 10px;
      }
    }
  }

  &__footer {
    height: 72px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #eee;

    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
