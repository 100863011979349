.todo-whiteboard {
  width: 100%;
  background-color: #fff;
  height: calc(100vh - 60px);

  &__header {
    height: 70px;
    padding: 20px 20px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    &__filter-wrapper {
      display: flex;
      align-items: center;
    }

    &__search-filter {
      position: relative;
      margin-right: 20px;
      input {
        background-color: #f4f5f7;
        border: 1px solid #dfe1e6;
        display: flex;
        width: 100%;
        min-width: 280px;
        padding: 0 30px 0 10px;
        height: 40px;
        color: #73768c;
        border-radius: 5px;
        font-size: 14px;
        outline: none;
        transition: all 0.4s;

        &::-webkit-input-placeholder {
          font-style: italic;
          color: lighten( #73768c, 10%);
        }

        &:active, &:focus {
          box-shadow: 0 0 0 1px var(--primary) inset;
          border: 1px solid var(--primary);
          background-color: #fff;
        }
      }
      i, fa-icon {
        position: absolute;
        top: 11px;
        right: 11px;
        font-size: 16px;
        color: #73768c;
        cursor: pointer;
      }
    }

    button {
      margin-left: auto;
      height: 40px;
      min-width: 120px;
      border-radius: 5px;
      border: 0 solid transparent;
      background-color: var(--primary);
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      transition: background-color 0.4s;

      i {
        margin-right: 5px;
      }

      &:hover {
        background-color: var(--primary-hover);
      }
    }
  }

  &__board {
    display: flex;
    height: calc(100% - 70px);
    padding: 0 10px 20px;

    &__column {
      width: 400px;
      padding: 0 10px;
      position: relative;
      height: 100%;

      &__name {
        display: flex;
        align-items: center;
        height: 40px;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
        color: #73768c;
        border-bottom: 2px dashed #efefef;
      }

      &__summary {
        display: flex;
        align-items: center;
        height: 30px;
        font-size: 10px;
        color: #73768c;

        .title {
          text-transform: uppercase;
        }
        .info {
          display: flex;
          align-items: center;
          margin-left: auto;
          span {
            font-size: 16px;
            font-weight: 600;
            color: darken(#73768c, 15%);
            margin-right: 8px;
          }
        }
      }
      &__shadow-top {
        position: absolute;
        z-index: 2;
        width: calc(100% - 30px);
        height: 1px;
        top: 81px;
        left: 15px;
        box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1);
      }
      &__shadow-bottom {
        position: absolute;
        z-index: 2;
        width: calc(100% - 30px);
        height: 1px;
        bottom: 0;
        left: 15px;
        box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1);
      }
      &__pool {
        border-radius: 5px;
        background-color: #f1f1f6;
        display: grid;
        grid-gap: 15px;
        grid-auto-rows: max-content;
        padding: 15px;
        width: 100%;
        height: calc(100% - 80px);
        overflow-y: scroll;

        &-empty-line {
          height: 1px;
          width: 100%;
          background-color: transparent;
        }
      }
    }
  }
}
