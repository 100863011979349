//attorney detail
attorney-detail, #attorney-detail {
  width: 100%;
  display: flex;
  history-log {
    flex: 0 1 auto !important;
    @extend .flex-fix;
  }
}

#attorney-detail {
  display: flex;
}

.attorney-content {
  flex: 1;
  @extend .flex-fix;
  overflow-y: scroll;
}

#attorney-detail .panel {
  attorney-statistics table.table * {
    padding: 4px !important;
  }
  attorney-statistics table tr th:not(:first-child) {
    text-align: right;
  }
}
