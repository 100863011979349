.timestamp {
  font-size: 11px;
  color: #9599bb;
}

.text-right {
  align-self: flex-end;
  text-align: right;
}

.text-primary {
  color: var(--primary);
}

.text-danger {
  color: var(--danger);
}

.content-spacer {
  margin-right: 0.25em;
}

//Styleguide - new and unified styles

$padder: 8px;

.desc {
  color: #696969;
  background: var(--app-medium);
  padding: 8px 10px;
  border-radius: 6px;
}

.rp-alert {
  border: 1px solid #32C3A6;
  border-top: 3px solid #32C3A6;
  background: #f3f3f4;
  padding: 15px 10px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.ml {
  &-1 {
    margin-left: $padder;
  }
}

.mr {
  &-1 {
    margin-right: $padder;
  }
}


//todo below you can see css form main.css in needst to be sorted properly

body {
  padding: 0;
}

.content {
  flex: 1;
  @extend .flex-fix;
}

.padded {
  padding: 0 20px;
}

.st-sort-ascent:before {
  content: '\25B2';
}

.st-sort-descent:before {
  content: '\25BC';
}

th[st-sort] {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}

fieldset {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 6px 12px;
  margin-bottom: 10px;
}

.form h3 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: lighter;
}

.form h2 {
  font-size: 26px;
  font-weight: 400;
}

.required label:after {
  content: "(required)";
  margin-left: auto;
  font-size: 10px;
  opacity: 0.8;
  font-weight: 500;
  color: var(--grey-dark);
  margin-right: 5px;
}

label {
  font-weight: normal;
}

.slider-selection {
  background: var(--primary);
}

.slider-handle {
  background: var(--primary);
}

.slider {
  margin-top: 12px;
}

.file-box {
  display: inline-block;
  float: none;
}

.drop-area-wrapper {
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
}

.file-box-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 200px));
}

.file-name {
  overflow: hidden;
}

.table-nested {
  width: 100%;
}

.strike {
  text-decoration: line-through;
}

.show-edit-mode .is-edit {
  border: 1px solid var(--primary);
  border-radius: 2px;
  padding: 5px 10px;
  margin: 0 -10px;
}

.floating-panel {
  min-width: 400px;
  padding: 10px;
  background: var(--info-bg);
  border: 2px solid var(--info-bg);
  position: fixed;
  margin: 0 auto;
  bottom: 20px;
  right: 20px;
  color: var(--info-text);
}

.row-highlight {
  background-color: var(--info) !important;
  color: white;
}

th .input-group-addon, th .input-group-btn {
  width: auto;
}

.multiSelect .checkboxLayer {
  right: 0;
}

.pos-relative {
  position: relative;
}

.ibox-footer {
  background: transparent;
  border-top: 0;
  padding: 0;
}

.spinner-overlay {
  flex: 0 1 100%;
  margin: auto;
  padding: 50px;
}

.has-tooltip {
  border-bottom: dotted 1px;
  cursor: help;
}

rp-field-editor input, rp-field-editor select {
  min-width: 150px !important;
}

fundings-table td {
  white-space: nowrap;
}

rp-field-editor, rp-field, rp-field-value, rp-section, rp-field-custom-content, rp-field-custom-edit {
  display: block;
}

rp-field-custom-edit {
  padding-bottom: 10px;
}

//.view-mode .rp-field .row {
//  border-top: 1px solid #e7eaec;
//  line-height: 1.42857;
//  padding: 4px 0;
//  vertical-align: top;
//  margin: 0 !important;
//}
//
//.view-mode .rp-field:nth-child(odd) .row {
//  background: #f9f9f9;
//}

ul[dnd-list],
ul[dnd-list] > li {
  position: relative;

}

ul[dnd-list] > li {
  padding: 10px;
  margin: 2px;
  border: 1px solid;
  border-radius: 2px;
}

ul[dnd-list] .dndPlaceholder {
}

.alert.alert-dismissible {
  margin: 15px 10px 0 auto;
  width: 350px;
}

/*******************************
  TODO list
 *******************************/

.todo-list-wrapper {
  padding: 15px 0;
  max-width: 960px;

  h3 {
    font-size: 18px;
  }
}

.todo-filter {
  .ui-select-container {
    display: inline-block;
    width: 27%;
    margin-right: 0.8%;
  }

  button.btn {
    display: inline-block;
    width: 7%;
  }
}

.todo-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding: 5px 15px 15px 15px;
  position: relative;

  .row {
    position: relative;
  }

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .check-mark {
    line-height: 36px;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 15px;
  }

  h2 {
    display: inline-block;
    margin: 0 0 0 25px;
    line-height: 36px;
  }

  .vip-attorney, .vip-applicant {
    padding-right: 5px;
  }

  .vip-applicant {
    color: var(--danger);
  }

  .vip-attorney {
    color: var(--primary);
  }

  .metadata {
    padding-top: 5px;

    img {
      max-width: 48px;
    }

    span, strong {
      vertical-align: top;
    }

    span {
      margin-right: 10px;
    }
  }

  .related-users {
    padding-left: 22px;

    li {
      display: inline-block;
      margin-right: 10px;
      margin-top: 10px;
      font-style: italic;
      font-weight: 100;

      &:after {
        display: inline-block;
        content: "/";
        color: #ddd;
        margin-left: 10px;
      }
    }

    li:last-child:after {
      display: none;
    }
  }

  &.overdue, &.highlight {
    background-color: rgba(255, 0, 0, 0.055);
    border-bottom: none;
    padding-bottom: 5px;
    padding-top: 10px;

    ul {
      margin-bottom: 5px;
    }
  }

  &:hover {
    opacity: .6;

    .edit-todo-item {
      display: block;

      .fa-pencil-square {
        font-size: 50px;
        position: absolute;
        right: 20px;
        bottom: 5px;
      }
    }
  }

  &.highlight {
    background-color: rgba(000, 255, 0, 0.1);

    .fa-pencil-square {
      top: 50%;
      margin-top: -25px;
      left: 50%;
      margin-left: -25px;
    }
  }

  &.overdue .date {
    color: red;
  }

  .edit-todo-item {
    display: none;
  }

}

.todo-list-actions-wrapper {
  margin-top: 30px;
}

.done-todo-wrapper {
  .finished-late .date {
    color: var(--danger);
  }

  .finished-ontime .date {
    color: var(--primary);
  }

  .todo-item h2 {
    margin-left: 0;
  }

  .related-users {
    padding-left: 0;
  }
}

@media screen and (min-width: var(--breakpoint-xl)) {
  .row-4 {
    min-height: 250px;
    max-height: calc(33vh - 200px);
    overflow: auto;
  }

  .row-8 {
    min-height: 250px;
    height: calc(66vh - 200px);
    overflow: auto;
  }

  .row-12 {
    min-height: 250px;
    height: calc(100vh - 350px);
    overflow: auto;
  }
}

//scrollbar global settings

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 10px;
  height: 1.5rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: transparent;

  &:horizontal {
    width: 0.5rem;
    background-color: transparent;
  }
}

//!end of scrollbars

//Portfolio Progression screen

.pp-preset, .pp-resolution {
  margin-bottom: 0;
  padding-bottom: 20px;
  display: inline-block;

  label {
    width: 100%;
    font-weight: bold;
  }

  .btn-group.btn-group-justified {
    display: flex;
    width: auto;
  }

  .btn-group {
    width: auto;

    .btn {
      margin-bottom: 0;
      padding: 8px 15px;
    }

    .btn.active {
      background: var(--primary);
      color: white;
    }
  }
}

.pp-resolution {
  float: right;

  .btn-group.btn-group-justified {
    z-index: 1;
  }
}

.portfolio-chart {
  max-height: calc(100vh - 275px);
}


.contacts {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.contact {
  display: flex;
  flex: 1;
  @extend .flex-fix;
  flex-wrap: wrap;
  padding: 6px 10px;
  border: 1px solid #ccc;
  //margin-right: 4px;
  margin-bottom: 2px;
  width: 100%;

  strong {
    display: block;
    width: 100%;
  }
}

.file-box-dropzone.dragover {
  border: 5px dashed #CCCCCC;
  padding: 10px 15px 15px 15px;
}

.small-padding {
  padding-left: 3px !important;
  padding-right: 4px !important;
}

.nv-file-over {
  background: #dff0d8;
}

a.clipboard {
  padding: 3px;
  color: var(--success);

  i {
    font-size: 13px;
  }
}

.hide-me {
  display: none !important;
}

rp-value {
  display: inline-flex;
}


.scroll {
  overflow: auto;
  //flex-direction: column;
  flex: 1;
  @extend .flex-fix;
}

.scroll-vertical {
  overflow-y: auto;
  overflow-x: visible;
}

.flex-thin {
  display: flex;
  flex: 0 0 auto;
  @extend .flex-fix;
}


.o-hidden {
  overflow: hidden;
}

.ag-row.status--todo {
  background-color: #fff7d2 !important;
}

.ag-row.status--in-progress {
  background-color: #d2eeff !important;
}

.ag-row.status--done {
  background-color: #d2ffd3 !important;
}

.ag-row.danger {
  background-color: #fff0f0 !important;
}

.ag-row.warning {
  background-color: #fff9c4 !important;
}

.ag-row.info {
  background-color: #b4e6fd !important;
}

.ag-row.success {
  background-color: #c0fdb4 !important;
}

.ag-row.ag-row-selected {
  --ag-selected-row-background-color: #b9b4fd;
}

.ag-theme-balham .ag-ltr .ag-cell-focus {
  border: none !important;
  overflow: visible;
}

.ag-group-value a, .ag-cell-value a {
  color: #0ead8d !important;
}

.ag-body-horizontal-scroll {
  min-height: fit-content;
}

.ag-cell {
  &.center-cell {
    display: flex !important;
    align-items: center;
    justify-content: center;

    child-cell {
      display: flex !important;
    }
  }

  // not-funny
  ga-cell {
    height: 100%;
    display: inline-block;

    ga-toggle-switch-renderer {
      height: 100%;
      display: flex;
      align-items: center;
    }

    ga-action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  ga-editor-cell {
    display: block;
    height: 100%;

    & > div {
      height: 100%;
    }

    form {
      height: 100%;
      display: initial;

      ga-editor {
        display: block;
        height: 100%;

        ga-switch-editor {
          display: block;
          height: 100%;

          rp-switch {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 0 10px;
          }
        }
      }
    }
  }

  .btn.btn--grid {
    height: 22px;
    padding: 0 10px;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

ga-page-title {
  grid-column: none;
}


// wrapper div makes default input looks same as ga-input
.ga-input__renderer {
  background-color: white;
  height: 43px;
  position: relative;
  padding-top: 20px;

  span, label {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 700;
    font-size: 11px;
    padding: 0 10px;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #ccc;
    width: 100%;
    font-size: 14px;
    padding: 0 8px 2px 8px;

    &:hover, &:focus, &:active {
      outline: none;
    }

    &:focus {
      border-color: var(--primary);
    }
  }
}

ng-include {
  display: block;
  width: 100%;
}

.webapp-notification {
  position: relative;

  &__count {
    height: 16px;
    width: 16px;
    font-size: 9px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    display: inline-flex !important;

    &.plaintiff {
      position: absolute;
      bottom: 6px;
      left: 6px;
      background-color: var(--primary);
    }

    &.medical {
      position: absolute;
      bottom: 6px;
      right: 10px;
      background-color: var(--medical);
    }
  }
}

.funding-card-tabs {
  .tab-content, .tab-pane {
    overflow: initial !important;
  }
}

.applicant-sidebar {
  .tab-container {
    height: 100%;
  }
}


.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}


/* TABLES */
.table-bordered {
  border: 1px solid #EBEBEB;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  background-color: #F5F5F6;
  border-bottom-width: 1px;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #e7e7e7;
}

.table > thead > tr > th {
  border-bottom: 1px solid #DDDDDD;
  vertical-align: bottom;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-top: 1px solid #ccc;;
  line-height: 1.42857;
  vertical-align: middle;
}

/* GLOBAL  */
.no-padding {
  padding: 0 !important;
}

.no-borders {
  border: none !important;
}

.no-margins {
  margin: 0 !important;
}

// !! need to be rewritten END

.btn-info-icon {
  color: var(--primary);
  background: none;

  &:hover {
    color: var(--primary-hover);
  }
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}

.btn-white {
  background-color: #fff;
  border-color: var(--grey);
  padding: 1px 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.btn-link {
  color: var(--primary);
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: transparent;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
  background-color: transparent;
  box-shadow: none;
}

// todo needs tidy up - move somewhere else
.wizard-form-disabled {
  display: flex;
  border: 1px dashed var(--danger);
  font-weight: 700;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.white-space-normal {
  white-space: normal !important;
}

.ga-grid-editable-cell {
  white-space: normal !important;

  &.ag-cell-inline-editing {
    height: 100% !important;
    min-height: 150px;
  }

  ga-textarea-editor {
    height: 100%;
    display: block;
  }

  textarea {
    width: 100%;
    height: inherit;
    text-align: left;
  }
}

.ng-select.ng-select-multiple.ng-select-clear .ng-select-container {
  overflow-x: initial;

  .ng-value-container {
    padding: 0 !important;
    margin: 0 !important;

    .ng-placeholder {
      top: 9px;
      padding-bottom: 5px;
      padding-left: 8px;
    }

    .ng-input {
      padding: 0 1px;

      input {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
}

.pac-container {
  z-index: 100000;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 0;

  .ng-placeholder {
    padding: 0;
    top: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.md-editor-container .md-layout .tool-bar .btn-group:before {
  content: none !important;
}

@media screen and (max-width: 657px) {
  ::-webkit-scrollbar {
    width: 5px;

    &:horizontal {
      height: 0.5rem;
    }
  }
}

.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;

  // These are used directly on <label>s
  &.disabled,
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

edit-icon fa-icon.icon svg {
  width: 1em;
  height: 1em;
}
