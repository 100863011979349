.folder-list .popover {
  right: 0 !important;
  left: 170px !important;
}

.funding-list-popover {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  z-index: 5;
  border-color: transparent;

  .popover-content {
    padding: 0;
  }
}
