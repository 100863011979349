board-card-detail {
  height: 100%;

  .inline-field {
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 8px;
    transition: background-color 450ms;

    &.editable:hover {
      background-color: var(--dark-grey);
      cursor: pointer;
    }
  }

  move-to-payment-column {

    fa-icon {
      margin-left: 8px;
    }

    .select-list.top.right {
      right: 1.5rem;
      bottom: 100%;
    }
  }

  previous-steps {
    fa-icon {
      margin-left: 8px;
    }

    .select-list.top.left {
      left: -60px;
      bottom: 62%;
    }
  }
}
