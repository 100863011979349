.document__uploader {
  background-color: #fff;

  h2 {
    padding: 15px;
    font-size: 20px;
    color: #686a7e;
    margin: 0;

    span {
      color: var(--primary);
    }
  }

  &__tabs {
    display: flex;
    height: 40px;
    border-bottom: 1px solid #eee;
    position: relative;

    &__item {
      height: 40px;
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
      cursor: pointer;
      color: #686a7e;

      &.active {
        color: var(--primary);
      }
    }

    &__line {
      position: absolute;
      bottom: -1.5px;
      left: 0;
      height: 3px;
      width: 140px;
      background-color: var(--primary);
      border-radius: 3px;
      transition: transform 0.4s;

      &.files {
        transform: translateX(100%);
      }
    }
  }

  &__tab {
    border-bottom: 2px dashed #efefef;

    &__content {
      padding: 15px;
      min-height: 108px;

      &__upload-area {
        width: 100%;
        height: 78px;
        border-radius: 5px;
        border: 1px dashed #0e9aef;
        background-color: #d2eeff;
        position: relative;
        cursor: pointer;
        transition: all 0.4s;

        &:hover,
        &__hover {
          background-color: #eff9ff;
        }

        &__text {
          position: absolute;
          color: #0e9aef;
          font-size: 10px;
          font-weight: 700;
          top: 50%;
          left: 50%;
          transform: translate(-48%, -50%);
        }
      }
    }
  }

  .document-template-row {
    display: flex;
    align-items: center;
    color: #686a7e;
    font-weight: 600;
    margin-bottom: 5px;

    &__checkbox-text {
      color: #686a7e;
      font-weight: 600;
      font-size: 12px;
    }
  }

  &__btn-row {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 15px;

    button {
      height: 40px;
      background-color: var(--primary);
      color: #fff;
      margin-left: auto;
      font-weight: 600;
      font-size: 12px;
      border-radius: 5px;
      border: none;
      width: 140px;
    }
  }
}
