.toggle-switch {
  height: 18px;
  width: 40px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 0;
  transition: box-shadow 0.1s ease-out, background-color 0.2s;

  &__control {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateX(-11px);
    transition: transform 0.2s;

    &__checked {
      position: absolute;
      top: 0;
      right: 50%;
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      color: white;
      font-size: 9px;
      font-weight: 600;
      padding-left: 6px;
      border-radius: 20px 0 0 20px;
    }
    &__slider {
      position: absolute;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      margin: 0 auto;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
    }
    &__unchecked {
      position: absolute;
      top: 0;
      left: 50%;
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      color: white;
      font-size: 9px;
      font-weight: 600;
      justify-content: flex-end;
      padding-right: 6px;
      border-radius: 0 20px 20px 0;
    }

    &--on {
      transform: translateX(11px);
    }
  }
  &__input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;

    &:checked + .toggle-switch__control {
      transform: translateX(11px);
    }
  }
}
