.servicing {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-gap: 10px;
  grid-template-rows: minmax(250px, 300px) 1fr;
  width: 100%;
}

.color-box-renderer {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 8px 4px;
  background-color: white;

  &.olderThan120, &.olderThan150 {
    background-color: #dc6c6c;
    border-color: darken(#dc6c6c, 10%);
  }

  &.olderThan90 {
    background-color: #fbf2a2;
    border-color: darken(#fbf2a2, 10%);
  }
}
