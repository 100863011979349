.base-note {
  width: 100%;
  max-width: 325px;
  padding: 20px 20px 1px 0;
  border-radius: 10px;
  background-color: white;
  position: relative;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.05), 0 1px 9px 0 rgba(93, 100, 148, 0.05);

  &:hover {
    .base-note__actions {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__actions {
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    transform: translateY(5px);
    transition: all 0.3s;

    &__edit {
      top: 1px;
      right: 20px;
    }

    &__delete {
      top: 0;
      right: 3px;
    }

    fa-icon {
      cursor: pointer;
      color: var(--text);
      opacity: 0.8;
    }
  }

  &__remove-dialog {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    color: var(--text);
    justify-content: center;
    align-items: center;

    &__title {
      margin-bottom: 8px;
      color: var(--text);
      font-weight: 700;
    }

    &__link-yes {
      color: #30c3a6
    }

    .link {
      display: inline-block;
      font-weight: 600;
      margin: 0 8px;
      cursor: pointer;
    }
  }

  &__body {
    display: flex;

    &__activity-type {
      &__symbol {
        display: flex;
        justify-content: space-between;
        width: 53px;
        padding-right: 15px;

        &__mark {
          top: 20px;
          left: 0;
          width: 3px;
          height: 18px;
          border-radius: 2px;

        }

        &__icon {
          top: 20px;
          left: 18px;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__text {
      &__summary {
        font-weight: 700;
        color: var(--text);
        font-size: 12px;
        margin-bottom: 8px;
        padding-right: 30px;
      }

      &__content {
        font-size: 11px;
        color: var(--text);
        text-align: left;
        margin-bottom: 0;
        opacity: 0.9;
      }
    }
  }

  &__footer {
    &__info {
      display: flex;
      justify-content: space-between;
      padding-left: 50px;
      margin-bottom: 10px;


      &__todos {
        display: flex;
        align-items: center;

        &__text {
          font-size: 10px;
          color: var(--text);
          cursor: pointer;
          padding: 4px;
          border-radius: 3px;
          transition: all 0.3s;

          &:hover {
            background-color: #f1f1f6;
          }

          span {
            font-weight: 700;
            margin-left: 3px;
          }
        }

        &__plus-icon {
          color: var(--primary);
          cursor: pointer;
          margin-left: 5px;

          &:hover {
            color: var(--primary-hover);
          }
        }
      }

      &__date {
        font-size: 9px;
        color: var(--text);
        opacity: 0.6;
      }
    }
  }

  &__loading {
    z-index: 3;
    border-radius: 10px;
  }
}

.mobile {
  .base-note{
    max-width: 100%;
    &__actions fa-icon {
      padding-left: 8px;
      font-size: 16px;
    }
  }
}
