.new-modal {

  &__content {
    &__instructions {
      width: 100%;
      display: block;
      margin: 0;

      &__item {
        padding: 10px 10px 10px 50px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #f5f5fb;
        position: relative;

        &__icon {
          position: absolute;
          top: 12px;
          left: 10px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #fff;

          &__wrapper {
            position: relative;
            width: 100%;
            height: 100%;
          }

          .svg-instruction svg {
            top: 4px;
            left: 5px;
            width: 20px;
            height: 20px;
          }

          .svg-law-firm svg {
            top: 4px;
            left: 6px;
            width: 22px;
            height: 22px;
          }

          .svg-doctor svg {
            top: 5px;
            left: 5px;
            width: 20px;
            height: 20px;
          }

          .svg-medical-facility svg {
            top: 3px;
            left: 2px;
            width: 24px;
            height: 24px;
          }

          svg {
            position: absolute;
            fill: #c3c3da;
          }
        }

        &__title {
          font-weight: 700;
          color: #686a7e;
        }

        &__note {
          font-size: 12px;
          color: #686a7e;
        }
      }
    }

    &__pricing, &__custom-fields {
      border-radius: 5px;
      padding: 15px 10px 10px;
      border: 1px dashed #eee;
      position: relative;
      font-size: 12px;
      width: 100%;
      justify-content: space-between;
      display: flex;

      &__table {
        margin-top: 10px;
      }

      &__left, &__right {
        width: 49%;
      }

      &__title {
        position: absolute;
        background-color: #fff;
        padding: 0 5px;
        font-weight: 600;
        color: #10cca7;
        top: -10px;
        left: 10px;
        font-size: 12px;
      }

      &__select {
        margin: 6px 0;

        select {
          margin: 0;
        }
      }

      &__summary {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px dashed #e3e3e3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        color: #686a7e;

        &__value {
          color: var(--primary);
        }
      }
    }

    &__client-row, &__doctor-row, &__custom-fields-row, &__documents-row {
      display: flex;
      align-items: stretch;
      margin-top: 20px;
      justify-content: space-between;
    }

    &__pricing-row {
      display: flex;
      margin: 15px 0 0;
    }

    &__client, &__attorney, &__doctor {
      border-radius: 5px;
      padding: 10px 10px 10px;
      border: 1px dashed #eee;
      position: relative;
      font-size: 12px;
      height: 100%;

      &__title {
        position: absolute;
        background-color: #fff;
        padding: 0 5px;
        font-weight: 600;
        color: #10cca7;
        top: -10px;
        left: 10px;
        font-size: 12px;
      }

      &__row-value {
        display: flex;
        cursor: pointer;
        height: 32px;
        align-items: center;

        span {
          margin: 0 8px;
          color: #686a7e;

          i {
            margin-right: 3px;
            color: #686a7e;
          }
        }

        &:hover {
          background-color: #f1f1f6;
          border-radius: 5px;
        }

        &.error {
          .error-msg {
            opacity: 1;
            color: var(--danger);
            font-weight: 700;
          }
        }

        .required {
          margin-left: auto;
          font-size: 10px;
          opacity: 0.8;
          font-weight: 500;
          color: var(--grey-dark);
          margin-right: 5px;
        }

        .name-wrapper {
          margin-right: auto;
        }
      }

      &__block-value {
        cursor: pointer;
        display: block;
        color: lighten(#686a7e, 5%);

        span {
          font-weight: 600;
          color: #686a7e;
        }

        &:hover {
          background-color: #f1f1f6;
          border-radius: 5px;
        }
      }

      &__add-new {
        display: flex;
        color: var(--primary);
        cursor: pointer;
        font-size: 11px;
        margin-top: 5px;
      }

      &__add-new-form {
        border-top: 2px dashed #efefef;
        border-bottom: 2px dashed #efefef;
        margin-top: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      h4 {
        color: #686a7e;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}
