/* Tabs */

.nav.nav-tabs {
  display: flex;
  border-bottom: 1px solid var(--grey);

  li {
	display: inline-flex;
	margin-bottom: 0;
	a {
    padding: 10px 5px;
    transition: all 150ms ease-out;
    border: none;
    border-bottom: 3px solid transparent;
    color: var(--text);
    font-weight: 600;
    font-size: 11px;
    border-radius: 0;

    &:hover {
      background-color: white;
		  border-bottom: 3px solid var(--primary);
	  }
	}
  }
}

.nav-tabs > .nav-item > a.active,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
	border: none;
	border-bottom: 3px solid var(--primary);
	background-color: white;
	color: var(--primary);
}


.tab-content > .tab-pane,
.pill-content > .pill-pane {
	display: none;
	&.flex:not(.active) {
		display: none !important;
	}
}

.tab-content > .active,
.pill-content > .active {
	display: flex;
	//flex-direction: column; caused timeline to disapear
}

.tab-content {
  background: white;
  overflow: auto;
  display: flex;
  flex: 1;
}

.tabs-container, .tab-content, .tab-pane {
	overflow: hidden;
	flex: 1;
}

.tab__second-level {
	background-color: #eaeaef;
	border-left: 4px solid var(--primary);

	&.active.tab-pane {
		background-color: transparent;
		border-left: none;
	}

	&__name {
		padding-left: 20px;
		display: block;
		font-size: 11px;
	}
}

@media (max-width: 767px) {
	.tabs-container .nav-tabs > li.active > a {
		border-bottom: 1px solid #e7eaec !important;
		margin: 0;
	}
}



.tabs-vertical {
	li {
		display: flex;
		width: 100%;
	}
}

.sidebar .flex-col tab.tab-pane.active {
	padding: 0;
}
