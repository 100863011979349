.dropdown {
   &.open {
       .dropdown-menu {
         display: flex;
         flex-direction: column;
         left: 0;
         top: 100%;
         max-height: 300px;
         margin-bottom: 20px;
       }
   }
  .dropdown-menu {
      border: none;
      border-radius: 3px;
      box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
      display: none;
      font-size: 12px;
      padding: 0;
      position: absolute;
      max-height: 300px;
      overflow-y: auto;
      li {
        width: 100%;
        border-bottom: 1px solid #ccc;
        font-weight: bold;
        cursor: pointer;
      }
      a {
        line-height: 30px;
        color: rgba(0, 0, 0, 0.75);
        padding: 0 10px;
        width: 100%;
      }
  }
    .divider {
        margin: 0;
    }
}

.dropdown-menu {
 padding: 0;
}

#fundings-accordion .dropdown-menu {
  right: 0 !important;
  left: unset !important;
}

.ui-select-choices {
  min-width: 0 !important;
  max-width: 100% !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  * {
    white-space: normal;
  }
}

.ui-select-choices-row {
  width: 100%;
}

.ui-select-match {
  display: flex;
  flex-direction: column;
  padding: 0 3px;
  .btn.btn-default {
    border: none;
    background: white;
    border-bottom: 1px solid #ccc;
    padding-right: 30px;
    max-height: 42px;
  }
  .btn.btn-default.ui-select-match-item {
    border: 1px solid #ccc;
    padding: 1px 3px !important;
  }

  .ui-select-match-item {
    white-space: normal !important;
    text-align: left !important;
  }

  span {
    float: none !important;
  }
}

.ui-select-bootstrap > .ui-select-choices, .ui-select-bootstrap > .ui-select-no-choice {
  padding-top: 0;
  width: auto;
}

.ui-select-bootstrap .ui-select-choices-row>span {
  padding: 3px 10px;
  background: white;
}

.ui-select-bootstrap .ui-select-toggle > .caret {
  padding: 0;
}
