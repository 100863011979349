// custom-bs-datepicker
.bs-datepicker.custom-datepicker-theme:not(.without-arrow) {
  &:after {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid var(--primary);
  }
}

.bs-datepicker.custom-datepicker-theme.top:not(.without-arrow) {
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    top: unset;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #fff;
    border-bottom: unset;
  }
}

.bs-datepicker.custom-datepicker-theme.top.with-timepicker:not(.without-arrow) {
  &:after {
    bottom: -87px;
  }
}

.bs-datepicker.custom-datepicker-theme {
  .bs-datepicker-multiple {
    .bs-datepicker-head {
      border-radius: 5px 0 0 0;
    }
  }

  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-left: -1px;

    .bs-datepicker-head {
      border-radius: 0 5px 0 0;
    }
  }

  .bs-datepicker-buttons {
    .btn {
      background-color: var(--primary);
      border-color: var(--primary);

      &:hover {
        border-color: var(--primary);
      }
    }
  }
}

.datepicker-highlight-date {
  background-color: var(--warning);
  color: white;
}

.custom-datepicker-theme {
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  border-radius: 5px;
  margin-top: 4px;
  padding: 10px;

  &.no-top-margin {
    margin-top: 0px;
  }

  &.no-shadow {
    box-shadow: none;
  }

  .bs-datepicker-container {
    padding: 0;

    .bs-datepicker-buttons {
      .btn-clear-wrapper {
        margin: 0 8px 8px 0;

        button {
          background-color: var(--danger);
          border-color: var(--danger-hover);
        }
      }
    }
  }

  .bs-datepicker-head {
    background-color: var(--primary);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: unset;
    width: 100%;
    border-radius: 5px 5px 0 0;

    button.next span {
      font-size: 28px;
      line-height: 25px;
      width: 30px;
      display: inline-block;
    }

    button.previous span {
      width: 30px;
      font-size: 28px;
      line-height: 25px;
      display: inline-block;
    }

    &:after {
      width: 0;
    }
  }

  .bs-datepicker-body {
    width: 100%;
    height: 180px;
    min-width: unset;
    min-height: unset;
    padding: 4px;
    border-radius: 0 0 5px 5px;

    table th {
      font-size: 11px;
      padding: 3px 0;
      color: var(--primary);
    }

    table td {
      width: 24px;
      height: 24px;
    }

    table td.week {
      color: var(--primary);
    }

    table td span {
      font-size: 12px;
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    table td span.custom-today-class {
      color: var(--primary);
      font-weight: 600;
      border: 1px solid var(--primary);

      &.selected {
        color: #fff;
      }
    }

    table td span.bg-warning {
      color: var(--base-input-backgroundColor);
      background-color: var(--danger-hover);
    }

    table.days span {
      width: 24px;
      height: 24px;
      line-height: 23px;

      &.disabled {
        color: #ceced6;
      }
    }

    .selected {
      background-color: var(--primary);
    }
  }

  &.with-timepicker {
    box-shadow: none;
    margin-top: 0;
    width: inherit;

    .bs-datepicker-body {
      border-radius: 5px 5px 0 0;
      border: none;
      border-bottom: 1px solid #eee;
    }
  }

  &.negative-top-margin {
    margin-top: -30px;
  }

  &__right-margin-date-picker {
    @extend .custom-datepicker-theme;
    position: unset;
    margin-right: 130px;
  }
}

// NGX timepicker
timepicker {
  table tr td {
    padding: 0 0;
    width: 46px;
    box-sizing: border-box;
    margin: 0;
    text-align: center;

    .btn.btn-default {
      background-color: var(--primary);
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      border: none;
      width: 46px;
      height: 36px;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s;
      border-radius: 5px;

      &:hover, &:focus, &:active {
        outline: none;
        background-color: var(--primary);
      }
    }

    a.btn.btn-link {
      color: var(--primary);
    }
  }

  table tr td.form-group {

  }

  input.bs-timepicker-field {
    background-color: #f4f5f7;
    border: 1px solid #dfe1e6;
    box-shadow: none;
    color: #686a7e;
    width: 46px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;

    &:focus, &:active {
      outline: none;
      box-shadow: none;
      background-color: #fff;
      border-color: #dfe1e6;
    }
  }

  a.btn {
    padding: 0 12px;
  }
}
