.applicant-form {
  width: 100%;
}

.applicant-form, .modal .modal-body.rp-form {
  .panel-body {
    background: white;
  }

  ul {
    padding-left: 0;
    padding-top: 3px;
    margin-top: 5px;
    border-top: 1px solid #ccc;;

    li {
      list-style: none;
      padding-left: 0;
    }
  }

  .form {
    padding: 10px;

    fieldset, section {
      //overflow: visible;
      margin-bottom: 10px;
      border: none;
      border-left: 3px solid #32C3A6;
      padding: 0 10px 10px 10px;
      background: #f3f3f4;

      .form-group {
        > div {
          textarea {
            height: 100%;
            min-height: 98px;
          }
        }
      }
    }

    .case-details {
      margin-top: 15px;
    }

    #submit {
      display: none;
    }

    h2 {
      font-weight: 200;
    }

    h3 {
      margin: 10px 0 10px 0;
    }

    label.slim {
      padding: 0;
      height: auto;
      min-height: 0;
      margin: 0;
    }

    .form-group {
      margin-bottom: 0;
      position: relative;

      .form-input {
        background: white;
        padding: 18px 12px 2px 12px;
        position: relative;
        margin: 0;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #ccc;;

        .form-group {
          position: static;
        }

        > label {
          padding: 2px 8px;
          margin-bottom: 0;
          font-size: 11px;
          font-weight: 800;
          position: absolute;
          top: 0;
          left: 0;

        }

        .without-border {
          border-bottom: none;
        }
      }

      > label {
        padding: 2px 8px;
        margin-bottom: 0;
        font-size: 11px;
        font-weight: 800;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }

      input:not([type="checkbox"]), select, textarea {
        background: white;
        padding: 18px 12px 2px 12px;
        position: static;
        margin: 0;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #ccc;;
        min-height: 42px;
        width: 100%;

        &:disabled {
          background-color: rgba(128, 128, 128, 0.1);
        }

        &.slim {
          padding: 0;
          min-height: 0;
          position: relative;
          width: 100%;
        }
      }

      .radio-inline {
        display: inline-flex;
        align-items: center;
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-right: 6px;
        padding-left: 0;

        input[type="radio"] {
          height: 13px;
          min-height: 0;
          margin-right: 4px;
          width: auto;
        }
      }

      .input-group {
        margin-bottom: 0;
        width: 100%;
        position: static;
      }

      .input-group-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        width: auto;

        .btn {
          border: none;
          background: transparent;

          &:active, &:hover {
            box-shadow: none;
            color: var(--primary);
            background: transparent;
          }
        }
      }

      .ui-select-container {
        background: white;
      }

      .has-error, .help-block {
        margin-bottom: 0;
        margin-top: 0px;
        font-size: 12px;
        font-weight: bold;
        padding-left: 8px;
      }

      .rp-search .input-group input {
        padding-right: 40px;
      }
    }
  }

  .form-control.ui-select-toggle {
    height: auto;
    padding-top: 19px;
    padding-bottom: 2px;
    border-radius: 0;
  }

  .form-multi {
    border: none;

    .row {
      grid-column-gap: 12px !important;
      grid-row-gap: 0 !important;
      grid-auto-flow: row !important;
      background: white;
    }

    .row > [class*=col-] {
      margin: 6px !important;
      margin-top: 12px !important;

    }

    .row .form-input {
      padding: 0;

      &.radio-input .form-group {
        padding: 0;
        border-bottom: 1px solid rgba(#ccc, 0.2);

        div {
          padding: 0 12px;
        }
      }
    }

    .radio-input {
      background: white;

      label {
        position: static;
      }
    }
  }
}
