//
//applicant nav
//
.page-sub-nav {
  background: white;
  flex-wrap: wrap;
  strong {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
}

.page-sub-nav ul {
  padding-left: 0;
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  flex-wrap: wrap;
  padding-top: 0;
  width: 100%;
  li {
    border-bottom: 2px solid var(--primary);
    margin-right: 2px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    a:hover, .ng-btn:hover {
      opacity: 0.8;
    }

    > span,  a, time {
      color: var(--primary);
      padding: 6px 12px;
      display: flex;
      align-items: center;
      span {
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    &.divider {
      background: none;
      flex: 1;
    }

    time {
      font-weight: bold;
      background: none;
    }
    .btn, button {
      border-radius: 0;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 6px;
      border: none;
    }
    .btn.disabled {
      background: rgba(#D1DADE, 0.4);
      pointer-events: auto;
      opacity: 1;
      font-size: 13px;
      color: var(--primary);
      display: flex;align-items: center;
      i {
        padding-right: 5px;
      }
    }
    .sub-nav-btn {
      width: 100%;
      font-size: 13px;
      color: var(--primary);
      display: flex;align-items: center;
      background: none;
      i {
        padding-right: 6px;
      }
    }

    rp-switch {
      display: flex;
      padding-top: 5px;
      padding-right: 5px;
      .onoffswitch-label {
        margin-bottom: 0 !important;
      }
    }
  }
  .rp-select:after {
    content: "";
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 18px;
    display: flex;align-items: center;
    justify-content: center;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.75);;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    min-width: 250px;
  }
  .form-group {
    margin: 0;
    .btn-group button {
      min-width: 60px;
      text-align: center;
      justify-content: center;
    }
  }
  input {
    border: none;
  }
  .input-group-addon, button, .btn {
    min-width: 38px;
    padding: 0;
    height: 34px;
    justify-content: center;
    align-items: center;
    border: none;
    i.fa-calendar {
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  button, .btn {
    min-width: 60px;
  }
  .input-group {
    border-bottom: none;
    border-radius: 0;
    overflow: hidden;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .input-group-addon i.fa-calendar {
    border: none;
    background: none;
    border-right: 1px solid #ccc;
    color: var(--primary);
  }
  .rp-btn-group {
    border: 1px solid #ccc;
    border-top: none;
    border-bottom: 2px solid var(--primary);
    border-radius: 0;
    i {
      padding-right: 4px;
    }
    &.btn-primary {
      color: white !important;
    }
  }

  @media screen and (max-width: 1200px) {
    a > span, .sub-nav-btn > span {
      display: none;
      i {
        padding-right: 0;
      }
    }
  }
  @media screen and (max-width: 1560px) {
    flex-wrap: nowrap !important;
    li {
      padding: 0;
    }
    > div, > span, a {
      padding: 6px 0;
      display: flex;
      align-items: center;
      span {
        padding-right: 5px;
        padding-left: 5px;
      }
    }
    a > span, li > span, .sub-nav-btn > span {
      display: none;
    }
  }
}
