.category-label {
  display: inline-flex;
  padding: 4px 6px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: #fff;
  background-image: linear-gradient(180deg, #fff, #f5f5fa);
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.10), 0 1px 3px 0 rgba(93, 100, 148, 0.15);
  position: relative;
  font-weight: 700;
  font-size: 10px;
}

.new-label {
	padding: 4px 6px;
	margin-right: 5px;
	margin-bottom: 4px;
	border-radius: 5px;
	background-color: #fff;
	color: var(--text);
	background-image: linear-gradient(180deg, #fff, #f5f5fa);
	box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 1px 3px 0 rgba(93, 100, 148, 0.2);
	position: relative;
	font-weight: 700;
	font-size: 10px;
}

.colored-label {
	font-size: 11px;
	padding: 0 8px;
	height: 20px;
	display: inline-flex;
	align-items: center;
	border-radius: 10px;
	background-color: var(--soft-grey);
	color: var(--grey);
  margin: 0 4px 5px 0;

	.fa {
		margin-left: 4px;
		cursor: pointer;
	}
}
