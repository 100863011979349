.quick-list {
  position: absolute;
  min-width: 200px;
  max-width: 250px;
  height: auto;
  max-height: 180px;
  background-color: #fff;
  border-radius: 5px;
  top: calc(100% + 6px);
  right: 0;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  z-index: 5;

  &.left {
    left: 0;
    right: unset;

    .quick-list__triangle {
      right: unset;
      left: 16px;
    }
  }

  &.right {

    .quick-list__triangle {
      right: 16px;
      left: unset;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    max-height: 180px;
    overflow-y: auto;

    .filter {
      display: flex;
      justify-content: center;
      padding: 4px;

      input {
        background-color: #f4f5f7;
        color: var(--text);
        border: 1px solid #dfe1e6;
        width: calc(100% - 8px);
        padding: 0 10px;
        height: 30px;
        border-radius: 5px;
        font-size: 12px;
        outline: none;
        transition: all 0.4s;

        &::-webkit-input-placeholder {
          color: var(--text);
        }
        &:-ms-input-placeholder {
          color: var(--text);
        }
        &::placeholder {
          color: var(--text);
        }
      }

    }
  }

  &__item {
    display: flex;
    height: 45px;
    position: relative;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    transition: background-color 0.4s;

    &:before {
      font-family: "FontAwesome", sans-serif;
      content: "\f058";
      display: inline-block;
      position: absolute;
      top: 15px;
      right: 12px;
      transform: translateX(40px);
      width: 10px;
      height: 10px;
      color: #4ad869;
      transition: all 0.4s;
    }

    &.active {
      background-color: #f5f5f6;
    }

    &.active {
      &:before {
        transform: translateX(0);
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: #f1f1f6;
      }
    }

    &--expand {
      overflow: unset;

      .quick-list {
        position: absolute;
        top: 0;
        left: 99%;
        width: 200px;
        height: 200px;
        box-shadow: 6px 3px 9px 0 rgba(37, 44, 97, 0.15);
        border-radius: 0 5px 5px 5px;
        opacity: 0;
        transform: translateX(20px);
        transition: all 0.4s;
      }

      &:before {
        display: none;
      }

      &:hover {
        .quick-list {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  &__triangle {
    top: -9px;
    right: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid white;
  }

  &__icon {
    width: 25px;
    height: 25px;
    .svg-icon {
      width: 25px;
      height: 25px;
    }
    svg {
      width: 25px;
      height: 25px;
      fill: var(--text);
    }
  }

  &__text {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    color: var(--text);
  }
}

.user__avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: 12px;
  font-weight: 700;
  color: var(--primary);
  background-image: linear-gradient(180deg,#fff,#f5f5fa);
  box-shadow: 0 4px 11px 0 rgba(37,44,97,.15), 0 1px 3px 0 rgba(93,100,148,.2)
}


