.whiteboard-card {
  &__labels {

    &__wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .colored-label {
        height: 24px;
        border-radius: 60px;
        justify-content: space-between;
        padding: 0 4px 0 6px;

        svg-icon {
          width: 16px;
          height: 16px;
          filter: brightness(0.5);
          margin-left: 4px;
        }
      }
    }

    .add-label {
      font-size: 2rem;
      margin-bottom: 5px;
      color: var(--primary);
    }

    .remove-label {
      font-size: 2rem;
      filter: brightness(0.6);
    }
  }
}
