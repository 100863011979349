.base-radio__wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0 0 0 28px;
  min-height: 21px;
  margin: 0;
  cursor: pointer;
  font-size: 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }

  &__checkmark {
	position: absolute;
	left: 0;
	height: 21px;
	width: 21px;
	display: block;
	background-color: var(--grey);
	border-radius: 50%;
	transition: all 0.2s;

	&:after {
	  position: absolute;
	  top: 4px;
	  left: 4px;
	  display: block;
	  width: 13px;
	  height: 13px;
	  content: "";
	  color: #fff;
	  border-radius: 50%;
	  background-color: var(--primary);
	  transform: scale(0.5);
	  opacity: 0;
	  transition: all 0.3s;
	}
  }

  &:hover &__input ~ &__checkmark {
	background-color: var(--soft-grey);
  }

  & &__input:checked ~ &__checkmark, &__checkmark.checked {
	box-shadow: 0 0 2px 0 rgba(150, 150, 150, 0.1) inset;

	&:after {
	  transform: scale(1);
	  opacity: 1;
	}
  }
  &:after {
	content: "";
	position: absolute;
	display: none;
  }
}

