.todo-card__comments {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 2px dashed #efefef;

  h4 {
    color: darken($dark-text-color, 5%);
    margin: 0 0 10px 0;
    padding: 0 5px;
    display: flex;
    align-items: center;
    position: relative;

    span {
      display: inline-flex;
      margin-left: auto;
      cursor: pointer;
      transition: all 0.4s;

      &.active {
        transform: rotate(180deg);
      }

      i, fa-icon {
        color: var(--primary);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;

    textarea {
      margin-top: 16px;
    }

    &__done {
      margin-top: -88px;
      margin-bottom: 13px;

      &__icon {
        display: block;
        height: 75px;
        fill: var(--primary);
      }
    }

    &__button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;

      i {
        position: absolute;
        margin-top: 13px;
        margin-right: 16px;
        color: #ff0000;
        display: flex;
        align-items: center;
      }
    }

    .flx-text-highlight-tag {
      font-weight: 500;
    }
  }
}

.flx-text-highlight-tag.highlight-tag {
  background-color: var(--soft-text);
  font-weight: 400;
}

span.mention {
  color: white;
  background-color: var(--text);
  border-radius: 10px;
  padding: 0 3px 0 3px;
  margin: 0 3px 0 0;

  &.current-user {
    background-color: var(--primary);
  }

}

flx-text-input-autocomplete .menu-template-container .quick-list {
  position: static;
}
