.law-firm-card {
  background-color: var(--soft-grey);
  border-radius: 5px;
  padding: 8px;
  margin: 0 0 8px 8px;
  display: flex;
  align-items: center;
  max-height: 35px;

  &__name {
    font-weight: 600;
    color: var(--primary);
    display: block;
  }

  &__divider {
    background-color: var(--soft-text);
    width: 2px;
    height: 26px;
    margin: 0 4px;
  }

}
