.fieldset {
  padding: 15px !important;
  align-items: start !important;
  border: 1px solid var(--soft-grey);
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.05), 0 1px 9px 0 rgba(93, 100, 148, 0.05);
  color: var(--text);
  border-radius: 10px;

  h1, h2, h3, h4, h5, h6 {
    color: var(--primary);
  }
}

.ng-dropdown-panel {
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  border: 1px solid var(--grey);
}

.ng-value-container .ng-input {
  top: 0 !important;
  bottom: 0 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: var(--text);
  font-size: 11px;
  font-weight: 400;

  p {
    margin: 0;
    padding: 0;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #def7f2;
  color: var(--text);
}

.ng-select.ng-select-multiple .ng-select-container {
  // overflow-x: scroll;
}

.ng-select.ng-select-multiple:not(.bg-ignore) .ng-select-container .ng-value-container .ng-value {
  background-color: var(--primary);
  color: white;
  margin-right: 3px;
  overflow: hidden;
  padding-right: 10px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: var(--danger);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #def7f2;
  color: var(--text);
  font-weight: 400;
}

.ng-select-container {
  position: static !important;
  height: auto !important;
  min-height: 21px !important;
  border: none !important;
  background: none !important;
  .ng-clear-wrapper, .ng-arrow-wrapper {
    display: none;
  }
}
.show-clear-all .ng-select-container .ng-clear-wrapper{
  display: block;
}

.google-places-input {
	align-self: center;
	padding-right: 8px;
	border-right: 4px dotted;
}
