.modal {
  display: flex !important;
  width: 100%;

  .modal-body {
    padding: 20px;
    background: white;
    border-radius: 0;
    max-height: 80vh;
    overflow-y: auto;
    min-width: 650px;
  }

  &-xl {
    max-width: 1200px;
  }

  &-3xl {
    width: 1500px;
  }
}

.confirmation-modal {
  z-index: 10000 !important;
}


.rp-modal {
  background: white;
  padding: 40px 30px;
}

/* MODAL */
.modal-content {
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  outline: 0 none;
  border-radius: 0;
  position: relative;
}

.modal-dialog {
  z-index: 2200;
  margin: auto !important;
  max-width: 80%;
  min-width: 650px;
  width: auto !important;

  iframe {
    min-width: 1000px;
  }
}

.animated.modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.modal-footer {
  margin-top: 0;
}

// Bootstrap issue in 3.3.0 with modal https://github.com/twbs/bootstrap/issues/9855
// Fix
body.modal-open {
  padding-right: inherit !important;
}

// Fix for modal backdrop with animate.css
body.modal-open .animated {
  animation-fill-mode: initial;
  z-index: inherit
}

.modal .indicator-report-modal {
  padding: 50px 10px;
  background: white;

  .datagrid-wrapper, .portfolio-followup-table-header, .datagrid-wrapper-inner {
    padding: 0 !important;
    background: white;
  }

  .datagrid-wrapper-inner tbody:nth-child(even) tr {
    background: white;

    &:hover {
      background: white;
    }
  }

  .datagrid-wrapper-inner tbody:nth-child(odd) tr {
    background: #f3f3f4;

    &:hover {
      background: #f3f3f4;
    }
  }

  .inner-table-wrap {
    width: 100%;
    overflow-x: hidden;
  }
}

.modal .modal-body.rp-form .ui-select-container {
  background: white;
}

.ng-dropdown-panel.base-input {
  z-index: 3000;
}

@media screen and (max-width: 767px) {
  .modal {
    .modal-dialog,
    .modal-body {
      min-width: 90%;
    }
  }
}
