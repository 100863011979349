.flex-1 {
  flex: 1;
}

.gap-1 {
  row-gap: 0.25rem;
  column-gap: 0.25rem;
}

.gap-2 {
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.gap-3 {
  column-gap: 0.75rem;
  row-gap: 0.75rem;
}

.gap-4 {
  row-gap: 1rem;
  column-gap: 1rem;
}

.gap-5 {
  row-gap: 1.25rem;
  column-gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.max-w-80 {
  max-width: 20rem;
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.transition-none {
  transition: none !important;
}

.m-0 {
  margin: 0;
}

.mb-4 {
  margin-bottom: 1rem;
}

.group:focus-within {

  .group-focus-within\:font-bold {
    font-weight: 700;
  }
}

.font-bold {
  font-weight: 700;
}

.min-w-40 {
  min-width: 160px;
}

.break-all {
  word-break: break-all;
}

.sticky {
  position: sticky !important;
}

.top-1	{
  top: 0.25rem;
}

.h-full {
  height: 100%;
}
