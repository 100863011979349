.permission-cell {
  width: 100%;
  height: 100%;

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
  }
  &__value {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__editor {
    position: absolute;
    top: 80%;
    z-index: 5;
    left: 10%;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 5px 15px 0 rgba(37,44,97,.15), 0 2px 4px 0 rgba(93,100,148,.2);
    div {
      padding: 10px 15px;
      width: 100%;
      transition: all 0.4s;
      &:hover {
        background-color: #fafafa;
      }
    }
  }

  .permission{
    font-weight: 600;
    &--none {
      color: #fb2b2b;
      font-weight: 600;
      text-align: center;
    }
    &--read, &--own {
      color: #f5c236;
      font-weight: 600;
      text-align: center;
    }
    &--write, &--all {
      color: #5fb766;
      font-weight: 600;
      text-align: center;
    }
  }

  &__in-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
