.loading-panel {
	z-index: 5;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	display: flex;
	justify-content: center;

	&__message {
		height: 100%;
		max-height: 100vh;
		font-size: 14px;
		color: var(--primary);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		&__text {
			margin-top: 15px;
		}
	}
}
