.tier-rating {
  display: inline-flex;
  align-items: flex-end;
  position: relative;

  .crown-icon {
	position: absolute;
	left: 0;
	bottom: 4px;

	svg {
	  width: 10px;
	  height: 10px;
	  fill: #69e619;
	}
  }

  span {
	font-weight: 700;
	font-size: 16px;
	margin-left: 4px;
	text-transform: uppercase;
  }

  &.t5 {
	div:nth-child(1) {
	  background-color: #f33000;
	}
	span {
	  color: #f33000;
	}
  }

  &.t4 {
	div:nth-child(1), div:nth-child(2) {
	  background-color: #ff6d00;
	}
	span {
	  color: #ff6d00;
	}
  }

  &.t3 {
	div:nth-child(1), div:nth-child(2), div:nth-child(3) {
	  background-color: #ffd600;
	}
	span {
	  color: #ffd600;
	}
  }

  &.t2 {
	div:nth-child(1), div:nth-child(2), div:nth-child(3), div:nth-child(4) {
	  background-color: #b3f100;
	}
	span {
	  color: #b3f100;
	}
  }

  &.t1, &.t0 {
	div:nth-child(1), div:nth-child(2), div:nth-child(3), div:nth-child(4), div:nth-child(5) {
	  background-color: #69e619;
	}
	span {
	  color: #69e619;
	}
  }

  div {
	width: 4px;
	background-color: var(--dark-grey);
	border-radius: 1px;
	margin-right: 2px;
  }

  div:nth-child(1) {
	height: 4px;
  }

  div:nth-child(2) {
	height: 8px;
  }

  div:nth-child(3) {
	height: 12px;
  }

  div:nth-child(4) {
	height: 16px;
  }

  div:nth-child(5) {
	height: 20px;
  }

  &.small {
	span {
	  font-size: 10px;
	  margin-bottom: -2px;
	}

	div:nth-child(1) {
	  height: 2px;
	}

	div:nth-child(2) {
	  height: 4px;
	}

	div:nth-child(3) {
	  height: 6px;
	}

	div:nth-child(4) {
	  height: 8px;
	}

	div:nth-child(5) {
	  height: 10px;
	}
  }
}
