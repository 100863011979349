.multi-select {
  position: relative;

  &__input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
    box-shadow: none;
    background-color: #f4f5f7;
    border: 1px solid #dfe1e6;
  }

  &--old {
    .multi-select__input {
      height: 34px;
      background-color: #fff;
      border-radius: 3px;
      padding: 12px;
      border-color: #ccc;

      &:focus, &:active {
        border-color: var(--primary);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
      }
    }
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    background-color: #fff;

    border-radius: 5px;
    z-index: 2;
    box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);

    &__item {
      font-size: 11px;
      &__parent {
        cursor: pointer;
        height: 35px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        color: #686a7e;
        transition: all 0.3s;

        &:hover {
          background-color: #f5f5f6;
        }

        &.selected {
          background-color: var(--primary);
          color: white;
        }
      }
    }
  }
}
