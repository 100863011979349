.base-flex {
  display: flex;

  &__item-right {
    margin-left: auto;
  }

  &__item-left {
    margin-right: auto;
  }

  &__left-margin {
    margin-left: 20px;
  }

  &__right-margin {
    margin-right: 20px;
  }

  &--align-center {
    align-items: center;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-end {
    justify-content: end;
  }

  &--full-width {
    width: 100%;
  }

  &--half-width {
    width: 50%;
  }

  &--gap-10 {
    gap: 10px;
  }

  &--align-center {
    align-items: center;
  }
}
