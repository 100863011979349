.datagrid-wrapper-header, .datagrid-wrapper-header th {
	&::-webkit-scrollbar:horizontal {
			display: none;
	}
}

rp-table-cell {
    display:  block;
	.edit-button {
		visibility: hidden;
		cursor: pointer;
	}
	div > span {
		max-width: 300px;
		display: inline-block;
		white-space: normal;
		transition: none;
		&:hover {
			max-height: none;
		}
	}
}

rp-icon-toggle {
  display: inline-block;
  padding: 0 2px;
}

table .quick-actions i:hover{
  color: var(--primary);
}

rp-table-cell:hover .edit-button {
	visibility: visible;
}

/*
* DATAGRIDS
 */


.datagrid-wrapper-inner {
	flex: 1;
  @extend .flex-fix;
	.actions-list {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		li {
			font-size: 18px;
			display: flex;

			a {
				display: flex;
				color: #676a6c;
				padding-left: 5px;
			}
		}
	}
	#portfolio-followup-table {
		overflow: auto;
		table {
			margin-bottom: 0;
		}
	}
}

.ui-view {
	height: 100%;
	display: block;
}

ui-view {
	> * {
		flex: 1 100%;
	}
}


