.radiobutton__wrapper, label.radiobutton__wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0 0 0 28px;
  min-height: 21px;
  margin: 0 10px 0 0;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #686a7e;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    display: block;
    background-color: #eaebf1;
    transition: all 0.2s;

    &:after {
      position: absolute;
      top: 3px;
      left: 5px;
      display: block;
      width: 12px;
      height: 12px;
      content: "\f00c";
      font-family: "FontAwesome", sans-serif;
      color: #fff;
      transform: scale(0.5);
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &:hover &__input ~ &__checkmark {
    background-color: darken(#eaebf1, 5%);
  }

  & &__input:checked ~ &__checkmark {
    background-color: var(--primary);

    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  span {
    font-size: 11px;
    font-weight: 600;
    color: #686a7e;
  }
}
