.permissions-editor {
	width: 100%;
	.roles-select {
		display: flex;
		flex-wrap: wrap;
		margin-top: 7px;
		.form-check {
			flex: 1;
      @extend .flex-fix;
			padding: 0 5px;
		}
		.form-check-label {
			display: flex;
			font-weight: bold;
			input {
				margin-right: 8px;
			}
		}
	}

	.permission-table {
		border: 1px solid #ccc;;
		margin-top: -1px;
		margin-bottom: 5px;
		border-radius: 3px;
		.permission-header {
			display: inline-block;
			cursor: pointer;
			width: 100%;
		}
		h4 {
			margin: 0;
			font-size: 14px;
			width: 100%;
			padding: 10px;
			&:hover {
				color: var(--primary);
			}
		}
		.fa {
			color: var(--primary);
		}
		td {
			padding: 0 !important;
			height: 30px;
			line-height: 30px;
			position: relative !important;
		}
	}

	table {
		margin-bottom: 0;
	}

	table, thead, tr, td, th {
		border-collapse: collapse;
		border: 1px solid #D1DADE !important;
	}
	table tr:nth-child(even) {
		background:  white;
	}
	table tr td {
		padding: 0 !important;
		height: 30px;
		line-height: 30px;
		position: relative;
		&:first-child {
			padding-left: 10px !important;
			font-weight: bold;
			.fa {
				color: #676a6c;
				padding-right: 4px;
			}
		}
	}
	table thead tr th:not(:first-child) {
		text-align: center;
		width: calc(100% / 10);
		background: var(--primary);
		color: white;
	}

	table thead tr th:first-child {
		border: none !important;
		padding: 0;
		.form-group {
			margin-bottom: 0;
			font-weight: normal;
		}
	}
}

.permission {
	position: absolute;
	max-height: 30px;
	left: 0;
	top: 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	transition: all 200ms linear;
	font-size: 14px;
		background: inherit;
		.permission-all, .permission-write {
			height: 30px;
			line-height: 30px;
			color: var(--primary);
		}
		.permission-read {
			height: 31px;
			line-height: 31px;
			color: #FF9900;
		}
		.permission-none {
			height: 31px;
			line-height: 31px;
			color: var(--danger);
		}
	&.list {
		max-height: none;
		z-index: 99;
		background: var(--primary) !important;
		color: white;
		label {
			height: 31px;
			line-height: 31px;
			border: 1px solid white;
			color: white !important;
		}
		&:first-child {border-bottom: none}
		&:last-child {border-top: none}
	}
	label {
		width: 100%;
		text-align: center;
		padding-left: 0;
		margin-left: 0 !important;
		line-height: 0;
		font-weight: normal;
		height: 0;
		overflow: hidden;
		flex-basis: 100%;
		transition: all 100ms linear;
		margin-bottom: 0;
		&:hover {
			background: #2BAF95;
		}
	}
}

.permission-old {
	position: absolute;
	overflow-y: hidden;
	max-height: 30px;
	left: 0;
	top: 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	transition: all 200ms linear;
	font-size: 14px;
	&.bg-success {
		background: inherit;
		.permission-all {
			height: 30px;
			line-height: 30px;
			color: var(--primary);
		}
	}
	&.bg-warning {
		background: inherit;
		.permission-read {
			height: 31px;
			line-height: 31px;
			color: #FF9900;
		}
	}
	&.bg-danger {
		background: inherit;
		.permission-none {
			height: 31px;
			line-height: 31px;
			color: var(--danger);
		}
	}
	&:hover {
		overflow-y: visible;
		max-height: none;
		z-index: 99;
		background: var(--primary) !important;
		color: white;
		label {
			height: 31px;
			line-height: 31px;
			border: 1px solid white;
			color: white !important;
		}
		label:first-child {border-bottom: none}
		label:last-child {border-top: none}
	}
	label {
		width: 100%;
		text-align: center;
		padding-left: 0;
		margin-left: 0 !important;
		line-height: 0;
		font-weight: normal;
		height: 0;
		overflow: hidden;
		flex-basis: 100%;
		transition: all 100ms linear;
		&:hover {
			background: #2BAF95;
		}
	}
	input[type="radio"] {
		display: none;
	}
}
