.new-table {
  tr td {
    font-size: 12px;
    height: 26px;
    border-bottom: 1px solid #f1f1f6;
    color: #686a7e;

    &:first-child {
      font-weight: 600;
    }
  }

  .table-name {
    font-size: 12px;
    color: var(--primary);
  }

  .table-header {
    font-size: 10px;
    background-color: #d9d9e4;
    color: #686a7e;
  }

  .table-select {
    display: flex;
    align-items: center;

    &__name {
      color: #686a7e;
    }

    &__select {
      min-height: 30px;
      color: var(--primary);
      margin-left: 6px;
      cursor: pointer;
      position: relative;
    }
  }

  &--clean {
    tr td {
      border-bottom: none;
    }
  }
}


