.reporting {
  display: grid;
  grid-template-columns: minmax(500px, 3fr) minmax(100px, 1fr);
  grid-gap: 10px;
  padding: 10px;
  overflow: auto;
  width: 100%;
  grid-template-rows: auto 1fr;
  .multiSelect .checkboxLayer {
    left: 15px !important;
    right: unset;
  }
}

static-pool-options-modal {
	.title {
		font-size: 16px;
		font-weight: 600;
	}

	.select {
		float: right;
		margin-top: 2px;

		span {
			cursor: pointer;
		}
	}
}

report-options-modal-1 {
	.title {
		font-size: 16px;
		font-weight: 600;
	}

	.select {
		float: right;
		margin-top: 2px;
		margin-right: 20px;

		span {
			cursor: pointer;
		}
	}
}

report-options-modal-2 {
	.managers-wrapper {
		label {
			font-size: 16px;
			font-weight: 600;
		}

		.select {
			float: right;
			margin-top: 5px;

			span {
				cursor: pointer;
			}
		}
	}
}
