body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: var(--dark);
  font-size: 13px;
  color: var(--text);
  overflow-x: hidden;
}

html, body {
  height: 100%;
}

.layout {
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) 0;
  grid-template-rows: 4rem minmax(0, 1fr);
  justify-items: stretch;
  align-items: stretch;
  grid-template-areas:
		  "side-navigation header header"
		  "side-navigation content quick-bar";

  .layout__side-navigation {
    grid-area: side-navigation;
    background: var(--dark);
    display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
  }

  .layout__header {
	grid-area: header;
  }

  .layout__content {
    grid-area: content;
    overflow: auto;
    display: flex;
    background-color: #fff;
  }
}


.view {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}


.ibox {
	margin-bottom: 0;
	background: white;
	flex: 1;
	max-width: 100%;
  	box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.05), 0 1px 9px 0 rgba(93, 100, 148, 0.05);
  	border: 1px solid var(--grey);
  	border-radius: 5px;
  @extend .flex-fix;
}

.ibox-content, .ibox-title {
	background: none;
	flex: 0 1 auto;
	padding: 10px;
  	color: var(--text);
  @extend .flex-fix;
	h4 {
		font-size: 18px;
		font-weight: normal;
		display: block;
		margin: 0;
	}
}

.ibox-content {
	// border-top: 1px solid #ccc;
	flex: 0 1 auto;
  @extend .flex-fix;
}

.ibox-tools {
	padding-right: 8px;
	padding-top: 4px;
	a {
		cursor: pointer;
		margin-left: 5px;
		color: #c4c4c4;
	}
}

.rp-ibox {
	padding: 10px;
	border: 1px solid rgba(110, 110, 110, 0.3);
	border-top: 3px solid var(--primary);
	.title {
		border: none;
		padding: 10px;
		border-bottom: 1px solid rgba(110, 110, 110, 0.3);
		margin: -10px -10px 10px -10px;
	}
}

.panel {
	background: white;
	padding: 15px;
	margin-bottom: 0;
	.panel-body {
		background: none;
	}
	&.panel-default {
		padding: 0;
		border: 1px solid #ccc;
		border-radius: 0 !important;
	}
	&.panel-default > .panel-heading {
		background: none;
	}
}

.panel-group {
	margin-bottom: 10px;
}

.panel-group .panel + .panel {
	margin-top: 4px;
}

.rp-panel {
  background: white;
  padding: 8px !important;
  border: 1px solid #ccc;
}

.rp-tile {
	background: white;
  border-left: 2px solid #ccc;
  word-break: break-word;
  padding: 5px !important;
}
