.agenda-detail .notes__form__actions {

  .link__bg-secondary {

    &.reachout-target-dropdown__wrapper-expand {
      width: 60px;
      height: 25px;
      background-color: #fff;
      box-shadow: 0 4px 11px 0 rgb(37 44 97 / 15%), 0 1px 3px 0 rgb(93 100 148 / 20%);

      svg-icon {
        width: 25px;
        height: 25px;

        flex: 1 1 1px;
        margin-left: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        svg {
          fill: var(--primary);
        }
      }

      .reachout-target-dropdown__option-label {
        background-color: var(--primary);
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        height: 15px;
        border-radius: 0 0 5px 5px;
        width: 100%;
        text-align: center;
      }
    }
  }

  .link {
    &.reachout-target-dropdown {
      &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      select-list {
        z-index: 10;
        position: absolute;
        margin-top: 40px;

        .select-list {
          position: unset;
        }
      }

    }
  }
}

.reachout-target-dropdown__renderer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .icon-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: stretch;

    fa-icon {
      position: relative;
      top: -2px;
      font-size: 10px;
      color: #ED5565;
    }
  }
}
