$todo-color: #efb40e;
$todo-bg: #fff7d2;
$in-progress-color: #0e9aef;
$in-progress-bg: #d2eeff;
$done-color: #00c722;
$done-bg: #d2ffd3;
$archived-color: #73768c;
$archived-bg: #f1f1f6;

$todo-danger-color: #ED5565;
$hover-navy-color: #20b99b;
$hover-bg-color: #f1f1f6;
$dark-text-color: #73768c;

.todo-color {
  color: $todo-color;
}

.in-progress-color {
  color: $in-progress-color;
}

.done-color {
  color: $done-color;
}

.todo-card {
  width: 100%;
  max-width: 345px;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, .05), 0 2px 4px 0 rgba(93, 100, 148, .1);

  &--danger, &--warning {
    border-radius: 10px;
  }

  &--danger {
    background-color: #fff4f4;
  }

  &--warning {
    background-color: #fffdf1;
  }

  &__wrapper {
    padding: 15px;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 20px;

    &__labels {
      flex: 1;
      display: flex;

      &__categories {
        display: inline-flex;
        margin-left: 8px;
        border-left: 1px solid #e6e6e6;
        cursor: pointer;
        position: relative;
        flex: 1;

        &__wrapper {
          display: flex;
          flex: 1;
          transition: all 0.4s;
          border-radius: 0 5px 5px 0;

          .quick-list {
            left: 0 !important;
            right: unset !important;
          }

          &:hover {
            background-color: $hover-bg-color;
          }
        }

        &__item {
          display: inline-block;
          padding: 0 8px;
          font-weight: 600;
          font-size: 10px;
        }
      }

      &__item {
        position: relative;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        height: 20px;
        padding: 0 8px;
        font-size: 10px;
        font-weight: 600;
        cursor: pointer;

        &.in-progress {
          background-color: $in-progress-bg;
          color: $in-progress-color;
        }

        &.todo {
          background-color: $todo-bg;
          color: $todo-color;
        }

        &.done {
          background-color: $done-bg;
          color: $done-color;
        }

        &.archived {
          background-color: $archived-bg;
          color: $archived-color;
        }

      }
    }

    &__menu-btn {
      width: 28px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 5px;
      position: relative;
      transition: all 0.4s;

      &:hover {
        background-color: $hover-bg-color;
      }

      .quick-list {
        right: -9px;
      }

      span {
        width: 4px;
        height: 5px;
        border-radius: 50%;
        background-color: $dark-text-color;
        margin: 0 1px;
      }
    }

    fa-icon {
      color: var(--funded);
      cursor: pointer;

      &:hover {
        color: $hover-navy-color;
      }
    }
  }

  &__summary {
    display: flex;
    font-size: 18px;
    margin: 10px 0 0;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    color: $dark-text-color;
    transition: all 0.4s;

    &:hover {
      background-color: $hover-bg-color;
    }
  }

  &__summary-edit {
    display: flex;
    margin: 10px 0 0;
    height: 40px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      min-height: 40px;
      padding: 0 45px 0 10px;
      border: 1px solid #dfe1e6;
      border-radius: 5px;
      color: $dark-text-color;
      background-color: #f4f5f7;

      &:hover, &:active, &:focus {
        outline: none;
      }

      &::placeholder {
        font-style: italic;
      }
    }

    &__btn {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      background-color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: $hover-navy-color;
      }
    }

    .svg-icon {
      width: 20px;
      height: 20px;
      fill: #fff;

      svg {
        width: 20px;
        height: 20px;
        fill: #fff;
      }
    }
  }

  &__due-date {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    color: var(--primary);
    font-size: 12px;
    position: relative;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: $hover-bg-color;

      .todo-card__due-date__remove {
        opacity: 1;
        transform: scale(1);
      }
    }

    .svg-icon {
      width: 30px;
      height: 30px;
      fill: var(--primary);

      svg {
        width: 30px;
        height: 30px;
      }
    }

    &__remove {
      margin-right: 5px;
      display: flex;
      margin-left: auto;
      opacity: 0;
      transform: scale(0);
      transition: all 0.4s;

      .svg-icon {
        width: 25px;
        height: 25px;
        fill: $dark-text-color;

        svg {
          width: 25px;
          height: 25px;
        }

        &:hover {
          fill: $todo-danger-color;
        }
      }
    }

    &__wrapper {
      position: absolute;
      top: 60px;
      left: 0;
      width: 215px;
      height: 350px;
      background-color: #fff;
      z-index: 10;
      border-radius: 5px;
      box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);

      .custom-datepicker-theme {
        .bs-datepicker-head {
          min-width: 215px;
        }
      }

      &__timepicker {
        position: relative;
        padding: 1px 10px;

        .clear {
          position: absolute;
          bottom: 0;
          right: 20px;
          font-size: 11px;
          color: var(--primary);
          cursor: pointer;
        }
      }

      &__btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
      }

      button {
        width: 90%;
        border: none;
        border-radius: 5px;
        background-color: var(--primary);
        color: white;
        height: 30px;
        transition: all 0.4s;
      }
    }
  }

  &__footer {
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    position: relative;

    &__assignee {
      display: inline-flex;
      cursor: pointer;

      .user__avatar {
        margin: 0 2px;
      }

      .quick-list {
        left: -8px;
        min-height: 135px;
      }
    }

    &__actions {
      margin-left: auto;
      width: 68px;
      height: 100%;
    }

    &__expand {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 50px;
      height: 30px;
      color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 5px;
      opacity: 0;
      transform: translateY(10px);
      transition: all 0.4s;

      i, fa-icon {
        transition: all 0.4s;
      }

      &:hover {
        background-color: $hover-bg-color;
      }

      &.active {
        i, fa-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  &:hover {
    .todo-card__footer__expand {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__expand-area {
    position: relative;
    height: fit-content;
    transition: all 0.4s;

    &__wrapper {
      padding: 10px;
    }
  }

  &__description {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px dashed #efefef;

    h4 {
      color: darken($dark-text-color, 5%);
      margin: 0 0 5px 0;
      padding: 0 5px;
    }

    &__text {
      color: $dark-text-color;
      padding: 5px;
      border-radius: 5px;
      transition: all 0.4s;

      &__no-message {
        color: #73768c;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        font-size: 11px;

        span {
          color: var(--primary);
          margin: 0 3px;
        }
      }

      &:hover {
        background-color: $hover-bg-color;
      }
    }

    &__edit {
      textarea {
        width: 100%;
        min-height: 100px;
        border-radius: 5px;
        padding: 5px;
        color: $dark-text-color;
        border: 1px solid #dfe1e6;
        background-color: #f4f5f7;

        &:hover, &:active, &:focus {
          outline: none;
        }
      }

      &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
  }

  &__attachments {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px dashed #efefef;

    h4 {
      color: darken($dark-text-color, 5%);
      margin: 0 0 10px 0;
      padding: 0 5px;
      display: flex;
      align-items: center;
    }

    &__no-message {
      color: $dark-text-color;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      font-size: 11px;

      span {
        color: var(--primary);
        margin: 0 3px;
      }
    }

    &__upload-area {
      width: 100%;
      height: 100px;
      border-radius: 5px;
      border: 1px dashed $in-progress-color;
      background-color: $in-progress-bg;
    }

    &__divider {
      width: 100%;
      height: 1px;
      margin: 15px 0 10px;
      background: #fff;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6edf1 50%, rgba(255, 255, 255, 0) 100%);
    }

    &__item {
      padding: 3px;
      display: flex;
      align-items: center;
      border-radius: 5px;

      &:hover {
        background-color: $hover-bg-color;

        .svg-icon.trash {
          opacity: 1;
          transform: scale(1);
        }
      }

      .svg-icon {
        width: 20px;
        height: 20px;
        fill: var(--primary);
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }

        &.trash {
          margin-left: auto;
          opacity: 0;
          fill: $dark-text-color;
          transform: scale(0);
          transition: all 0.4s;

          &:hover {
            fill: $todo-danger-color;
          }
        }
      }

      a {
        display: inline-flex;
      }
    }
  }

  &__associations {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px dashed #efefef;

    h4 {
      color: darken($dark-text-color, 5%);
      margin: 0 0 10px 0;
      padding: 0 5px;
      display: flex;
      align-items: center;
      position: relative;

      button {
        margin-left: auto
      }
    }

    .quick-list {
      max-height: 300px;
      right: -6px;

      &__content {
        max-height: 300px;
      }

      &__item:hover {
        cursor: pointer;
      }

    }

    &__search-bar {
      padding: 5px;
      width: 100%;

      &__search {
        display: flex;
        flex-flow: column;
        position: relative;
        width: 100%;
        height: 100%;

        label {
          color: $dark-text-color;
          font-size: 12px;
          font-weight: 600;
          margin-left: 5px;
          margin-bottom: 0;
        }

        ng-select {
          width: 100%;
          border: 1px solid #dfe1e6;
          border-radius: 5px;
          background-color: #f4f5f7;
          font-size: 13px;
          box-shadow: none;

          input {
            color: $dark-text-color;
          }

          &:hover, &:active, &:focus {
            outline: none;
          }

          &::placeholder {
            font-style: italic;
          }

          .ng-select-container {
            position: relative !important;

            &:hover {
              box-shadow: none;
            }
          }

          .ng-dropdown-panel.ng-select-bottom {
            z-index: 3;
            border: none;
            height: 0;
            margin: 0;
            padding: 0;
          }

          .ng-dropdown-panel .scroll-host {
            background-color: #fff;
            box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
            border-radius: 5px;
          }

          .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            margin: 0;
            font-size: 12px;
            font-weight: 600;
            color: #73768c;
          }

          .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
            background-color: #f6fff7;
          }
        }


        &__btn {
          position: absolute;
          top: 4px;
          right: 4px;
          width: 32px;
          height: 32px;
          border-radius: 5px;
          background-color: var(--primary);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            background-color: $hover-navy-color;
          }
        }

        .svg-icon {
          width: 25px;
          height: 25px;
          fill: #fff;

          svg {
            width: 25px;
            height: 25px;
            fill: #fff;
          }
        }
      }
    }

    &__no-message {
      color: $dark-text-color;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      font-size: 11px;
      margin-bottom: 10px;

      span {
        color: var(--primary);
        margin: 0 3px;
      }
    }

    &__item {
      padding: 3px 6px;
      display: flex;
      align-items: center;
      border-radius: 5px;

      &:hover {
        background-color: $hover-bg-color;

        .svg-icon.trash {
          opacity: 1;
          transform: scale(1);
        }
      }

      .svg-icon {
        width: 20px;
        height: 20px;
        fill: var(--primary);
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }

        &.trash {
          margin-left: auto;
          opacity: 0;
          fill: $dark-text-color;
          transform: scale(0);
          transition: all 0.4s;

          &:hover {
            fill: $todo-danger-color;
          }
        }
      }

      a {
        display: inline-flex;
        align-items: center;
      }

      .m-r {
        margin-right: 10px
      }
    }
  }

  &__activity-log {

    h4 {
      color: darken($dark-text-color, 5%);
      margin: 0 0 16px 0;
      padding: 0 5px;
      display: flex;
      align-items: center;

      span {
        display: inline-flex;
        margin-left: auto;
        cursor: pointer;
        transition: all 0.4s;

        &.active {
          transform: rotate(180deg);
        }

        i, fa-icon {
          color: var(--primary);
        }
      }
    }

    &__content {
      padding-left: 4px;
    }

    &__item {
      padding: 5px 12px;
      margin-bottom: 8px;
      font-size: 12px;
      color: $dark-text-color;
      position: relative;
      background-color: #f0f0f5;
      border-radius: 5px;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        background-color: var(--primary);
        top: 50%;
        transform: translate(-15px, -3px);
      }

      span {
        color: var(--primary);
        font-style: italic;
        font-weight: 600;
      }
    }

    .___tooltip {
      position: relative;
      display: inline-block;
      border-bottom: 1px dotted black;

      .tooltipText {
        display: none;
        width: 20rem;
        bottom: 100%;
        left: 20%;
        margin-left: -50%;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 6px;
        border-radius: 6px;

        position: absolute;
        z-index: 1;

        &::after {
          content: " ";
          position: absolute;
          top: 100%; /* At the bottom of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: black transparent transparent transparent;
        }

      }

    }

    .___tooltip:hover .tooltipText {
      display: block;
    }
  }

  &__action-dialog {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    z-index: 6;

    h3 {
      color: $dark-text-color;
      margin-bottom: 25px;

      span {
        color: var(--primary);
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  .btn {
    width: 100%;
    max-width: 68px;
    height: 100%;
    max-height: 35px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: all 0.4s;

    &:hover, &:focus, &:active {
      outline: none;
    }

    &--finish {
      background-color: $in-progress-color;

      &:hover {
        background-color: lighten($in-progress-color, 10%);
      }
    }

    &--start {
      background-color: $todo-color;

      &:hover {
        background-color: lighten($todo-color, 10%);
      }
    }

    &--reopen {
      background-color: #cecece;

      &:hover {
        background-color: lighten(#cecece, 5%);
      }
    }

    &--save {
      background-color: var(--primary);
    }

    &--cancel {
      color: var(--primary);
      border: none;
      background-color: transparent;

      &:active {
        box-shadow: none;
      }
    }

    &--remove {
      background-color: $todo-danger-color;

      &:hover {
        background-color: lighten($todo-danger-color, 5%);
      }
    }

    &--attachment, &--association {
      margin-left: auto;
      width: 21px;
      height: 21px;
      border: none;
      border-radius: 5px;
      background-color: var(--primary);
      color: #fff;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s;

      span {
        position: absolute;
        top: 2px;
        left: 6px;
        transition: all 0.4s;
      }

      &.active {
        span {
          transform: rotate(225deg);
        }
      }

      &:hover {
        background-color: $hover-navy-color;
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 2000;
}
