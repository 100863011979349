.law-firm-view {
  &__content {
		background-color: #fff;
		flex: 1;
		max-height: calc(100vh - 60px);
		overflow-y: auto;
		padding: 0 20px;
    -webkit-overflow-scrolling: touch;

		&.list-content {
			background-color: #f1f1f6;
		}

		&__header {
			display: flex;
			position: -webkit-sticky; /* Safari */
			position: sticky;
			top: 0;
			z-index: 1051;
			background-color: #fff;
			align-items: center;
			padding: 0 8px;
			min-height: 80px;
			margin-bottom: 10px;
			border-bottom: 2px dashed #eaeaf1;

      .content-wrap {
        padding-bottom: 10px
      }

			&:hover {
				.law-firm-view__content__header__edit-button {
					opacity: 1;
					transform: translateY(0);
				}
			}

      .back-btn{
        font-size: 2.2rem;
        margin-right: 10px;
        color: var(--text);
      }

      .add-todo-btn{
        margin-left:10px;
      }

			.vip-badge {
				margin-right: 20px;
			}

			&__id {
				color: var(--text);
				font-size: 14px;
				font-weight: 600;
				margin-left: 10px;
			}

			&__edit-button {
				margin-left: 10px;
				opacity: 0;
				transform: translateY(-30px);
				cursor: pointer;
				transition: all 0.4s;
			}

			h2 {
				margin-left: 10px;
				font-size: 20px;
				display: inline-flex;
				font-weight: 700;
				color: var(--primary);
			}
		}

    &__form {
      padding: 10px;
      border: 1px solid var(--grey);
      grid-column: span 3;
      border-radius: 5px;
      width: 100%;
    }

    &.mobile {
      margin-bottom: 50px;
      padding-bottom: 60px;
      .law-firm__detail__content {
        padding-right: 0;
        .base-grid, &__edit, &__notes-wrapper {
          grid-template-columns: 1fr;
        }
      }
      .law-firm-view__content__header {
        margin: 5px -20px 10px;
        padding: 0 20px;
        h2 {
          margin-left: 0;
        }
        .add-todo-btn {
          margin-left: auto;
          margin-bottom: 10px;
        }
        &__edit-button{
          opacity: 1;
					transform: translateY(0);
          &.desktop {
            display: none;
          }
        }
        .checkbox-height{
          min-height: 40px;
        }
      }
      .law-firm-view__sidebar, .law-firm__detail__sidebar{
        width: unset;

        &:before {
          content: none;
        }
      }
      .section-header {
        margin-top: 40px;
        margin-bottom: 20px;
        border-bottom: 2px dashed #eaeaf1;
        h4 {
          padding-bottom: 5px;
          font-weight: bold;
        }
      }
      .view__sidebar .nav-tabs .nav-item {
        height: 30px;
      }
    }
  }

  &__sidebar {
		background-color: #fff;
		width: 400px;
		height: calc(100vh - 60px);
		overflow: hidden;
		position: relative;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			content: "";
			height: 100%;
			width: 1px;
			background-image: linear-gradient(to bottom, white 1%, #d9dade, white 99%);
		}
  }
}

.law-firm__detail {
  display: block;

  &__content {
    flex: 1;
    padding-right: 20px;

    &__notes-wrapper {
      margin: 30px 0;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      color: var(--text);
      font-size: 11px;
      word-break: normal;
    }

    &__edit-button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 5;
    }

    &__edit {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
    }
  }

  &__sidebar {
	  width: 260px;
  }
}

.view__sidebar {

  .column {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .nav-tabs {
	display: flex;

	.nav-item {
	  flex: 1;
	  height: 50px;

	  &:after {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			content: "";
			height: 100%;
			width: 1px;
			background-image: linear-gradient(to bottom, white 2%, #d9dade, white 98%);
	  }

	  &:last-child:after {
			display: none;
	  }

	  .nav-link {
			padding: 0;
			margin: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 700;
			font-size: 11px;
			color: var(--text);
			border: none;
			position: relative;

		&.active {
		  border: none;
		  color: var(--primary);
		}

    @media (hover: hover) {
      &:hover {
        border: none;
        color: var(--primary-hover);
      }
    }
	  }
	}
  }
}

.vip-badge {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  background-color: black;
  background-image: linear-gradient(to bottom right, #656766, #2a2d2c);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10px;
}

.truthy-value {
  .badge {
		background-color: transparent;
		padding: 0;
		margin: 0;
		line-height: unset;
		font-weight: 700;

	&.badge-danger {
	  color: var(--danger);
	}

	&.badge-success {
	  color: var(--success);
	}
  }
}

.doctor-card-margin-bottom {
	margin-bottom: 10px;
}

///can be kept common for staff and doctor law firm page
.add-btn-right {
	position: absolute;
	right: 0;
  }

.search-btn-mobile{
	display: flex;
  }
