//dashboard panels
.canvas-wrapper {
  position: relative;
  width: 90%; // this is a "hack" to resize chart properly
}

div.dateOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

div.dateOptions * {
  margin-right: 10px;
}

bs-daterangepicker-container {
  padding: 0;
}

bs-datepicker-navigation-view {
  display: flex;
  justify-content: space-around;
}

daterange-picker > div.form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

button.range-button {
  margin-left: 10px;
  margin-bottom: 10px;
}

.range-list {
  display: flex;
  flex-direction: column;
}

.bs-calendar-container[role=application] {
  display: flex;
}

.glyphicon {
  font-family: "Glyphicons Halflings" !important;
  font-size: 12px;
}

.dashboard {
  padding: 10px;
  width: 100%;
  gap: 10px;

  #dashboard-current-month {
    .ibox {
      height: 100%;
      display: flex;
      flex-flow: column;

      .ibox-content {
        flex: 1;
        @extend .flex-fix;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: none;
    grid-gap: 10px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1599px) {
    display: flex;
    flex-direction: column;

    &__columns {
      display: flex;
      gap: 10px;

      &__column {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 10px;
      }
    }
  }
}

.breakdown-content {
  flex-direction: column;
}

@media screen and (min-width: 1600px) {

  .portfolio {
    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__row {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    &__column {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-basis: 50%
    }
  }

  .breakdown-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      height: 450px;
      flex: 1;
    }

    ag-grid-angular {
      max-height: 450px;
      width: 50%;
    }
  }

  .dashboard-round {
    display: flex;
    align-items: center;
    justify-content: end;

    &__toggle {
      display: flex;
      gap: 10px;
    }
  }

  #dashboard-current-month {
    .ibox {
      height: auto;
    }
  }

  #dashboard-breakdowns > .dash-panel,
  #dashboard-charts > .dash-panel,
  #dashboard-deals > .dash-panel,
  .portfolio-wrapper {
    margin: 8px;
    padding: 8px;
  }
}

.dash-panel {
  position: relative;
  background: white;
  color: rgba(0, 0, 0, 0.75);

  .nav.nav-tabs {
    overflow-x: auto;
  }
}

current-month {
  .ibox {
    background: white;
  }
}

current-month .toggle {
  flex-direction: column;
  align-items: flex-end;
  padding: 5px;
  font-weight: bold;

  span {
    margin-bottom: 5px;
  }
}

current-month, .float-e-margins {
  li {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
  }
}

current-month, .tab-content, .float-e-margins {
  .stat-list {
    hr {
      margin: 10px 0;
    }
  }

  .progress-wrap {
    display: flex;
    flex: 1;
    @extend .flex-fix;
    flex-wrap: wrap;
    align-items: center;

    .progress {
      height: 10px;
      flex: 1;
      @extend .flex-fix;
      margin: 0 5px;
      align-items: center;
    }

    .progress-bar {
      background-color: var(--primary);
      height: 100%;
    }

    span {
      flex-basis: 110px;
      font-weight: bold;
    }

    small {
      flex-basis: 100%;
    }
  }

  .progress-data {
    flex-basis: 90px;
    text-align: right;
  }

  .progress-info {
    flex-basis: 100%;
  }
}

current-month .ibox-content {
  .stat-list {
    flex: 1;
    @extend .flex-fix;
  }

  .current-month-days {
    text-align: right;

    table tr td {
      padding-right: 5px;
    }
  }
}

portfolio-performance-chart canvas {
  padding-top: 20px;
  border-radius: 3px;
  margin-top: 5px;

}

.indicator-report .ibox-content {
  padding: 0 10px !important;
  position: relative;

  .container-table {
    margin-bottom: 0;

    th > div {
      display: flex;
    }

    th.actions {
      width: 306px;

      .btn {
        margin-right: 4px;
      }
    }
  }
}

#dashboard-charts {
  board-deals-grid {
    & > div {
      margin-top: 16px;
    }
  }

  ag-grid-angular {
    height: 45vh;
  }

  .dashboard-chart {
    height: 36vh;
  }
}

.top-five-dashboard-chart {
  height: 55%;
}

dashboard {
  width: 100%;
}

.filter-wrapper {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
  align-items: center;

  & > dashboard-filter {
    width: 100%;

    & > ng-select > .ng-select-container > .ng-value-container {
      & > .ng-placeholder {
        margin-top: 5px;
      }

      & .ng-value {
        padding-right: 8px;
      }
    }
  }

  & > span {
    min-width: 85px;
  }

}


.chart-clear-wrapper {
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;

  &--space-between {
    justify-content: space-between;
  }

}
