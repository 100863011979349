.doctor-view {

  &__sidebar {
	background-color: #fff;
	width: 400px;
	height: calc(100vh - 60px);
	overflow: hidden;
	position: relative;

	&:before {
	  position: absolute;
	  top: 0;
	  left: 0;
	  display: block;
	  content: "";
	  height: 100%;
	  width: 1px;
	  background-image: linear-gradient(to bottom, white 1%, #d9dade, white 99%);
	}
  }
}
