$soft-grey: #f1f1f6;

:root {
  --primary: #32c3a6;
  --primary-hover: #2baf95;
  --medical: #51c8e2;
  --mri: #1ea3f7;
  --court: #35af31;
  --text: #73768c;
  --text-light: #999CB4;
  --soft-text: #c3c3da;
  --dark: #36394e;
  --grey: #eaeaf1;
  --soft-grey: #{$soft-grey};
  --dark-grey: #dfdfe8;

  --external: #7e9ace;
  --consult: #9892ff;
  --pending: #FFCC43;
  --approved: #23c6c8;
  --funded: #1ea3f7;
  --settled: #4fd27d;
  --external-portfolio: #73768c;
  --rejected: #ED5565;
  --pre-op: #ffad09;
  --op: #4fd27d;
  --post-op: #9892ff;

  // APP STATUS COLOR
  --success: #4fd27d;
  --info: #23c6c8;
  --info-hover: #1eaaab;
  --warning: #FFCC43;
  --warning-hover: #ffad09;
  --danger: #ED5565;
  --danger-hover: #d54554;

  --base-input-backgroundColor: #f4f5f7;
  --base-spinner-dot-backgroundColor: #32c3a6;

  --info-bg: #d9edf7;
  --info-text: #31708f;
  --brand-primary: darken(#428bca, 6.5%);
  --btn-primary-border: darken(var(--btn-primary-bg), 5%);
}


