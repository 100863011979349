.agile-detail {
  .timestamp {
    flex: 1;
    text-align: right;
    i {
      margin: 0 2px;
    }
    padding-top: 4px;
  }
  .funding-card-section {
    padding: 2px 4px 4px 4px;
    background: var(--success);
    color: white;
  }

  .expanded {
    border-top: 1px solid #ccc;;
  }
}

.funding-card {
  position: relative;

  &.danger {
    background-color: #ffe8e8;
  }

  &.warning {
    background-color: #fffbe8;
  }

  .action-btns button {
    flex-wrap: wrap;
    margin: 4px 4px 0px 0px;
  }
}



whiteboard-card-list {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 2px !important;
  min-width: 220px;
  flex: 1;
}

.wb-top-bar {
  display: flex !important;
  padding: 0 10px;
  //height: 74px;
  height: auto;
  align-items: center;
}

whiteboard-filter {
  flex: 1;
}

.whiteboard-filter > * {
  margin-right: 5px;
  flex: 1;

  input.base-input, select.base-input, ng-select.base-input {
    min-width: unset;
  }
}

div[actions] button {
  margin: 20px 0 0 0;
}

medical-report-modal .ng-select {
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  background: white;
  height: 32px;
}
////////////////

.whiteboard {
  display: flex;
  padding: 0 2px 2px 2px;
  @extend .flex-fix;
  background: white;

  label {
    margin-bottom: 0;
  }
}

.whiteboard-settings {
  align-items: flex-end;
  padding: 10px;
  background: #f3f3f4 !important;
  //settings panel is coded really bad this is my workaround to select it's childs'
  > * {
	  flex: 1 0;
	  margin: 0 5px;
  }
  .input-group, .ng-select-multiple, .ng-select-single {
    border: 1px solid #ccc;;
    border-radius: 4px;
    overflow: hidden;
    background: white;
    > * {
        border: none !important;
      }
    i {
      border-right: 1px solid #ccc;;
    }
  }
  .ng-select-single {
    height: 34px;
    display: flex;
    align-items: center;
    .ng-select-container {
      width: 100%;
      height: 100% !important;
    }

    .ng-input {
      padding-right: 0 !important;
      input {
        box-sizing: border-box;
        height: 100%;
      }
      input:hover, input:focus, input:active {
        border: none;
      }
    }

  }
  label {
    margin-bottom: 0;
  }
  .btn {
    padding: 0;
    width: 34px;
    height: 34px;
    flex-grow: 0;
    flex-basis: 34px;
    justify-content: center;
    margin: 0;
  }
}

.whiteboard-actions {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 0;

  button.btn {
    height: 34px;
    margin: 0 10px 0 0;
  }
}

.card-list-header {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 10px;
  min-height: 74px;
  background: #f3f3f4;
  cursor: pointer;
  h2 {
    margin: 0;
    font-size: 16px;
    flex: 1;
    font-weight: bold;
    color: #16977e !important;
    padding-right: 40px;
  }
}

.card-list-column {
  padding: 0;

  li {
    margin-bottom: 4px;
    z-index: 0;
  }

  .drop-overlay, .progress-overlay {
    color: #ccc;;
    position: absolute;
    min-height: 200px;
    max-height: calc( 100% - 85px);
    top: 83px;
    width: 100%;
    height: 100%;
    font-size: 3em;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    text-align: center;
    display: flex;
    opacity: 0;
    z-index: -1 !important;
    transition: all 150ms linear;
    border: 3px dashed #ccc;;
    border-radius: 5px;
  }

  &.drag-over .drop-overlay {
    display: flex;
    opacity: 0.8;
    z-index: 10 !important;
  }

  .agile-detail {
    padding: 4px 5px 4px 7px;
    transform: unset !important;
    word-wrap: break-word;
    .agile-detail-top-bar {
      position: relative;
      h4 {
        font-size: 15px;
        margin: 0;
        padding-right: 50px;
        word-wrap: break-word;
      }

      .rp-button {
        color: #16977e;
        border: none;
        background: none;
        border-radius: 3px;
        &:hover {
          background: var(--primary);
          color: white;
        }
      }
      .currency {
        font-size: 14px;
        font-weight: 600;
      }
      .card-actions {
        position: absolute;
        top: -2px;
        right: -2px;
      }
    }
    .badges {
      padding-top: 4px;
      flex-wrap: wrap;
    }
    .deal-number{
      justify-content: flex-end;
    }
  }
  span, small, strong, div {
    word-break: break-word;
  }
}

.drag-helper {
  opacity: 0.8;
  border: dashed 3px #ccc;;
  background: #f3f3f4;
  border-radius: 5px;
  color: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.6em;
  padding: 0 10px;
}

///////

.expanded {
  //display: flex;
  //flex-wrap: wrap;
  .btn-group {
    margin-bottom: 4px;
    margin-right: 4px;
    justify-content: space-between;
  }
  .note {
    margin: 0 4px 4px 6px;
    display: flex;
    flex-direction: column;
    border-left: 2px solid var(--primary);

    &__wrapper {
      padding: 4px 4px 4px 8px;
      background: #f3f3f4;
      cursor: text;
      position: relative;
      border-radius: 0 4px 4px 0;
      transition: all 0.4s;

      &__edit{
        position: absolute;
        right: 20px;
        top: 4px;
        opacity: 0;
        cursor: pointer;
        transform: translateY(10px);
        transition: all 0.4s;
        i {
          width: 14px;
          color: var(--primary);
        }
      }

      &__remove{
        position: absolute;
        right: 5px;
        top: 3px;
        opacity: 0;
        cursor: pointer;
        transform: translateY(10px);
        transition: all 0.4s;
        i {
          width: 14px;
          color: var(--primary);
        }
      }

      &__time-ago {
        font-style: italic;
        font-size: 10px;
        margin-bottom: 3px;
      }

      &__buttons {
        display: flex; padding: 4px 0;
      }

      &:hover {
        background-color: darken(#f3f3f4, 5%);
        .note__wrapper__edit {
          opacity: 1;
          transform: translateY(0);
        }
        .note__wrapper__remove {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
  .dropdown-menu li {
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
  }

  .funding-cards-notes form {
    margin: 4px;
  }
  .funding-cards-notes h4 {
    margin: 10px 4px 4px 4px;
  }
  .form-group {
    margin-bottom: 0;
  }
  .funding-cards-notes .btn {
    border-radius: 0 5px 5px 0;
    outline: none;
    &:focus, &:active {
      outline: none;
    }
  }
  textarea {
    background: white;
    min-height: 80px;
    padding: 6px;
    font-size: 12px;
    width: 100%;
    border-radius: 5px 0 0 5px;
  }
}

.expanded-section .btn-group {
  margin: 8px 4px 0 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  label {
    padding-right: 6px;
  }
  .btn {
    white-space: normal;
    border-radius: 5px;
  }
  .btn:focus {
    background: var(--primary);
    border-color: var(--primary);
  }
  .dropdown-menu {
    width: 100%;
    margin-top: 0;
    word-break: unset;
    overflow: hidden;
    > li > a {
      padding: 4px;
      background: white;
      border-bottom: 1px solid #ccc;;
      white-space: normal;
      &:hover {
        background: #f3f3f4;
      }
    }
  }
}

/* LABELS MULTI SELECT BOX */

.label-multi-select {
  margin-left: auto;
  .selection-label {
    align-items: center;
    border-radius: 3px 0 0 3px;
    padding: 1px 3px 1px 3px;
    cursor: pointer;
    color: white;
    font-weight: 600;
    font-size: 10px;
    margin-right: 1px;
  }
  .selection-icon {
    border-radius: 0 3px 3px 0;
    padding: 0px 3px 0px 2px;
    cursor: pointer;
    color: white;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
  }
  .expanded-section .btn {
    flex: 1 0 100%;
    @extend .flex-fix;
  }
  .option {
    align-items: center;
    border-radius: 8px;
    padding: 2px 8px 2px 8px;
  }
  .ng-value {
    border: 0 !important;
  }
}

.whiteboard {
  .selection-color {
    width: 20px;
    height: 20px;
    display: inline-flex;
    border-radius: 4px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 4px 8px;
    > div {
      //justify-content: center;
      align-items: center;
    }
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 8px;
  }
}



//COLLAPSED WHITEBOARD COLUMN

whiteboard-card-list.collapsed {
  min-width: 48px;
  flex:0 1 48px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  overflow: hidden;
  .card-list-header {
    h2 {
      padding-top: 32px;
      padding-right: 0;
    }
    span {
      padding-top: 10px;
    }
  }
}



/* AGILE BOARD */

.sortable-list {
  padding: 10px 0;
}

.agile-list {
  list-style: none;
  margin: 0;
  flex: 1;
  @extend .flex-fix;
}

.agile-list li {
  background: #f3f3f4;
  //border: 1px solid #ccc;;
  margin: 0 0 4px 0;
}

.agile-list li.warning-element {
  border-left: 3px solid var(--warning);
}

.agile-list li.danger-element {
  border-left: 3px solid var(--danger);
}

.agile-list li.info-element {
  border-left: 3px solid var(--success);
}

.agile-list li.success-element {
  border-left: 3px solid var(--primary);
}

whiteboard-funding-statistics {
  margin-left: auto;
  .whiteboard-statistics__wrapper {
    div:first-child:hover {
      cursor: pointer;
    }
    padding-left: 2px; border-left: 2px dotted #999999; width: 62px; font-size: 80%;
    .colorize {
      color: #909090;
    }
  }
}

.card-status {
  &__wrapper {
    margin: 6px 4px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin: 3px 0;
  }

  &__label {
    margin-right: auto;
    font-size: 12px;
  }

  &__select {
    margin-left: auto;
    border-radius: 5px;
    background-color: var(--primary);
    color: #fff;
    border: none;
    font-size: 12px;
    &:hover, &:active, &:focus {
      outline: none;
    }
    option {
      background-color: #fff;
      color: var(--primary);
    }
  }
}

.btn-show-more-notes {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    color : var(--primary-hover);
  }

  &:active {
    box-shadow: unset;
  }
}
