.tw-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.border-b-2	{
  border-width: 0;
  border-bottom-width: 2px;
}

.border-dashed	{
  border-style: dashed;
}

.border-\[\#eaeaf1\] {
  border-color: #eaeaf1;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.gap-1 {
  row-gap: 0.25rem;
  column-gap: 0.25rem;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-2 {
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}

.gap-3 {
  column-gap: 0.75rem;
  row-gap: 0.75rem;
}

.gap-4 {
  row-gap: 1rem;
  column-gap: 1rem;
}

.gap-5 {
  row-gap: 1.25rem;
  column-gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-fit {
  max-width: fit-content;
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, max-content));
}

.col-start-2 {
  grid-column-start: 2;
}

.row-start-2 {
  grid-row-start: 2;
}

.transition-none {
  transition: none !important;
}

.m-0 {
  margin: 0;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 8px !important;
}

.opacity-20 {
  opacity: 0.2;
}

.group:hover {

  .group-hover\:opacity-100 {
    opacity: 1;
  }

}

.group:focus-within {

  .group-focus-within\:font-bold {
    font-weight: 700;
  }
}

.font-bold {
  font-weight: 700;
}

.min-w-40 {
  min-width: 160px;
}

.break-all {
  word-break: break-all;
}

.sticky {
  position: sticky !important;
}

.top-1 {
  top: 0.25rem;
}

.h-full {
  height: 100%;
}

.h-10 {
  height: 2.5rem; /* 40px */
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.p-0 {
  padding: 0;
}

.relative {
  position: relative;
}

.bg-gray-100 {
  background-color: var(--soft-grey)
}

.shadow-none {
  box-shadow: 0 0 #0000;
}

.font-bold {
  font-weight: 700;
}

.text-neutral-500 {
  color: var(--text);
}

.hover\:cursor-pointer {
  cursor: pointer;
}

.w-max {
  width: max-content;
}

.w-4 {
  width: 1rem;
}

.h-4 {
  height: 1rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.h-6 {
  height: 1.5rem;
}

.min-w-max {
  min-width: max-content;
}

.mb-6	{
  margin-bottom: 1.5rem;
}

.flex-none {
  flex: none;
}

.grid-cols-\[max-content_max-content_max-content\] {
  grid-template-columns: repeat(5, minmax(0, max-content));
}

.w-1\/2	{
  width: 50%;
}

.min-w-52	{
  min-width: 13rem;
}

.grid {
  display: grid;
}

.grid-cols-12	{
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-6	{
  grid-column: span 6 / span 6;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-2	{
  grid-column: span 2 / span 2;
}

.col-span-1	{
  grid-column: span 1 / span 1;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-5 {
  top: 1.25rem;
}

.z-10 {
  z-index: 10;
}

.mr-12 {
  margin-right: 3rem;
}

.basis-1\/2 {
  flex-basis: 50%;
}
