.doctor-addresses-widget {
  position: relative;

  &__toggle {
	color: var(--doctor-address-button-color, var(--primary));
    cursor: pointer;

    i, fa-icon {
      transition: all 0.4s;
      &.active {
        transform: rotate(180deg);
      }
    }
  }

  &__all {
    position: absolute;
    width: 400px;
    height: auto;
    max-height: 400px;
    background-color: #fff;
    min-height: 150px;
    border-radius: 5px;
    top: calc(100% + 6px);
    box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
    z-index: 5;
    overflow-y: auto;
    overflow-x: hidden;
    color: black;

    &__row {
      padding: 14px;
      border-bottom: 1px solid #eee;
      position: relative;
    }
  }

  &__main-marker {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 36px solid var(--primary);
    border-left: 36px solid transparent;

    &:after {
      content: 'Active';
      font-size: 9px;
      position: absolute;
      top: -31px;
      left: -23px;
      transform: rotate(45deg);
      color: #fff;
    }
  }

  address {
    margin-bottom: 0;
  }

  &__contact-info {
    margin-top: 5px;
  }

  &__address-title {
    color: var(--primary);
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }

  &__value-row {
    display: flex;
  }

  &__label {
    font-weight: 600;
    width: 80px;
  }

  &__value {
    flex: 1;
  }
}
