.base-checkbox__wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0 0 0 28px;
  min-height: 21px;
  margin: 0;
  cursor: pointer;
  font-size: 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    left: 0;
    height: 21px;
    width: 21px;
    display: block;
    background-color: var(--grey);
    border-radius: 2px;
    transition: all 0.2s;

    &:after {
      position: absolute;
      top: 3px;
      left: 5px;
      display: block;
      width: 12px;
      height: 12px;
      content: "\f00c";
      font-family: "FontAwesome", sans-serif;
      color: #fff;
      transform: scale(0.5);
      opacity: 0;
      transition: all 0.3s;
    }
  }

  &__text {
    font-size: 12px;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover &__input ~ &__checkmark {
    background-color: var(--soft-grey);
  }

  & &__input:checked ~ &__checkmark, &__checkmark.checked {
    background-color: var(--primary);

    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

