medical-detail-content {
  appointment-card {
    margin-left: 5px;
  }
}

.appointment {
  width: calc(100% + 5px);
  display: flex;
  align-items: stretch;
  font-size: 10px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
  line-height: 16px;
  position: relative;
  transform: translateX(-5px);
  transition: all 0.4s;

  &__actions {
    position: absolute;
    display: flex;
    align-items: center;
    top: 3px;
    right: 4px;
    opacity: 0;
    transform: translateY(4px);
    z-index: 2;
    transition: all 0.4s;

    fa-icon {
      font-size: 10px;
      color: var(--text);
      cursor: pointer;
      margin-left: 4px;


      &:hover {
        color: var(--primary);
      }
    }
  }

  &:hover {
    background-color: #f1f1f6;

    .appointment__actions {
      opacity: 1;
      transform: translate(0);
    }
  }

  &__left {
    display: block;
  }

  &__right {
		margin-top: 12px;
    margin-left: auto;
    display: flex;
    flex: none;
  }

  &__procedure {
    color: var(--text);
    padding-right: 10px;
  }

  &__doctor {
    color: var(--primary);
    font-weight: 700;

    a, a:hover, a:focus, a:active {
      color: var(--primary);
      padding-right: 5px;
      cursor: pointer;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: var(--text);
    font-weight: 400;
    opacity: 0.8;

		.telemed {
			color: var(--primary);
			font-size: 12px;
			font-weight: 600;
		}
  }

  &__form {
    margin-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 2px dashed #eaeaf1;
    position: relative;

    .loading-panel__message__text {
      font-weight: 400;
    }

		.disabled {
			cursor: not-allowed;
			pointer-events: none;
			opacity: 0.5;
		}
  }

  &__confirmation-dialog {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--text);

    span {
      cursor: pointer;
      width: 30px;
      display: flex;
      justify-content: center;

      &:first-child {
        color: var(--primary);
      }
    }
  }

  &__datepicker-wrapper {
    position: absolute;
    bottom: 40px;
    margin: 0 auto;
    width: 200px;
    height: 308px;
    background-color: #fff;
    z-index: 10;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  }

  &__doctor__address {
    padding-left: 3px;
    margin-left: 1px;
    border-left: 1px solid #f1f1f6;
    color: #b6b9d3;
  }

  &__doctor__phone {
    font-style: italic;
    font-weight: 400;
  }
}


.whiteboard-appointments {
  padding: 0 5px;
  margin: 10px 0;

  &__wrapper {
    display: flex;
    align-items: center;

    i {
      margin-left: auto;
      cursor: pointer;
      color: var(--primary);
    }
  }

  .appointment {
    width: calc(100% + 10px);
  }

  .appointment__doctor__address {
    border-color: rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.75);
  }

  .appointment__procedure {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
  }
}

// only because Angularjs
.add-appointment-button {
  position: absolute;
  right: 5px;
  top: 5px;
}
