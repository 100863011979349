documents-queue-contract, documents-queue-file {
  margin-bottom: 8px;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
}

.document__queue {
  padding: 0 15px;

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    font-size: 10px;
    color: #686a7e;
  }

  &__title {
    color: #686a7e;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 10px;
    padding: 15px 5px 0;
    border-top: 2px dashed #efefef;
  }

  &__wrapper {
    background-color: #f1f1f6;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
  }

  &__row {
    width: 100%;
    min-height: 40px;
    background-color: #fff;
    border-radius: 5px;
    color: #686a7e;
    margin-bottom: 8px;
    box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.05), 0 2px 4px 0 rgba(93, 100, 148, 0.1);

    &:last-child {
      margin-bottom: 0;
    }

    &__category {
      padding: 0 8px;
      position: relative;
      border-right: 1px solid #efefef;
      font-size: 12px;

      .fa {
        position: absolute;
        right: 12px;
        top: 5px;
      }
    }

    &__category--file {
      width: 125px;
      min-height: 22px;
      padding-right: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &__name {
      display: flex;
      align-items: center;

      .file-icon {
        margin-left: 8px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        fill: #686a7e;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .template-icon {
        margin-left: 10px;
        width: 14px;
        height: 14px;
        margin-right: 5px;
        fill: #686a7e;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-left: auto;

      .remove-icon svg {
        fill: #e85353;
      }
    }
  }

  &__docusign {
    padding: 15px;
    border-top: 1px solid #eee;
    max-height: 0;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;

    &__collapse {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      &__button {
        cursor: pointer;
        width: 50px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        transition: all 0.4s;

        .fa, fa-icon {
          color: var(--primary);
          transition: all 0.4s;

          &.expand {
            transform: rotate(180deg);
          }
        }

        &:hover {
          background-color: #f1f1f6;
        }
      }
    }

    &.expand {
      max-height: 500px;
      overflow: unset;
    }

    &__title {
      height: 30px;
      margin: 10px 0 15px;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: var(--primary);
    }

    &__sent-to {
      border-radius: 5px;
      padding: 15px 10px 10px;
      border: 1px dashed #eee;
      position: relative;
      font-size: 12px;
      margin-bottom: 20px;

      &__title {
        position: absolute;
        background-color: #fff;
        padding: 0 5px;
        font-weight: 600;
        color: #686a7e;
        top: -10px;
        left: 10px;
        font-size: 12px;
      }

      &__row {
        padding-left: 5px;
        display: flex;
        align-items: center;
        min-height: 30px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background-color: #f1f1f6;
          border-radius: 5px;
        }

        &.edit {
          min-height: 50px;

          &.big {
            min-height: 70px;
          }

          &:hover {
            background-color: unset;
          }
        }

        &__value {
          &__user {
            margin: 0 6px 0 0;
          }

          &__envelope {
            margin: 0 6px 0 10px;
          }
        }

        .checkbox__wrapper {
          width: 100px;
        }
      }

      .checkbox__wrapper {
        font-size: 12px;
      }
    }

    &__additional-recipients {
      border-radius: 5px;
      padding: 15px 10px;
      border: 1px dashed #eee;
      position: relative;
      font-size: 12px;

      &__add-btn {
        width: 21px;
        height: 21px;
        border: none;
        border-radius: 5px;
        background-color: var(--primary);
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        margin-left: 5px;

        span {
          transition: all 0.4s;
        }

        &.active {
          span {
            padding-right: 0.3px;
            transform: rotate(315deg);
          }
        }

        &__wrapper {
          position: relative;
          display: inline-flex;
        }
      }

      &__title {
        position: absolute;
        background-color: #fff;
        padding: 0 5px;
        font-weight: 600;
        color: #686a7e;
        top: -10px;
        left: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;
      }

      &__row {
        height: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 5px;
        padding-left: 8px;
        transition: all 0.4s;

        &:hover {
          background-color: #f1f1f6;

          .row__action {
            opacity: 1;
            transform: scale(1);
          }
        }

        .row__action {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          margin-left: auto;
          cursor: pointer;
          justify-content: center;
          opacity: 0;
          transform: scale(0.8);
          transition: all 0.4s;
        }

        svg-icon, svg {
          height: 20px;
          width: 20px;
          fill: #686a7e;
        }
      }

      &__form {
        padding-top: 10px;
        margin-top: 5px;
        border-top: 1px solid #eee;
      }
    }
  }
}
