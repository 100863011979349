.visibility-wrapper {
  display: block;
  padding: 0 10px;
  border: 1px solid #dfe1e6;
  background-color: var(--base-input-backgroundColor);
  border-radius: 5px;
  transition: all 0.4s;

  &:focus-within {
    box-shadow: 0 0 0 1px var(--primary-hover) inset;
    border: 1px solid var(--primary-hover);

    &:has(input.ng-invalid:focus:not(:placeholder-shown)), &:has(input.error:focus:not(:placeholder-shown)) {
      box-shadow: 0 0 0 1px var(--danger) inset;
      border-color: var(--danger);
    }
  }

  input.base-input {
    border-radius: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    &:active, &:focus, &.active, &.error, &.error:focus {
      box-shadow: none;
      border: none;
      background-color: transparent;
      color: unset;
    }

    &::placeholder {
      color: transparent;
    }
  }
}
