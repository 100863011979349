.security-view {
  display: flex;
  width: 100%;
  background-color: white;

  &__tabs {
    width: 100%;
    height: 100%;
    position: relative;

     .nav-tabs {
       width: 100%;
       height: 50px;
       border-bottom: 1px solid #e0e0e0;
       display: flex;
       align-items: stretch;
       position: relative;

       .nav-item {
         font-weight: 600;
         width: 170px;
         margin: 0;
         padding: 0;
         display: inline-flex;
         align-items: center;
         a {
           width: 100%;
           height: 100%;
           display: flex;
           align-items: center;
           justify-content: center;
           margin: 0;
           border: none;
           color: #868686;
           transition: color 0.4s;
           &:after {
             content: "";
             position: absolute;
             bottom: 0px;
             left: 0;
             width: 170px;
             height: 0px;
             background-color: var(--primary);
             border-radius: 4px;
           }
           &:hover {
             border: none;
             color: var(--primary);
           }
					 &:active, &:focus {
						 background-color: #fff;
					 }
           &.active {
             border: none;
             color: var(--primary);
             position: relative;
             &:after {
               height: 3px;
             }
           }
         }
       }
     }

    .tab-content {
      overflow: unset;
    }

      &__item {
        margin: 0;
        padding: 0;
        overflow: unset;

        &__content {
          width: 100%;
        }
      }
  }

  &__search {
    width: 350px;
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    input {
      background-color: #f3f3f4;
      width: 100%;
      max-width: 350px;
      border-radius: 8px;
      border: none;
      box-shadow: 0 1px 1px 0px rgba(100, 100, 100, 0.1) inset;

      &:active, &:focus {
        box-shadow: 0 1px 1px 0 rgba(100, 100, 100, 0.1) inset;
      }
    }
  }

  &__section {
    margin-top: 2px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      .security-view__section__table {
        box-shadow: none;
      }
    }
    &__title {
      height: 50px;
      background-color: #f1f1f6;
      display: flex;
      align-items: center;
      padding: 0 20px 0 40px;
      font-weight: 600;
      font-size: 14px;
      color: #71767b;
      position: relative;
      span {
        padding: 5px 10px;
        border-radius: 8px;
        color: var(--primary);
        font-weight: 700;
        font-size: 12px;
      }
      i, fa-icon {
        position: absolute;
        top: 18px;
        left: 14px;
        color: #71767b;
        transition: all 0.4s;
        &.open {
          margin-top: 2px;
          transform: rotate(180deg);
        }
      }
    }
    &__table {
      background-color: #eee;
      box-shadow: 0 0 0 1px rgba(255,255,255,1) inset;
      &__no-fields {
        background-color: white;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        color: var(--primary);
      }
      &__header {
        display: grid;
        gap: 1px;
        height: 50px;
        grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
        margin-bottom: 1px;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid var(--primary);
        }
        &__cell {
          color: var(--primary);
          background-color: white;
          font-weight: 700;
          text-transform: capitalize;
        }
      }
      &__row {
        display: grid;
        gap: 1px;
        height: 50px;
        grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
        &:nth-child(odd) {
          .security-view__section__table__row__cell {
            background-color: #fafafa;
          }
        }
        &__cell {
          display: flex;
          background-color: white;
          align-items: center;
          justify-content: center;
          margin-bottom: 1px;
          position: relative;
          &.field-name {
            font-weight: 700;
            padding: 0 20px;
            justify-content: flex-end;
            text-align: right;
          }
        }
      }
    }
  }
}
