input:not([type="checkbox"]).base-input, select.base-input, ng-select.base-input, div.base-input {
  background-color: var(--base-input-backgroundColor);
  border: 1px solid #dfe1e6;
  display: flex;
  width: 100%;
  padding: 0 10px 0 10px;
  min-height: 40px;
  color: var(--text);
  border-radius: 5px;
  font-size: 12px;
  align-items: center;
  outline: none;
  font-weight: 400;
  transition: all 0.4s;

  &::-webkit-input-placeholder {
    font-style: italic;
    color: lighten(#73768c, 10%);
    font-weight: 400;
  }

  &.responsive-height {
    height: unset;
    min-height: 40px;
  }

  &:active, &:focus, &.active {
    box-shadow: 0 0 0 1px var(--primary-hover) inset;
    border: 1px solid var(--primary-hover);
    background-color: #fff;
  }

  &.auto-height {
    min-height: 40px;
    height: auto;
  }

  &.invalid, &.error {
    border-color: var(--danger);

    &:active, &:focus {
      box-shadow: 0 0 0 1px var(--danger) inset;
      border: 1px solid var(--danger);
    }
  }

	&.disabled {
		pointer-events:none;
		cursor: not-allowed;
		opacity: 0.5;
	}
}

textarea.base-input, .ga-note textarea {
  background-color: #f4f5f7;
  border: 1px solid #dfe1e6;
  display: flex;
  width: 100%;
  min-width: 200px;
  min-height: 100px;
  padding: 10px 10px;
  color: var(--text);
  border-radius: 5px;
  font-size: 12px;
  outline: none;
  resize: none;
  transition: all 0.4s;
  font-weight: 400;

  .no-min-width & {
    min-width: auto;
  }

  &--font-small, &.ga-small {
    font-size: 11px;
    color: var(--text);
    font-weight: 400;
  }

  &::-webkit-input-placeholder {
    font-style: italic;
    color: lighten(#73768c, 10%);
    font-weight: 400;
  }

  &:active, &:focus {
    box-shadow: 0 0 0 1px var(--primary-hover) inset;
    border: 1px solid var(--primary-hover);
    background-color: #fff;
  }
}

.base-input__wrapper {
  margin: 5px 0;
  position: relative;

  &.error {
    .base-label span {
      opacity: 1;
      color: var(--danger);
      font-weight: 700;
    }
  }
}

.base-label {
  color: var(--text);
  font-size: 12px;
  display: flex;
  font-weight: 700;

  &--margin-bottom {
    margin-bottom: 5px;
  }

  .required, .validation-label {
    margin-left: auto;
    font-size: 10px;
    opacity: 0.8;
    font-weight: 500;
    color: var(--grey-dark);
    margin-right: 5px;

		&.invalid {
			color: var(--danger);
			font-weight: 700;
		}
  }

  .required:last-of-type {
    margin-right: 0;
  }

  &__with-filter {
    justify-content: space-between;
  }
}

// fix angular ng-select with base-input styling
ng-select.base-input {
  .ng-select-container {
    position: relative;
    overflow: unset;
    box-shadow: none !important;

    .ng-value-container {
      height: 100%;
      padding: 0;

      .ng-placeholder {
        font-style: italic;
        color: lighten(#73768c, 10%);
        font-weight: 400;
      }

      .ng-value {
        color: var(--text);
        font-size: 12px;
        font-weight: 400;
      }

      .ng-input {
        padding-right: 30px !important;
        padding-left: 10px;
      }

      .ng-input > input {
        height: 100%;
        font-size: 12px;
        color: var(--text);
      }
    }

    .ng-spinner-loader {
      right: 0px;
    }
  }

  &.ng-select-multiple .ng-input {
    padding-left: 0 !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.disabled {
    opacity: 0.7;
    font-style: italic;
    color: var(--text);
    font-weight: 400;
  }
}

.base-input .ng-select-container {
  box-shadow: none !important;
}

.error-msg {
  color: var(--danger);
  font-size: 9px;
  font-weight: 700;
}

.fixed-width-input {
  ng-select.base-input {
    max-width: 220px !important;
    min-width: unset;
    padding-right: 15px;
  }
}

ng-select.base-input.auto-height-input {
  height: auto;
}

.ng-dropdown-panel-items {
  min-height: 3.25rem;
}
