.doctor-labels__wrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 114px;
  overflow-y: auto;
  padding-bottom: 10px;
}

.doctor-label {
  margin: 3px;
  font-size: 11px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 2.2rem;
  padding: 0 0 0 0.5rem;
  box-shadow: 0 5px 12px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  border-radius: 5px;
  background-image: linear-gradient(#fff,#eeeeef);

  a {
	align-self: center;
	transition: all 0.3s;
	&:hover {
	  color: var(--medical);
	}
  }

  .btn-primary {
	align-self: center;
	height: 100%;
	background-color: transparent;
	padding: 0 6px 0 3px;
	color: var(--primary);
	margin: 0;
	display: flex;
	align-items: center;
	font-size: 11px;
	border: none;
	border-left: 1px solid #d6d7da;
	transition: all 0.3s;

	&:hover {
	  color: white;
	  border-radius: 0 5px 5px 0;
	}
  }
}



