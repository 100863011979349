/* You can add global styles to this file, and also import other style files */
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

@import "styles/pages/applicant-detail";
@import "styles/pages/applicant-form";
@import "styles/pages/servicing";
@import "styles/pages/dash";
@import "styles/pages/whiteboard-card";
@import "styles/components/smart-search";
@import "styles/components/tables";
@import "styles/components/tabs";

@import "styles/components/modal";
@import "styles/pages/lien-letters";
@import "styles/components/overlayPanel";
@import "styles/components/pageSubNav";
@import "styles/components/dropdown";
@import "styles/components/popover";
@import "styles/components/rpForms";
@import "styles/components/grid";
@import "styles/security/security";
@import "styles/pages/applicants";
@import "styles/pages/attorneyDetail";
@import "styles/pages/attorneyDetail";
@import "styles/pages/reporting";
@import "styles/pages/lawFirm";
@import "styles/pages/whiteboard";
@import "styles/pages/medicalFacilities/detail";
@import "styles/new/views/payoff-quote-view";
@import "styles/new/views/create-loa-document";

@import "bootstrap/dist/css/bootstrap";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "ngx-bootstrap/datepicker/bs-datepicker.css";

@import "../node_modules/@angular/cdk/overlay";
@import '@angular/cdk/overlay-prebuilt.css';
@include cdk-overlay();
@import "styles/colors";
@import "styles/media-query";
@import "styles/animate";

@import "styles/typography";
@import "styles/custom";
@import "styles/components/layout";
@import "styles/tailwind";

/*************************************/
/************* New CSS ***************/
/*************************************/
// font
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// ag grid
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";

// components
@import "styles/new/components/ag-server-side-status-bar";
@import "styles/new/components/confirmation-panel";
@import "styles/new/components/loading-panel";
@import "styles/new/components/spinner";
@import "styles/new/components/modal";
@import "styles/new/components/doctor-label";
@import "styles/new/components/toggle-switch";
@import "styles/new/components/permission-editor";
@import "styles/new/components/base-checkbox";
@import "styles/new/components/todo-widget";
@import "styles/new/components/assignee-list";
@import "styles/new/components/todo-card";
@import "styles/new/components/todo-card-comments";
@import "styles/new/components/user-filter";
@import "styles/new/components/multi-select";
@import "styles/new/components/animations";
@import "styles/new/components/association-todo-list";
@import "styles/new/components/agenda-detail";
@import "styles/new/components/select-list";
@import "styles/new/components/salesboard-detail";
@import "styles/new/components/base-input";
@import "styles/new/components/base-button";
@import "styles/new/components/bs-datepicker";
@import "styles/new/components/tier-rating";
@import "styles/new/components/base-note";
@import "styles/new/components/sidebar-section";
@import "styles/new/components/base-flex";
@import "styles/new/components/appointment";
@import "styles/new/components/base-card";
@import "styles/new/components/doctor-address-widget";
@import "styles/new/components/base-popup";
@import "styles/new/components/base-table";
@import "styles/new/components/secondary-menu";
@import "styles/new/components/history-log";
@import "styles/new/components/timeline";
@import "styles/new/components/base-grid";
@import "styles/new/components/base-icon";
@import "styles/new/components/base-badge.scss";
@import "styles/new/components/base-info-box.scss";
@import "styles/new/components/contact-card.scss";
@import "styles/new/components/radiobutton.scss";
@import "styles/new/components/applicant-documents-list.scss";
@import "styles/new/components/applicant-documents-uploader.scss";
@import "styles/new/components/applicant-documents-queue-item.scss";
@import "styles/new/components/label.scss";
@import "styles/new/components/modal-create-contract.scss";
@import "styles/new/components/table.scss";
@import "styles/new/components/row-form.scss";
@import "styles/new/components/base-radio.scss";
@import "styles/new/components/base-form.scss";
@import "styles/new/components/custom-tooltips.scss";
@import "styles/new/components/ga-copy.directive.scss";
@import "styles/new/components/reachout-target-dropdown";
@import "styles/new/components/additional-law-firm-card";
@import "styles/new/components/deal-info";
@import "styles/new/components/board-card-detail";
@import "styles/new/components/board-card";
@import "styles/new/components/ag-grid";
@import "styles/new/components/password-visibility";

//views
@import "styles/new/views/security-view";
@import "styles/new/views/todo-whiteboard";
@import "styles/new/views/law-firm-view";
@import "styles/new/views/doctor-view";
@import "styles/new/views/medical-facility-view";
@import "styles/new/views/login-view";
@import "styles/new/views/settings-view";
