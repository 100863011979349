.history-log {
  &--new {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
  }

  &__header {
	padding: 10px 15px;
  }

  &__content {
	padding: 10px 15px 20px;
	flex: 1 1 1px;
	display: flex;
	flex-direction: column;

	&__pool {
	  padding: 15px;
	  border-radius: 5px;
	  background-color: #f1f1f6;
	  display: grid;
	  grid-gap: 15px;
	  flex: 1 1 1px;
	  grid-auto-rows: max-content;
	  overflow-y: scroll;
	  position: relative;

	  &__shadow-top {
		position: absolute;
		z-index: 2;
		width: calc(100% - 36px);
		height: 1px;
		top: 12px;
		left: 18px;
		box-shadow: 0 -1px 3px 0 rgba(100, 100, 100, 0.3);
	  }

	  &__shadow-bottom {
		position: absolute;
		z-index: 2;
		width: calc(100% - 36px);
		height: 1px;
		bottom: 19px;
		left: 18px;
		box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.2);
	  }
	}
  }


  .new-log-item {
	background-color: #fff;
	border-radius: 10px;
	font-size: 11px;
	color: var(--text);
	box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.05), 0 1px 9px 0 rgba(93, 100, 148, 0.05);
	cursor: pointer;
	overflow: hidden;

	&__header {
	  display: flex;
	  flex-direction: column;
	  padding: 10px 10px 10px 20px;
	  min-height: 90px;
	  position: relative;

	  &__mark {
		position: absolute;
		top: 10px;
		left: 0;
		width: 4px;
		height: 20px;
		border-radius: 8px;
		border: none !important;

		&.update {
		  background-color: #0e9aef;
		}

		&.insert {
		  background-color: var(--success);
		}

		&.delete {
		  background-color: var(--danger);
		}
	  }

	  &__title {
		  font-weight: 700;
	  }

	  &__fields-count {
		margin-left: auto;
		font-weight: 600;
		color: var(--primary);
	  }

	  &__user-info {
		margin-top: 5px;
		font-size: 10px;
		opacity: 0.7;
	  }

	  &__fields {
		flex: 1 1 1px;
	  }

	  &__remaining-fields {
		font-size: 10px;
		opacity: 0.9;
	  }
	}
  }
}

.mobile .history-log {
  &__header, &__content {
    padding: 10px 0;
  }
}
