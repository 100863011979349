.base-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.05), 0 1px 9px 0 rgba(93, 100, 148, 0.05);
  font-size: 12px;
  color: var(--text);
  border: 1px solid #edf5fb;
  position: relative;
  overflow: hidden;

  &--contact-edit, &--attorney-edit {
    ga-form-field:not(:last-of-type) {
      padding-bottom: 8px;
    }
  }

  &--contact-edit {
    min-height: 840px;
  }

  &--attorney-edit {
    min-height: 760px;
  }

  &--doctor-edit {
    min-height: 295px;
  }

  &--law-firm-association-edit {
    min-height: 384px;
  }

  &__header {
    padding: 15px;
    border-bottom: 2px dashed #ecf2f9;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;

    &__title {
      display: inline-flex;
      align-items: center;
    }

    &__actions {
      margin-left: auto;
      display: flex;
    }
  }

  &__section {

    &__field {
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 1px solid #ecf2f9;
      padding: 15px;

      &.field--icon {
        padding-left: 40px;
      }

      &__icon {
        color: var(--primary);
        position: absolute;
        left: 13px;
        font-size: 13px;
      }

      &__label {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 2px;
        min-width: 100px;
      }

      &__value {
        font-size: 11px;
      }

      &__values {
        font-size: 11px;
        display: flex;
        flex-wrap: wrap;

        div {
          margin: 0 3px 3px 0;
        }
      }
    }
  }

  &__edit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 20px;

    &__actions {
      justify-content: flex-end;
      margin-top: 15px;
    }
  }

  &__content {
    font-size: 12px;
    color: var(--text);
    padding: 15px;
    border-bottom: 1px solid #ecf2f9;

    &__row {
      display: flex;
      margin-bottom: 3px;
    }

    &__label {
      width: 100px;
      font-weight: 700;
    }

    ga-value {
      flex: 1;
    }
  }

  &__collection-item {
    padding: 10px;
    border-radius: 10px;
    background-color: #fafafa;
    border: 1px solid var(--grey);
  }
}

.label-description {
  font-weight: 400;
  font-size: 12px;
}
