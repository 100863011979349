.deal-info {
  .deal-info__widget svg-icon.danger-color svg {
    fill: var(--danger);
  }

  .editor {
    .ng-select {
      min-width: 25rem;
    }
  }

  .editor-wrapper:not(:last-child) {
    margin-bottom: 8px;
  }

  .procedure-wrapper {
    display: flex;
    flex-direction: column;
  }
}
