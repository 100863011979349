.popup {
  width: 400px;
  height: auto;
  background-color: #fff;
  min-height: 150px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  z-index: 101;
  position: absolute;
  top: 0;
  max-width: 100%;

  &__title {
    font-weight: 700;
    color: var(--text);
    margin-bottom: 5px;
    font-size: 13px;
  }

  &__wrapper {
		position: relative;
  }

  &__link {
    margin-top: 5px;
    font-weight: 600;
	color: var(--primary);
  }

  &.left {
	right: calc(100% + 15px);
    transform: translateY(-50%);

    .popup__triangle {
      right: -9px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 9px solid #fff;
    }
  }

  &.right {
    right: calc(100% + 15px);

    .popup__triangle {
      left: -9px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 9px solid #fff;
    }
  }

  &.top {
    bottom: calc(100% + 15px);

    .popup__triangle {
      top: 9px;
      right: 0;
      left: 0;
      margin: 0 auto;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #fff;
    }
  }

  &.bottom {
    top: calc(100% + 10px);
    left: -8px;

    .popup__triangle {
      top: -9px;
      left: 35px;
      margin: 0 auto;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #fff;
    }
  }

  &__triangle {
    position: absolute;
    width: 0;
    height: 0;
  }

}
