.new-timeline {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__header {
    padding: 15px 15px 0 15px;

    .base-button--round {
      margin-left: auto;
    }
  }

  &__floating-button {
    position: absolute;
    bottom: 25px;
    right: 30px;
    z-index: 5;
    transition: 0.4s all;

    &:hover {
      background-color: var(--primary-hover);
    }
  }

  &__content {
    padding: 10px 15px 15px;
    flex: 1 1 1px;
    display: flex;
    flex-direction: column;

    &__pool {
      padding: 15px;
      border-radius: 5px;
      background-color: #f1f1f6;
      display: grid;
      grid-gap: 15px;
      flex: 1 1 1px;
      grid-auto-rows: max-content;
      overflow-y: scroll;
      position: relative;

      &__shadow-top {
        position: absolute;
        z-index: 2;
        width: calc(100% - 36px);
        height: 1px;
        top: 12px;
        left: 18px;
        box-shadow: 0 -1px 3px 0 rgba(100, 100, 100, 0.3);
      }

      &__shadow-bottom {
        position: absolute;
        z-index: 2;
        width: calc(100% - 36px);
        height: 1px;
        bottom: 14px;
        left: 18px;
        box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__note {
    position: relative;
    display: flex;
    padding-left: 45px;

    &:before {
      content: '';
      width: 2px;
      height: calc(100% + 16px);
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
      background-color: #e0e0f1;
      opacity: 0.8;
    }

    &__last:before {
      background-color: transparent;
    }

    &:last-child:before {
      background-color: transparent;
    }

    &__wrapper {
      z-index: 1;

      &__icon {
        top: 0;
        left: 0;
        width: 40px;
        margin-left: -44px;
        margin-bottom: 10px;
        height: 40px;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        z-index: 1;
        align-items: center;
        justify-content: center;
        box-shadow: 0 5px 15px 0 rgba(209, 211, 228, 0.15), 0 1px 9px 0 rgba(177, 180, 206, 0.15);

        svg, svg-icon {
          max-width: 20px;
          max-height: 20px;
        }

        i, fa-icon {
          color: var(--primary);
        }
      }
    }

    &__date {
      position: absolute;
      top: 40px;
      left: 0;
      background-color: var(--primary);
      font-size: 10px;
      text-align: center;
      width: 40px;
      color: #fff;
      border-radius: 0 0 10px 10px;
    }

    &__content {
      background-color: #fff;
      margin-left: 5px;
      border-radius: 10px;
      min-height: 40px;
      min-width: 285px;
      width: 100%;
      box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.05), 0 1px 9px 0 rgba(93, 100, 148, 0.05);
      position: relative;
      padding: 20px;
      color: var(--text);
      overflow: hidden;
      transition: all 0.3s;

      &.edit {
        min-height: 375px;
      }

      &.bg-blue {
        background-color: #9feafb;
      }

      &.bg-orange {
        background-color: #ffeac5;
      }

      &.bg-pink {
        background-color: rgb(255, 205, 222);
      }

      &.bg-light-purple {
        background-color: rgb(227, 201, 238);
      }

      &:hover .base-note__actions {
        opacity: 1;
        transform: translateY(0);
      }

      &__subject {
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 8px;
      }

      &__comment {
        font-size: 12px;
        color: var(--text);
        opacity: 0.9;
        text-align: left;

        ga-default-text-renderer {
          word-break: normal;
        }

        p {

          i {
            font-size: 14px;
          }

          i::before {
            padding-top: 6px;
          }
        }
      }

      &__info {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        &__created-by {

          &__date {
            font-size: 9px;
            opacity: 0.8;
            margin-top: 3px;
          }

          &__user {
            font-size: 9px;
            font-weight: 600;
          }
        }

        &__date-to-follow-up {

          &__title {
            font-size: 9px;
            font-weight: 600;
          }

          &__date {
            font-size: 9px;
            opacity: 0.8;
            margin-top: 3px;
          }
        }
      }

      &__edit {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.9);

        form {
          height: 100%;
          display: flex;
          flex-direction: column;

          .ga-textarea {
            flex: 1;
            border: none;
            margin: 0;
            padding: 0;

            ga-editor {
              ga-textarea-editor {
                display: block;

                textarea {
                  min-height: 130px;
                  background-color: #f4f5f7;
                  border: 1px solid #dfe1e6;
                  font-size: 11px;
                  padding: 5px;
                  margin: 0;
                  border-radius: 5px;

                  &:focus {
                    background-color: #fff;
                    border-color: var(--primary);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile {
  .new-timeline__note__content {
    width: 100%;
  }

  .activity-detail-sidebar, .new-timeline__content {
    padding: 10px 0;
  }

  .new-timeline__content {
    &__pool__shadow-bottom, &__pool__shadow-top {
      display: none;
    }
  }

  .new-timeline__header, todo-list-widget-sort, .user-filter, .todo-widget__filter {
    padding: 10px 0 0;
  }

  .todo-widget__content {
    padding: 0 0 10px;
  }
}

@media only screen and (max-width: 1700px) {
  .new-timeline__content {
    padding: 10px 5px 15px;

    &__pool {
      padding: 10px;
    }
  }

  .new-timeline__note__content {
    padding: 15px;
  }
}

@media only screen and (max-width: 1400px) {
  .new-timeline__floating-button {
    right: 10px;
  }
}
