

.badge {
  background-color: var(--grey);
  color: var(--text);
  font-family: 'Open Sans';
  font-size: 9px;
  font-weight: 700;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;
  text-shadow: none;
}

.badge-round {
  min-width: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0 !important;
  margin-right: 8px;
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-primary, .badge-primary {
  background-color: var(--primary);
  color: #fff;
}

.label-success, .badge-success {
  background-color: var(--success);
  color: #fff;
}

.label-warning, .badge-warning {
  background-color: var(--warning);
  color: #fff;
}

.label-warning-light, .badge-warning-light {
  background-color: var(--warning);
  color: #fff;
}

.label-danger, .badge-danger {
  background-color: var(--danger);
  color: #fff;
}

.label-info, .badge-info {
  background-color: var(--info);
  color: #fff;
}

.label-inverse, .badge-inverse {
  background-color: #262626;
  color: #fff;
}

.label-white, .badge-white {
  background-color: #fff;
  color: #5E5E5E;
}

.label-white, .badge-disable {
  background-color: #2A2E36;
  color: #8B91A0;
}


.badges {
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-end;
}

.badge.primary {
  background: var(--primary) !important;
  color: #fff;
  i {
	font-size: 14px;
  }
}

.badge.danger {
  background: var(--danger) !important;
  color: white;
  i {
	font-size: 14px;
  }
}

.badges .badge {
  display: flex;
  padding: 2px 6px 3px 6px;
  margin-bottom: 2px;
  margin-right: 2px;
  min-width: 30px;
  align-items: center;
  justify-content: center;
  color: white;
  background: #676a6c;
  font-weight: bold;
  .dropdown-menu {
	z-index: 100 !important;
  }
}

.badges.tier .badge {
  min-width: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0 !important;
  margin-right: 8px;
}

.colored-badge {
	height: 20px;
	padding: 0 5px;
	display: inline-flex;
	align-items: center;
	font-size: 11px;
	border-radius: 5px;
	margin-right: 4px;
	margin-bottom: 5px;
}
