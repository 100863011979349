.p-tooltip {
  &.no-arrow {
    .p-tooltip-arrow {
      display: none;
    }
  }

  &.max-width {
    min-width: fit-content;
  }
}
