.action__item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.4s;

  svg {
    width: 20px;
    height: 20px;
    fill: #73768c;
  }

  svg-icon {
    width: 20px;
    height: 20px;

    &.trash-icon {
      height: 30px;
      width: 30px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  &:hover {
    background-color: #f1f1f6;
  }
}

.documents-list {
  position: relative;

  &__header {
    display: flex;
    height: 50px;
    align-items: center;
    padding: 0 15px;
    border-bottom: 1px solid #eee;

    &__actions {
      margin-left: auto;
      display: flex;
    }
  }

  &__column {
    flex: 1;
    margin: 0 10px;
  }

  &__category-wrapper {
    width: 100%;
  }

  &__category {
    padding-bottom: 15px;
    margin-bottom: 5px;
    border-bottom: 2px dashed #efefef;

    //&:last-child {
    //  border-bottom: none;
    //  margin-bottom: 0;
    //  padding-bottom: 0;
    //}

    &__header {
      display: flex;
      align-items: center;
      height: 50px;

      &__title {
        color: #686a7e;
        font-weight: 600;
        margin: 0;

        span {
          opacity: 0.4;
          font-weight: 400;
          font-size: 14px;
        }
      }

      &__actions {
        display: flex;
        margin-left: auto;
      }
    }

    &__files {
      margin-top: 10px;

      &__item {
        padding-left: 5px;
        border-radius: 5px;
        position: relative;
        margin-bottom: 5px;
        transition: background-color 0.3s;

        &__header {
          display: flex;
          align-items: center;
          min-height: 45px;
        }

        &__properties {
          margin-left: 21px;
          color: var(--text);
          opacity: 0.6;
          font-size: 10px;
        }

        .action__item {
          width: 40px;
          height: 34px;

          &:hover {
            background-color: #d9d9e4;
          }
        }

        &:hover {
          background-color: #f1f1f6;

          .documents-list__category__files__item__confirmation {
            background-color: #f1f1f6;
          }
        }

        &__name {
          display: flex;
          align-items: center;

          svg-icon {
            width: 20px;
            height: 20px;
          }

          svg {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            fill: var(--primary);
          }
        }

        &__badge {
          background-color: var(--primary);
          color: #fff;
          margin-left: 5px;

          &.badge-danger {
            background-color: var(--danger);
          }
        }

        &__expand {
          padding: 0 5px;
          color: #686a7e;
          cursor: pointer;
          border-radius: 5px;
          transition: 0.3s;

          &.active {
            transform: rotate(180deg);
          }
        }

        &__actions {
          display: flex;
          align-items: center;
          margin-left: auto;
        }

        &__table {
          display: flex;
          align-items: stretch;
          padding-right: 5px;
          width: 100%;
          padding-bottom: 10px;
          color: #686a7e;

          &__left, &__right {
            width: 48%;
          }

          &__divider {
            display: flex;
            width: 4%;
            align-items: stretch;
            justify-content: center;

            .line {
              width: 1px;
              height: 100%;
              background-color: #d9d9e4;
            }
          }
        }

        &__confirmation {
          background-color: #fff;
          position: absolute;
          display: flex;
          align-items: center;
          padding: 0 10px;
          right: 0;
          top: 0;
          height: 100%;
          border-radius: 0 5px 5px 0;
          font-weight: 600;
          color: #686a7e;

          .confirm {
            width: 53px;
            text-align: center;
            margin-left: 4px;
            color: #ED5565;
            cursor: pointer;
          }

          .cancel {
            width: 53px;
            text-align: center;
            margin-left: 4px;
            color: lighten(#686a7e, 15%);
            cursor: pointer;
          }
        }
      }
    }
  }

  &__uploader {
    //position: absolute;
    //top: 50px;
    //left: 0;
    width: 100%;
    height: auto;
    min-height: calc(100% - 50px);
    background-color: #fff;
  }
}

medical-scheduler-detail-content {
  .documents-list__category__header__title {
    font-weight: 500;
    font-size: 13px;
  }
}

.files_button_hidden {
  display: none;
}
