.secondary-menu {
  min-width: 160px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-right: 1px solid #f3f3f4;

  &__section {
	border-top: 5px solid #f3f3f4;
  }

  &__item {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 50px;
	color: var(--text);
	position: relative;
	font-weight: 700;
	font-size: 11px;

	a {
	  color: var(--text);

	  &:hover, &.active {
		color: var(--primary);
	  }
	}

	&--small {
	  font-size: 10px;
	  padding-right: 20px;
	  font-weight: 600;
	  opacity: 0.8;
	  background-color: rgba(255, 255, 255, 0.6);
	}

	&.active {
	  color: var(--primary);
	}

	&:after {
	  content:"";
	  width: 100%;
	  height: 1px;
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  background-image: linear-gradient(to right, #fff 0%, #f3f3f4 35%, #f3f3f4 100%);
	}
  }
}
.mobile-menu{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: var(--dark);
  .secondary-menu__item{
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    fa-icon{
      font-size: 2rem;
    }
  }
}
